import React, { Component, Fragment } from "react";
import { Form, Alert } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import $ from "jquery";

class EmptyRooms extends Component {
    state = { roomsList: null, noPermission: false };

    getRoomsList = async () => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = { activeEvent: activeEvent };
        try {
            const response = await fetch(`${fetchURL}/empty-rooms-list`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: 2400 });
                    else this.setState({ roomsList: fetchedData.roomsList });
                });
            } else {
                //console.log(response.status);
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getRoomsList();
    }

    render() {
        const { roomsList, noPermission } = this.state;
        const { onChangeRoomValue, value, req, id, disabled, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {t("room.room")} {req && <span className="required">*</span>}
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        {roomsList ? (
                            roomsList.length > 0 ? (
                                <Dropdown
                                    className="req mb-3"
                                    placeholder={t("form.choose")}
                                    fluid
                                    selection
                                    value={value}
                                    options={roomsList}
                                    onChange={(event, { value }) => {
                                        $(`#${id}`).removeClass("danger");
                                        onChangeRoomValue(value);
                                    }}
                                    id={id}
                                    disabled={disabled}
                                />
                            ) : (
                                <Alert variant="warning" className="alert-small">
                                    {t("rooms.noUnoccupiedRooms")}
                                </Alert>
                            )
                        ) : (
                            <LoadingProgressBar t={t} />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default EmptyRooms;
