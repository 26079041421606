import React, { Fragment, Component } from "react";
import { Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

class TransactionsListTable extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    render() {
        const { currentSortType, currentSortingColumn } = this.state;
        const { transactionsList, displayNOR, t, onChangeNavAndPage } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.part_ident_number - b.part_ident_number, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )
                fnAmount: (a, b) => {
                    console.log(a.payment_amount, b.payment_amount, a.payment_amount > b.payment_amount);
                    if (a.payment_amount > b.payment_amount) return 1;
                    else return -1;
                },
                fnCreateDate: (a, b) => {
                    if (new Date(a.create_date) > new Date(b.create_date) || isNaN(new Date(a.create_date))) return 1;
                    else return -1;
                },
                fnSurname: (a, b) => {
                    if (a.participant_surname.toUpperCase() > b.participant_surname.toUpperCase()) return 1;
                    else return -1;
                },
                fnName: (a, b) => {
                    if (a.participant_name.toUpperCase() > b.participant_name.toUpperCase()) return 1;
                    else return -1;
                },
                fnNickname: (a, b) => {
                    //jest to wyodrębnione do osobnego ifa, bo jeśli przejdzie do następnego warunku, a nickname będzie nullem, to wywali błąd Cannot read property 'toUpperCase' of null
                    if (a.participant_nickname === null) return 1;
                    else if (b.participant_nickname === null) return -1;
                    else if (a.participant_nickname.toUpperCase() > b.participant_nickname.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.part_ident_number - a.part_ident_number,
                fnAmount: (a, b) => {
                    console.log(a.payment_amount, b.payment_amount, a.payment_amount > b.payment_amount);
                    if (a.payment_amount > b.payment_amount) return -1;
                    else return 1;
                },
                fnCreateDate: (a, b) => {
                    if (isNaN(new Date(a.create_date))) return -1;
                    else if (new Date(a.create_date) > new Date(b.create_date)) return -1;
                    else return 1;
                },
                fnSurname: (a, b) => {
                    if (a.participant_surname.toUpperCase() > b.participant_surname.toUpperCase()) return -1;
                    else return 1;
                },
                fnName: (a, b) => {
                    if (a.participant_name.toUpperCase() > b.participant_name.toUpperCase()) return -1;
                    else return 1;
                },
                fnNickname: (a, b) => {
                    //jest to wyodrębnione do osobnego ifa, bo jeśli przejdzie do następnego warunku, a nickname będzie nullem, to wywali błąd Cannot read property 'toUpperCase' of null
                    if (a.participant_nickname === null) return -1;
                    else if (b.participant_nickname === null) return 1;
                    else if (a.participant_nickname.toUpperCase() > b.participant_nickname.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnCreateDate: (a, b) => a,
                fnName: (a, b) => a,
                fnSurname: (a, b) => a,
                fnNickname: (a, b) => a,
                fnAmount: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (currentSortingColumn === "sortID") sortedData = transactionsList.sort(sortTypes[currentSortType].fnID);
        else if (currentSortingColumn === "sortSurname") sortedData = transactionsList.sort(sortTypes[currentSortType].fnSurname);
        else if (currentSortingColumn === "sortNickname") sortedData = transactionsList.sort(sortTypes[currentSortType].fnNickname);
        else if (currentSortingColumn === "sortName") sortedData = transactionsList.sort(sortTypes[currentSortType].fnName);
        else if (currentSortingColumn === "sortAmount") sortedData = transactionsList.sort(sortTypes[currentSortType].fnAmount);
        else if (currentSortingColumn === "sortCreateDate") sortedData = transactionsList.sort(sortTypes[currentSortType].fnCreateDate);
        else sortedData = transactionsList.sort(sortTypes[currentSortType].fnID);

        const currentPageParams = { nav: "transactions", subpage: "transactionsList", params: "" };
        console.log(sortedData);
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "45px" }}>{t("on")}</th>
                            <th style={{ width: "150px" }}>
                                {t("user.list.surname")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortSurname" onClick={() => this.handleSorting("sortSurname")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortSurname" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th style={{ width: "100px" }}>
                                {t("user.list.nickname")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortNickname" onClick={() => this.handleSorting("sortNickname")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortNickname" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th style={{ width: "150px" }}>
                                {t("user.list.name")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortName" onClick={() => this.handleSorting("sortName")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortName" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>
                                {t("payment.list.amount")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortAmount" onClick={() => this.handleSorting("sortAmount")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortAmount" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>{t("payment.list.group")}</th>
                            <th>{t("payment.list.method")}</th>
                            <th>{t("payment.list.side")}</th>
                            <th>{t("payment.list.sender")}</th>
                            <th>{t("payment.list.receiver")}</th>
                            <th>{t("payment.list.creator")}</th>
                            <th style={{ width: "180px" }}>
                                {t("payment.list.paymentDate")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortCreateDate" onClick={() => this.handleSorting("sortCreateDate")}>
                                    <FontAwesomeIcon
                                        icon={currentSortingColumn !== "sortCreateDate" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort}
                                    />
                                </Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <Button
                                            onClick={() =>
                                                onChangeNavAndPage({ nav: "participants", subpage: "participantDetails", params: { partID: p.part_id, userID: p.user_id } }, currentPageParams)
                                            }
                                            variant="link"
                                            className="text-left"
                                        >
                                            {p.participant_surname}
                                        </Button>
                                    </td>
                                    <td>{p.participant_nickname ? p.participant_nickname : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>{p.participant_name}</td>
                                    <td className="text-right">
                                        <span className={p.payment_amount > 0 ? "text-success" : "text-danger"}>
                                            {p.payment_amount} {t("currency")}
                                        </span>
                                    </td>
                                    <td>{t(`payment.groups.${p.payment_type_group}`)}</td>
                                    <td>{t(`payment.methods.${p.payment_type_method}`)}</td>
                                    <td>{t(`payment.sides.${p.payment_type_side}`)}</td>
                                    <td>
                                        {p.sender_fullname ? (
                                            <Button
                                                onClick={() => {
                                                    console.log("partID: ", p.sender_part_id, "userID: ", p.sender_user_id);
                                                    onChangeNavAndPage(
                                                        { nav: "participants", subpage: "participantDetails", params: { partID: p.sender_part_id, userID: p.sender_user_id } },
                                                        currentPageParams
                                                    );
                                                }}
                                                variant="link"
                                                className="text-left"
                                            >
                                                {p.sender_fullname}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("na")}</span>
                                        )}
                                    </td>
                                    <td>
                                        {p.receiver_fullname ? (
                                            <Button
                                                onClick={() =>
                                                    onChangeNavAndPage(
                                                        { nav: "participants", subpage: "participantDetails", params: { partID: p.receiver_part_id, userID: p.receiver_user_id } },
                                                        currentPageParams
                                                    )
                                                }
                                                variant="link"
                                                className="text-left"
                                            >
                                                {p.receiver_fullname}
                                            </Button>
                                        ) : (
                                            <span className="opacity-4">{t("na")}</span>
                                        )}
                                    </td>
                                    <td>{p.create_user_fullname}</td>
                                    <td>{p.payment_date}</td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default TransactionsListTable;
