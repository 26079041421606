import React, { Component, Fragment } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import NavDetails from "../../navigation/NavDetails";
//import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import TextForm from "../../forms/text-inputs/TextForm";
import TextareaForm from "../../forms/TextareaForm";
//import { Checkbox } from "semantic-ui-react";
import CalendarDateAndTime from "../../forms/datetimes/CalendarDateAndTime";
import $ from "jquery";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";
import ScanQRCodeForm from "../../forms/ScanQRCodeForm";
import { Checkbox } from "semantic-ui-react";
//import IdentNumber from "../../forms/text-inputs/IdentNumber";

class AccreditParticipant extends Component {
    state = {
        loading: true,
        partData: null,
        qrCode: "",
        showScanner: false,
        changedValues: false,
        arrivalDate: new Date(),
        noPermission: false,
        partGadgets: null,
        alertAssignedUniquePartID: "",
    };

    getPartGadgets = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/get-participant-gadgets`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const partGadgets = fetchedData.partGadgets;
                        partGadgets.forEach((e) => {
                            e.checked = false;
                        });
                        this.setState({ partGadgets: partGadgets, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    getBasicData = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/participant-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData.participantData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ partData: fetchedData.participantData, qrCode: fetchedData.participantData[0].ident_value }, () => this.getPartGadgets(partID));
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleChangeValuesInForms = (e) => {
        const { onChangeValuesInFormes } = this.props;
        const { value, name } = e.target;
        const { partData } = this.state;
        const list = [...partData];
        list[0][name] = value;
        this.setState({ changedValues: true, partData: list });
        onChangeValuesInFormes();
    };

    handleChangePartIdentNumber = (e) => {
        const { onChangeValuesInFormes, activeEvent, t } = this.props;
        const { partData } = this.state;
        console.log(activeEvent);
        const { value, name } = e.target;
        $("#partIdentNumber").removeClass("danger");
        const list = [...partData];
        list[0][name] = value;
        this.setState({ changedValues: true, partData: list }, () => {
            //sprawdzenie, czy format nr identa jest czterocyfrowy
            const re = /^\d{4}$/;
            if (!re.test(String(value).toLowerCase())) {
                this.setState({ alertAssignedUniquePartID: { variant: "danger", message: t("error.4digitIdent") } });
            } else this.checkUniquePartIdentNumber(value, activeEvent.code);
        });
        onChangeValuesInFormes();
    };

    //zmiana danych w formularzu - dane uczestnika
    handleChangePartData = (e) => {
        const { name, value, id } = e.target;
        this.changeDataInParticipantData(name, value);
        $(`#${id}`).removeClass("danger");
    };

    //akutalizacja danych {t('from')} formularza w stanie partData
    changeDataInParticipantData(name, data) {
        const { onChangeValuesInFormes } = this.props;
        const { partData } = this.state;
        const list = [...partData];
        list[0][name] = data;
        this.setState({ changedValues: true, partData: list });
        onChangeValuesInFormes();
    }

    //sprawdzanie poprawności wypełnionego formularza
    handleCheckValidation = (e) => {
        e.preventDefault();
        const { arrivalDate, qrCode, partData, alertAssignedUniquePartID } = this.state;
        const { currentPage, onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = "";

        if (!partData[0].part_ident_nickname) {
            errorMsg += t("error.emptyIdentNickname");
            $("#participantNickname").addClass("danger");
        }

        if (!arrivalDate) {
            errorMsg += t("error.emptyArrivalDate");
            $("#part_arrival_date").addClass("danger");
        }

        //sprawdzanie, czy został wpisany poprawny numer identa
        if (alertAssignedUniquePartID) {
            errorMsg += t("error.invalidIdentValue");
            $("#partIdentNumber").addClass("danger");
        }

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const passData = {
                partID: currentPage.params.partID,
                identValue: qrCode,
                partIdentNumber: partData[0].part_ident_number,
                partIdentNickname: partData[0].part_ident_nickname,
                partCommentOrg: partData[0].part_comment_org,
                arrivalDate: arrivalDate,
            };
            //console.log(passData, this.state.partGadgets);

            //brak zamówionych gadżetów - przejdź tylko do samej akredytacji
            if (!this.state.partGadgets || this.state.partGadgets.length === 0) this.accreditParticipant(passData);
            //najpierw wydaj gadżety, potem zaakredytuj
            else this.changeGadgetsStatuses(passData);
        }
    };

    changeGadgetsStatuses = async (data) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.accrediting") });
        const passData = { partID: data.partID, partGadgets: this.state.partGadgets };
        try {
            const response = await fetch(`${fetchURL}/change-gadgets-statuses`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.accreditParticipant(data);
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    accreditParticipant = async (passData) => {
        const { fetchURL, onCancelChangeValuesInFormes, onShowAlert, onChangeNavAndPage, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.accrediting") });
        try {
            const response = await fetch(`${fetchURL}/accredit-participant`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        this.setState({ changedValues: false });
                        onCancelChangeValuesInFormes();
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        onChangeNavAndPage({ nav: "participants", subpage: "participantsList", params: "" });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleSetDefaultIdentNumber = (value) => {
        const { partData } = this.state;
        const list = [...partData];
        list[0].part_ident_number = value;
        this.setState({ partData: list });
    };

    //sprawdzanie, czy wpisany numer identa jest unikalny per konwent
    checkUniquePartIdentNumber = async (partIdentNumber, eventCode) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partIdentNumber: partIdentNumber,
            eventCode: eventCode,
        };
        try {
            const response = await fetch(`${fetchURL}/check-unique-part-ident-number`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "assigned") this.setState({ alertAssignedUniquePartID: { variant: "danger", message: t(`error.${fetchedData.code}`) } });
                    else this.setState({ alertAssignedUniquePartID: "" });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow, currentPage } = this.props;
        this.getBasicData(currentPage.params.partID);

        onResizeWindow("basic");
    }
    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }
    render() {
        const { loading, partData, qrCode, changedValues, arrivalDate, noPermission, partGadgets } = this.state;
        const { scannerHeight, onCancelChangeValuesInFormes, onBackToPreviousPage, onShowAlert, fetchURL, t, onShowHomePage } = this.props;

        function checkIfAvailableItems(gadgetsList) {
            return gadgetsList.filter((e) => e.shordit_stat_code !== "DELIVERED" && e.shordit_stat_code !== "SOLD");
        }

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t("participant.accreditPart")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col xs={12}>
                                                <p className="title-details">{partData[0].user_fullname}</p>
                                            </Col>
                                        </Row>
                                        <Row className="my-4">
                                            <Col xs={12}>
                                                <p className="h4 my-3 form-title">{t("data")}</p>
                                            </Col>
                                            <Col lg={3} xs={12}>
                                                {/* <IdentNumber
                                                    partID={partData[0].part_id}
                                                    fetchURL={fetchURL}
                                                    value={partData[0].part_ident_number}
                                                    onShowAlert={onShowAlert}
                                                    activeEvent={activeEvent}
                                                    onChangePartIdentNumber={this.handleChangePartIdentNumber}
                                                    onSetDefaultIdentNumber={this.handleSetDefaultIdentNumber}
                                                    currentPage={currentPage}
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                />
                                                {alertAssignedUniquePartID && (
                                                    <Alert variant={alertAssignedUniquePartID.variant} className="alert-small">
                                                        {alertAssignedUniquePartID.message}
                                                    </Alert>
                                                )} */}
                                                <TextForm
                                                    labelTxt="Numer identa"
                                                    placeholderTxt="Wpisz numer identa"
                                                    value={partData[0].part_ident_number}
                                                    readOnly
                                                    req
                                                    name="part_ident_number"
                                                    id="partIdentNumber"
                                                />
                                            </Col>
                                            <Col lg={3} xs={12}>
                                                <TextForm
                                                    labelTxt={t("participant.partNicknameLabel")}
                                                    value={partData[0].part_ident_nickname}
                                                    onChangeValue={this.handleChangePartData}
                                                    req
                                                    id="participantNickname"
                                                    placeholderTxt={t("user.nicknamePlaceholder")}
                                                    name="part_ident_nickname"
                                                />
                                            </Col>

                                            <Col lg={6} xs={12}>
                                                <ScanQRCodeForm
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                    scannerHeight={scannerHeight}
                                                    onChangeQRCodeValue={(code) => {
                                                        this.setState({ qrCode: code, changedValues: true });
                                                        this.props.onChangeValuesInFormes();
                                                    }}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    qrCode={qrCode}
                                                    onResetIdent={() => this.setState({ qrCode: null })}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={4} md={6} xs={12}>
                                                <CalendarDateAndTime
                                                    req
                                                    txtLabel={t("calendar.dateTimeArrival")}
                                                    onChangeCalendarDate={(date) => this.setState({ arrivalDate: date })}
                                                    value={arrivalDate}
                                                    id="part_arrival_date"
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6}>
                                                <p className="h4 my-3 form-title">{t("comments")}</p>
                                                <TextareaForm
                                                    value={partData[0].part_comment_org}
                                                    name="part_comment_org"
                                                    labelTxt={t("participant.accrComment")}
                                                    placeholderTxt={t("form.typeComment")}
                                                    onChangeValue={this.handleChangePartData}
                                                    id="partComment"
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <p className="h4 my-3 form-title">{t("participant.gadgets")}</p>
                                                {partGadgets.length === 0 ? (
                                                    <span className="opacity-4">{t("shop.noOrders")}</span>
                                                ) : (
                                                    <Fragment>
                                                        {checkIfAvailableItems(partGadgets).length > 0 ? (
                                                            <Fragment>
                                                                <p>{t("participant.checkGadgets")}:</p>

                                                                {partGadgets.map((e, i) => (
                                                                    <div key={i}>
                                                                        {e.shordit_stat_code !== "DELIVERED" && e.shordit_stat_code !== "SOLD" && (
                                                                            <Checkbox
                                                                                className="ml-1 pt-2"
                                                                                label={`${e.shit_name}${e.shit_variant_name ? `, ${e.shit_variant_name}` : ""} x${e.shordit_quantity} (${t(
                                                                                    "status"
                                                                                )}: ${e.shordit_stat_name})`}
                                                                                value={e.shordit_id}
                                                                                checked={e.checked}
                                                                                onChange={(event, data) => {
                                                                                    const { partGadgets } = this.state;
                                                                                    const list = [...partGadgets];
                                                                                    list[i].checked = data.checked;
                                                                                    this.setState({ changedValues: true, partGadgets: list });
                                                                                    this.props.onChangeValuesInFormes();
                                                                                }}
                                                                                disabled={e.shordit_stat_code !== "PENDING" && e.shordit_stat_code !== "PAID"}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </Fragment>
                                                        ) : (
                                                            <span className="opacity-4">{t("shop.noAvailableItems")}</span>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                <Button type="submit" variant="primary" size="lg" className="mb-3">
                                                    {t("participant.accredit")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AccreditParticipant;
