import React, { Fragment } from "react";
import { Table } from "react-bootstrap";

const ScheduleTableConstr = (props) => {
    const { duties } = props;
    console.log(duties);
    return (
        <Fragment>
            <Table className="mt-3" bordered>
                <tbody>
                    {duties.map((e, i) => (
                        <tr key={i}>
                            <td className="bg-light-gray">
                                {e.date}
                                <br />
                                {e.duty_period_label}
                            </td>
                            <td>
                                {e.participants.map((p, j) => (
                                    <Fragment key={j}>
                                        <p className="mb-0">{p.user_fullname}</p>
                                    </Fragment>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Fragment>
    );
};

export default ScheduleTableConstr;
