import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import $ from "jquery";

class ChangePassword extends Component {
    state = {
        value1: "",
        value2: "",
        changedValues: false,
        alert1: null,
        alert2: null,
    };

    validatePassword = (pass) => {
        const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_\-{}[\]:;'"<> ?`/]).{8,}$/;
        if (!re.test(pass)) {
            return false;
        } else return true;
    };

    updatePass = async (dataPass) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.changingPassword") });
        try {
            const response = await fetch(`${fetchURL}/update-pass`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataPass),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        this.setState({ changedValues: false }, () => {
                            onShowAlert({ variant: "success", message: t(`success.editedPass`), fading: 2000 });
                            this.props.onCancelChangeValuesInFormes();
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleCheckValidationPass = (e) => {
        e.preventDefault();
        const { value2, value1 } = this.state;
        const { onShowAlert, t, userEmail } = this.props;
        onShowAlert("");
        let errorMsg = "";

        if ((value1 && !value2) || (!value1 && value2)) {
            errorMsg = t("error.oneOfPasswordsIsEmpty");
        } else if (value1 !== value2) {
            errorMsg = t("error.passNotIdentical");
        } else if (!this.validatePassword(value1) || !this.validatePassword(value2)) {
            errorMsg = t("error.passRegExp");
        }

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
            $(".pass").addClass("danger");
        } else {
            const passData = {
                password: value1,
                repeatPassword: value2,
                resetEmail: userEmail,
            };

            this.updatePass(passData);
        }
    };

    render() {
        const { value1, value2, changedValues, alert1, alert2 } = this.state;
        const { t } = this.props;
        return (
            <Fragment>
                <Form onSubmit={this.handleCheckValidationPass}>
                    <p className="h5 mb-3">{t("myAccount.changePassword")}</p>
                    <Row>
                        <Col xs={12}>
                            <Form.Control type="text" id="username" name="username" value="username" autoComplete="username" className="d-none" onChange={() => {}} />
                            <Form.Label>
                                {t("form.pass")} <span className="required"> *</span>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                placeholder={t("form.typePass")}
                                name="password"
                                value={value1}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (!this.validatePassword(value)) {
                                        $("#password").addClass("danger");
                                        this.setState({ alert1: t("error.passRegExp") });
                                    } else {
                                        $("#password").removeClass("danger");
                                        this.setState({ alert1: null });
                                    }
                                    this.setState({ value1: value, changedValues: true });
                                    this.props.onChangeValuesInFormes();
                                }}
                                className="mb-3 req pass"
                                autoComplete="new-password"
                            />
                            {alert1 && (
                                <Alert variant="danger" className="alert-small">
                                    {alert1}
                                </Alert>
                            )}
                        </Col>
                        <Col xs={12}>
                            <Form.Label>
                                {t("form.repeatPass")} <span className="required"> *</span>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                id="repeatPassword"
                                placeholder={t("form.typeRepeatPass")}
                                name="repeatPassword"
                                value={value2}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    if (!this.validatePassword(value)) {
                                        $("#repeatPassword").addClass("danger");
                                        this.setState({ alert2: t("error.passRegExp") });
                                    } else {
                                        $("#repeatPassword").removeClass("danger");
                                        this.setState({ alert2: null });
                                    }
                                    this.setState({ value2: value, changedValues: true });
                                    this.props.onChangeValuesInFormes();
                                }}
                                className="mb-3 req pass"
                                autoComplete="new-password"
                            />
                            {alert2 && (
                                <Alert variant="danger" className="alert-small">
                                    {alert2}
                                </Alert>
                            )}
                        </Col>
                        <Col xs={12}>
                            {!changedValues ? (
                                <TooltipWithDisabledButton t={t} mssgTooltip={t("info.noChangesInForm")} btnTxt={t("myAccount.changePassword")} tooltipID="tooltipDisabledNoChanges" size="md" />
                            ) : (
                                <Button type="submit" variant="primary" className="mb-3">
                                    {t("myAccount.changePassword")}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

export default ChangePassword;
