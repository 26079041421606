import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Table, Col, Button } from "react-bootstrap";
import AlertNoPermission from "../../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import ShopItemSendEmailModal from "../../../modals/ShopItemSendEmailModal";

class ShopOrders extends Component {
    state = {
        showShopOrderSendEmail: false,
        partOrders: null,
        modalParams: null,
        noPermission: false,
        loading: true,
    };

    getPartOrders = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/participant-orders`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ partOrders: fetchedData.partOrders, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getPartOrders(this.props.partID);
    }

    render() {
        const { showShopOrderSendEmail, modalParams, noPermission, partOrders, loading } = this.state;
        const { fetchURL, onShowAlert, t, currentPageParams, onChangeNavAndPage } = this.props;

        function itemsValue(items) {
            let value = 0;
            items.forEach((e) => {
                value += e.shordit_price * e.shordit_quantity;
            });
            return parseFloat(value.toFixed(2));
        }

        function ifOrderPaied(order, shopOrderItemsList) {
            return order.payment_amount === itemsValue(shopOrderItemsList) ? (
                <span className="text-success">{t("shop.ordersList.paied")}</span>
            ) : order.payment_amount > itemsValue(shopOrderItemsList) ? (
                <span className="text-info">{t("shop.ordersList.overpaied")}</span>
            ) : order.payment_amount < (itemsValue(shopOrderItemsList) && order.payment_amount > 0) ? (
                <span className="text-warning">{t("shop.ordersList.underpaied")}</span>
            ) : (
                <span className="text-danger">{t("shop.ordersList.notpaied")}</span>
            );
        }
        return (
            <Fragment>
                <Col xs={12}>
                    <p className="h3 my-3 d-inline-block mr-3 align-middle">{t("shop.littleShop")}</p>
                </Col>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        <Col xs={12} className="mb-3">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : partOrders.length > 0 ? (
                                <Fragment>
                                    <Table bordered hover size="sm" className="table-list">
                                        <thead>
                                            <tr>
                                                <th>{t("shop.ordersList.code")}</th>
                                                <th>{t("shop.ordersList.date")}</th>
                                                <th>{t("shop.ordersList.value")}</th>
                                                <th>{t("dropdowns.actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partOrders.map((e, i) => (
                                                <tr key={i}>
                                                    <td>{e.shord_code}</td>
                                                    <td>{e.create_date}</td>
                                                    <td>
                                                        {itemsValue(e.items)} {t("currency")}
                                                        {itemsValue(e.items) > 0 && <span> - {ifOrderPaied(e, e.items)}</span>}
                                                    </td>

                                                    <td className="text-center">
                                                        <Button
                                                            variant="outline-primary"
                                                            size="sm"
                                                            onClick={() =>
                                                                onChangeNavAndPage(
                                                                    { nav: "shop", subpage: "shopOrderDetails", params: { orderCode: e.shord_code } },
                                                                    currentPageParams
                                                                )
                                                            }
                                                            className="mx-1"
                                                        >
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Fragment>
                            ) : (
                                <p className="opacity-4 mb-3">{t("shop.noOrders")}</p>
                            )}
                        </Col>

                        {showShopOrderSendEmail && (
                            <ShopItemSendEmailModal
                                fetchURL={fetchURL}
                                show={showShopOrderSendEmail}
                                addressee={modalParams}
                                onCloseModal={() => this.setState({ showShopOrderSendEmail: false, modalParams: null })}
                                shopOrderSentEmail={() =>
                                    this.setState({ showShopOrderSendEmail: false, modalParams: null }, () =>
                                        onShowAlert({ variant: "success", message: "Pomyślnie wysłano wiadomość.", fading: 2000 })
                                    )
                                }
                                onShowAlert={onShowAlert}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ShopOrders;
