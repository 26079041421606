import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container } from "react-bootstrap";
import TableApplicationsList from "./TableApplicationsList";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import { faEraser, faLongArrowAltLeft, faLongArrowAltRight, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import NoPermission from "../../other/NoPermission";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import EventFilter from "../../forms/dropdowns/EventFilter";
import ApplicationStatuses from "../../forms/dropdowns/ApplicationStatuses";
import ChangeAppStatusModal from "../../modals/ChangeAppStatusModal";
import ApplicationDetailsModal from "../../modals/ApplicationDetailsModal";
import ApplicationTypes from "../../forms/dropdowns/ApplicationTypes";

class ApplicationsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        applicationsList: this.props.dataList,
        originalApplications: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        noPermission: false,
        selectedAppStat: "all",
        selectedAppType: "all",
        showChangeAppStatusModal: false,
        selectedPartAppData: null,
        showApplicationDetailsModal: false,
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredApplications = this.state.originalApplications;
            filteredApplications = filteredApplications.filter(
                (el) =>
                    el.user_fullname.toString().toLowerCase().includes(searchedPhrase) ||
                    el.part_app_type_name.toString().toLowerCase().includes(searchedPhrase) ||
                    el.part_app_stat_name.toString().toLowerCase().includes(searchedPhrase)
            );
            this.setState({ applicationsList: filteredApplications });
        } else {
            this.setState({ applicationsList: this.state.originalApplications });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getApplicationsList();
    };

    getApplicationsList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/applications-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const applicationsList = fetchedData.appList;
                        this.setState(
                            {
                                applicationsList: applicationsList,
                                originalApplications: applicationsList,
                            },
                            () => {
                                onGetDataList(applicationsList);
                                this.setState({ loading: false });
                            }
                        );
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getApplicationsList();
        const { onResizeWindow } = this.props;
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const {
            applicationsList,
            searchedPhrase,
            loading,
            showNOR,
            displayNOR,
            noPermission,
            selectedAppStat,
            selectedAppType,
            showChangeAppStatusModal,
            selectedPartAppData,
            showApplicationDetailsModal,
        } = this.state;
        const { onDisplaySubPage, fetchURL, t, currentPage, onChangeNavAndPage, eventFilterValue, onShowAlert, onShowHomePage } = this.props;

        function filterList(e) {
            if (
                (selectedAppStat === "all" || selectedAppStat === e.part_app_stat_code) &&
                (selectedAppType === "all" || selectedAppType === e.part_app_type_code) &&
                (eventFilterValue === "all" || e.event_code === eventFilterValue)
            )
                return true;
            else return false;
        }

        let filteredApplicationsList = null;
        if (applicationsList) filteredApplicationsList = applicationsList.filter(filterList);
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: "" };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            applicationsList: this.state.originalApplications,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col lg={1} xs={4}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EventFilter t={t} eventFilterValue={this.props.eventFilterValue} eventsList={this.props.eventsList} onChangeEventFilter={this.props.onChangeEventFilter} />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <ApplicationStatuses
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        onPartAppStatSelected={(value) => {
                                            this.setState({ selectedAppStat: value });
                                        }}
                                        defaultValue={selectedAppStat}
                                        fetchURL={fetchURL}
                                        onShowAlert={onShowAlert}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <ApplicationTypes
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        onPartAppTypeSelected={(value) => {
                                            this.setState({ selectedAppType: value });
                                        }}
                                        defaultValue={selectedAppType}
                                        fetchURL={fetchURL}
                                        onShowAlert={onShowAlert}
                                    />
                                </Col>

                                <Col lg={2} xs={12} className="text-center text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                {filteredApplicationsList.length > 0 ? (
                                                    <Fragment>
                                                        <TableApplicationsList
                                                            applications={filteredApplicationsList}
                                                            numOfRecords={displayNOR}
                                                            onDisplaySubPage={onDisplaySubPage}
                                                            fetchURL={fetchURL}
                                                            onRefreshData={this.handleDownloadData}
                                                            currentPageParams={currentPageParams}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            onSelectAppPartData={(data) => this.setState({ selectedPartAppData: data, showChangeAppStatusModal: true })}
                                                            onSelectAppPartDataForDetails={(data) => this.setState({ selectedPartAppData: data, showApplicationDetailsModal: true })}
                                                            t={t}
                                                        />
                                                        <div className="light-border"></div>{" "}
                                                    </Fragment>
                                                ) : (
                                                    <div className="text-center">{t("notFound")}</div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            {t("loaded")} {displayNOR > filteredApplicationsList.length ? filteredApplicationsList.length : displayNOR} {t("from")} {filteredApplicationsList.length}{" "}
                                            {t("records")}
                                        </Col>
                                        <Col className="text-center ">
                                            {displayNOR >= filteredApplicationsList.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">{t("thatsAll")}</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    {t("loadMore")}
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}

                {showChangeAppStatusModal && (
                    <ChangeAppStatusModal
                        t={t}
                        onShowHomePage={onShowHomePage}
                        show={showChangeAppStatusModal}
                        onClose={() => this.setState({ showChangeAppStatusModal: false, selectedPartAppData: null })}
                        partAppData={selectedPartAppData}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        onDownloadData={this.handleDownloadData}
                    />
                )}
                {showApplicationDetailsModal && (
                    <ApplicationDetailsModal
                        t={t}
                        onShowHomePage={onShowHomePage}
                        show={showApplicationDetailsModal}
                        onClose={() => this.setState({ showApplicationDetailsModal: false, selectedPartAppData: null })}
                        partAppData={selectedPartAppData}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                    />
                )}
            </Fragment>
        );
    }
}

export default ApplicationsList;
