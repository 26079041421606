import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import NavDetails from "../../navigation/NavDetails";
import $ from "jquery";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import LogoutModal from "../../modals/LogoutModal";
import MyAccountDetails from "./MyAccountDetails";
import ChangePassword from "./ChangePassword";

class MyAccount extends Component {
    state = {
        userData: [],
        userRoles: [],
        userPassword: "",
        userRepeatPassword: "",
        rolesList: [],
        eventsList: "",
        loading: true,
        changedValues: false,
        modalShow: false,
        showScanner: false,
        addFbLink: false,
        scanDone: false,
        noPermission: false,
    };

    myAccount = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert("");

        try {
            const response = await fetch(`${fetchURL}/my-account`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response
                    .json()
                    .then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                        else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                        else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                        else {
                            let fetchedUserRoles = [];
                            fetchedData.userRoles.forEach((el) => {
                                fetchedUserRoles.push({
                                    roleCode: el.user_role_code,
                                    roleName: el.user_role_name,
                                    roleEvent: el.event_code ? el.event_code : "permanent",
                                    roleID: el.u2r_id,
                                    roleActivity: "Y",
                                    roleGroup: el.user_role_group,
                                    roleLevel: el.user_role_level,
                                });
                            });
                            const userData = fetchedData.userData;
                            userData.forEach((el) => {
                                el.user_nickname = el.user_nickname ? el.user_nickname : "";
                                el.user_phone = el.user_phone ? el.user_phone : "";
                                el.user_facebook_link = el.user_facebook_link ? el.user_facebook_link : "";
                                el.user_medical_info = el.user_medical_info ? el.user_medical_info : "";
                                el.user_comment = el.user_comment ? el.user_comment : "";
                                el.user_qr_code = el.user_qr_code ? el.user_qr_code : "";
                            });

                            this.setState({ userData: userData, userRoles: fetchedUserRoles });
                        }
                    })
                    .then(() => {
                        this.setState({ loading: false });
                    });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.myAccount();
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        if ($(".scrolled-content").outerHeight() && !this.state.loading) this.props.onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { userData, loading, modalShow } = this.state;
        const { onLogout, currentPage, onChangeNavAndPage, t, onChangeValuesInFormes, fetchURL, onShowAlert, onCancelChangeValuesInFormes, onShowHomePage } = this.props;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };
        return (
            <Fragment>
                <Container fluid className="nav-details">
                    <Row>
                        <NavDetails t={t} header={t("myAccount.myAccount")} hideBackButton={true} />
                        <Col md={3} xs={12}>
                            <Button className="float-right mt-1" variant="outline-primary" onClick={() => this.setState({ modalShow: true })}>
                                {t("user.logout")}
                            </Button>
                        </Col>
                    </Row>
                </Container>
                {loading ? (
                    <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                        <LoadingSpinnerInner />
                    </div>
                ) : (
                    <div className="scrolled-content" style={{ display: "none" }}>
                        <Container className="py-3">
                            <Row>
                                <Col lg={8} xs={12}>
                                    <p className="h5 mb-3">{t("myAccount.dataAccount")}</p>
                                    <MyAccountDetails t={t} userData={userData} />
                                    <Button variant="primary" className="mb-3" onClick={() => onChangeNavAndPage({ nav: "account", subpage: "editMyAccount" }, currentPageParams)}>
                                        {t("myAccount.editData")}
                                    </Button>
                                </Col>

                                <Col lg={4} xs={12}>
                                    <ChangePassword
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                        onChangeValuesInFormes={onChangeValuesInFormes}
                                        userEmail={userData[0].user_email}
                                        onShowAlert={onShowAlert}
                                        fetchURL={fetchURL}
                                        onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                )}

                <LogoutModal t={t} onLogout={onLogout} show={modalShow} onClose={() => this.setState({ modalShow: false })} />
            </Fragment>
        );
    }
}

export default MyAccount;
