import React, { Component, Fragment } from "react";
import { Container, Row, Col, Button, Form, Alert } from "react-bootstrap";
import $ from "jquery";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../navigation/NavDetails";
import TextForm from "../../forms/text-inputs/TextForm";
import InactiveEvents from "../../forms/dropdowns/InactiveEvents";
import NumberForm from "../../forms/number-inputs/NumberForm";
import BuildingsDropdown from "../../forms/dropdowns/BuildingsDropdown";
import TextareaForm from "../../forms/TextareaForm";
import RoomsSizeDropdown from "../../forms/dropdowns/RoomsSizeDropdown";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";

class AddRoom extends Component {
    state = {
        loading: true,
        room: [
            {
                event_code: "",
                room_size_code: "",
                building_code: "",
                room_number: "",
                room_code: "",
                room_capacity: "",
                room_doors: "",
                room_windows: "",
                room_description: "",
            },
        ],
        buildings: null,
        inactiveEventsList: null,
        noPermission: false,
        alertNotUniqueRoomCode: "",
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;
        const room = this.state.room[0];
        onShowAlert("");
        let errorMsg = "";
        $(".form-control.req").each(function () {
            if ($(this).val() === "" || $(this).val() === "default") {
                $(this).addClass("danger");
                errorMsg = t("error.reqFields");
            } else $(this).removeClass("danger");
        });

        if (!room.room_size_code) {
            $("#roomSizeCode").addClass("danger");
            errorMsg += t("error.roomSizeEmpty");
        }
        if (!room.building_code) {
            $("#buildingCode").addClass("danger");
            errorMsg += "Budynek pokoju jest pusty. ";
        }

        if (this.statealertNotUniqueRoomCode) {
            $("#roomCode").addClass("danger");
            errorMsg += "Kod pokoju nie jest unikalny. Pokój o takiej nazwie już istnieje w bazie danych.";
        }

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            this.insertRoom();
        }
    };

    checkRoomCodeUniqueness = async (e) => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = {
            roomCode: e.target.value,
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/check-room-code-uniqueness`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "notUnique") this.setState({ alertNotUniqueRoomCode: fetchedData.message });
                    else this.setState({ alertNotUniqueRoomCode: "" });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    insertRoom = async () => {
        const { fetchURL, onChangeNavAndPage, onShowAlert, activeEvent, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.addingNewRoom") });
        const passData = {
            room: this.state.room,
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/insert-room`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({
                            variant: "success",
                            message: `${t("room.roomSuccessfullAdded1")}${this.state.room[0].room_code}${t("room.roomSuccessfullAdded1")}`,
                            fading: 3000,
                        });
                        onChangeNavAndPage({ nav: "rooms", subpage: "roomsList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    addRoomView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/add-room-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    //akutalizacja danych {t('from')} formularza w stanie participantData
    handleRoomData(name, data) {
        const { onChangeValuesInFormes } = this.props;
        const { room } = this.state;
        const list = [...room];
        list[0][name] = data;
        this.setState({ changedValues: true, room: list });
        onChangeValuesInFormes();
    }

    handleAmountValidation = (e) => {
        $("#amountInput").removeClass("danger");
        let amount = e.target.value;

        const oldAmount = this.state.room[0].shit_price;
        const reg = /^(\d+(\.|,)?\d{0,2})$/;

        if (!reg.test(amount) && amount !== "") {
            amount = oldAmount;
        }
        this.handleRoomData("shit_price", amount);
    };

    handleNumberValue = (e) => {
        const reg = /^([0-9]*)$/;

        if (e.target.value !== "") {
            if (reg.test(e.target.value)) {
                this.handleRoomData(e.target.name, e.target.value);
            }
        } else {
            this.handleRoomData(e.target.name, e.target.value);
        }
    };

    componentDidMount() {
        const { onResizeWindow, activeEvent } = this.props;
        onResizeWindow("basic");
        if (activeEvent) {
            const { room } = this.state;
            const list = [...room];
            list[0]["event_code"] = activeEvent.id;
            this.setState({ room: list, activeEvent: true });
        }
        $("#uploadFile").on("change", function () {
            $("span").text($(this).val());
        });

        this.addRoomView();
    }

    componentDidUpdate() {
        const { loading, room } = this.state;
        const { onResizeWindow } = this.props;

        if ($(".scrolled-content").outerHeight() && !loading) onResizeWindow("basic");
        if (!loading) $(".scrolled-content").fadeIn();
        if (loading && room[0].event_code) {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, changedValues, noPermission, alertNotUniqueRoomCode } = this.state;
        const { onCancelChangeValuesInFormes, fetchURL, onBackToPreviousPage, activeEvent, t, onShowHomePage } = this.props;

        const room = this.state.room[0];
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header="dodaj pokój"
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        {(activeEvent === "noActiveEvent" || !activeEvent) && (
                                            <Row>
                                                <Col lg={4} md={6} xs={12}>
                                                    <InactiveEvents
                                                        t={t}
                                                        onShowHomePage={onShowHomePage}
                                                        fetchURL={fetchURL}
                                                        onInactiveEventSelected={(event, value) => this.handleRoomData("event_code", value)}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col lg={3} md={4} xs={6}>
                                                <TextForm
                                                    req
                                                    value={room.room_code}
                                                    onChangeValue={(e) => {
                                                        this.checkRoomCodeUniqueness(e);
                                                        this.handleRoomData(e.target.name, e.target.value);
                                                    }}
                                                    labelTxt={t("room.roomCodeLabel")}
                                                    placeholderTxt={t("room.roomCodePlaceholder")}
                                                    name="room_code"
                                                    id="roomCode"
                                                />
                                                {alertNotUniqueRoomCode && (
                                                    <Alert variant="danger" className="alert-small">
                                                        {alertNotUniqueRoomCode}
                                                    </Alert>
                                                )}
                                            </Col>
                                            <Col lg={2} md={3} xs={4}>
                                                <TextForm
                                                    req
                                                    value={room.room_number}
                                                    onChangeValue={(e) => this.handleRoomData(e.target.name, e.target.value)}
                                                    labelTxt={t("room.roomNumberLabel")}
                                                    placeholderTxt={t("room.roomNumberPlaceholder")}
                                                    name="room_number"
                                                    id="roomNumber"
                                                />
                                            </Col>
                                            <Col lg={3} md={3} xs={6}>
                                                <RoomsSizeDropdown
                                                    req
                                                    fetchURL={fetchURL}
                                                    onChangeRoomsSizeValue={(event, { value }) => {
                                                        this.handleRoomData("room_size_code", value);
                                                    }}
                                                    id="roomSizeCode"
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                />
                                            </Col>
                                            <Col lg={3} md={3} xs={6}>
                                                <BuildingsDropdown
                                                    req
                                                    fetchURL={fetchURL}
                                                    onChangeBuildingValue={(event, { value }) => {
                                                        this.handleRoomData("building_code", value);
                                                    }}
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                    id="buildingCode"
                                                />
                                            </Col>

                                            <Col lg={3} md={4} xs={6}>
                                                <NumberForm
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                    value={room.room_capacity}
                                                    name="room_capacity"
                                                    labelTxt={t("room.roomCapacity")}
                                                    onChangeValue={(e) => this.handleNumberValue(e)}
                                                    helper={t("room.roomCapacityHelper")}
                                                />
                                            </Col>
                                            <Col lg={3} md={4} xs={6}>
                                                <NumberForm
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                    value={room.room_doors}
                                                    name="room_doors"
                                                    labelTxt={t("room.roomDoors")}
                                                    onChangeValue={(e) => this.handleNumberValue(e)}
                                                />
                                            </Col>
                                            <Col lg={3} md={4} xs={6}>
                                                <NumberForm
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                    value={room.room_windows}
                                                    name="room_windows"
                                                    labelTxt={t("room.roomWindows")}
                                                    onChangeValue={(e) => this.handleNumberValue(e)}
                                                />
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <TextareaForm
                                                    value={room.room_description}
                                                    onChangeValue={(e) => this.handleRoomData(e.target.name, e.target.value)}
                                                    labelTxt={t("room.roomDescrLabel")}
                                                    placeholderTxt={t("room.roomDescrPlaceholder")}
                                                    name="room_description"
                                                    id="roomDescription"
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton
                                                        t={t}
                                                        mssgTooltip={t("info.emptyForm")}
                                                        btnTxt={t("add")}
                                                        tooltipID="tooltipDisabledNotFilled"
                                                        size="lg"
                                                    />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg">
                                                        {t("add")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddRoom;
