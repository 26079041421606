import React, { Component, Fragment } from "react";
import { Container, Row, Col, Table, Form, Dropdown, Button } from "react-bootstrap";
import $ from "jquery";
import NavDetails from "../../../navigation/NavDetails";
import LoadingSpinnerInner from "../../../spinners/LoadingSpinnerInner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import NoPermission from "../../../other/NoPermission";

class ShopItemDetails extends Component {
    state = {
        loading: true,
        shopItem: null,
        shopItemVariants: null,
        changedValues: false,
        imageFile: null,
        imageFileURL: null,
        loadingImage: false,
        showShopItemVariants: false,
        shopItemLabels: null,
        alertUploadImage: "",
        activeEvent: false,
        showDeleteShopItem: false,
        modalBody1: true,
        modalBody2: false,
        noPermission: false,
        langs: null,
    };

    getProductData = async (shopItemID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert("");
        this.setState({ loading: true });
        const passData = {
            shopItemID: shopItemID,
        };

        try {
            const response = await fetch(`${fetchURL}/shop-item-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const shopItem = fetchedData.shopItem;

                        if (shopItem[0].blob_data) {
                            let blob = shopItem[0].blob_data;
                            let buffer = Buffer.from(blob);
                            let bufferBase64 = buffer.toString("base64");
                            shopItem[0].blob_data = bufferBase64;
                        } else shopItem[0].blob_data = null;
                        this.setState({ shopItem: shopItem, shopItemVariants: fetchedData.shopItemVariants, shopItemLabels: fetchedData.shopItemLabelNames, loading: false, langs: fetchedData.langs });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        const { onResizeWindow, currentPage } = this.props;
        const shopItemParams = currentPage.params;
        onResizeWindow("basic");
        this.getProductData(shopItemParams.shopItemID);
        $("#uploadFile").on("change", function () {
            $("span").text($(this).val());
        });
    }

    componentDidUpdate() {
        const { loading } = this.state;
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !loading) onResizeWindow("basic");
        if (!loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, changedValues, shopItemVariants, noPermission, langs } = this.state;
        const { onCancelChangeValuesInFormes, onBackToPreviousPage, onCheckUserPrivilage, currentPage, onChangeNavAndPage, t } = this.props;

        const shopItem = this.state.shopItem ? this.state.shopItem[0] : null;
        const currentPageParams = { nav: "shop", subpage: "shopItemDetails", params: { shopItemID: currentPage.params.shopItemID } };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t("shop.productDetails")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col>
                                    {onCheckUserPrivilage("ACCR_SHOP_ITEMS_ACTIONS") ? (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-primary" id="dropdownParticipantActions" className="float-right mt-1 d-inline-block">
                                                {t("dropdowns.actions")}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    as="button"
                                                    onClick={() =>
                                                        onChangeNavAndPage({ nav: "shop", subpage: "editShopItem", params: { shopItemID: currentPage.params.shopItemID } }, currentPageParams)
                                                    }
                                                >
                                                    {t("shop.editProduct")}
                                                </Dropdown.Item>
                                                <hr />
                                                <Dropdown.Item as="button" onClick={() => this.getProductData(currentPage.params.shopItemID)}>
                                                    {t("dropdowns.refreshData")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => {
                                                this.setState({ loading: true });
                                                this.getProductData(currentPage.params.shopItemID);
                                            }}
                                            className="btn-sync float-right mt-1"
                                        >
                                            <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col lg={8} xs={12}>
                                                <p className="h5 mb-3">{t("data")}</p>
                                                <Table bordered hover className="table-details" responsive="lg" id="shopItemDetailsTable">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ width: "200px" }}>{t("shop.productCodeLabel")}</td>
                                                            <td>{shopItem.shit_code}</td>
                                                        </tr>
                                                        {langs.map((e, i) => (
                                                            <Fragment key={i}>
                                                                <tr>
                                                                    <td>
                                                                        {t("shop.productNameLabel")} {e.lang_code}
                                                                    </td>
                                                                    <td>{e.shit_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        {t("shop.productDescrLabel")} {e.lang_code}
                                                                    </td>
                                                                    <td>{e.shit_description}</td>
                                                                </tr>
                                                            </Fragment>
                                                        ))}
                                                        <tr>
                                                            <td>{t("shop.itemPrice")}</td>
                                                            <td>
                                                                {shopItem.shit_price.toFixed(2).replace(".", ",")} {t("currency")}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("shop.shopCategory")}</td>
                                                            <td>{shopItem.shcat_name}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>{t("shop.amount")}</td>
                                                            <td>{shopItem.shit_quantity === "" ? <span className="opacity-4">{t("shop.unlimited")}</span> : shopItem.shit_quantity}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("shop.availability")}</td>
                                                            <td>{shopItem.shit_available ? <span className="text-success">{t("yes")}</span> : <span className="text-danger">{t("no")}</span>}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("shop.productVariants")}</td>
                                                            <td>
                                                                {shopItemVariants ? (
                                                                    <Table style={{ width: "100%" }} className="table-inside">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>{t("shop.variantName")}</th>
                                                                                <th>{t("shop.amount")}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {shopItemVariants.map((v, i) => (
                                                                                <tr key={i}>
                                                                                    <td style={{ width: "50%" }}>{v.shit_variant_name}</td>
                                                                                    <td>
                                                                                        {shopItem.shit_quantity ? v.shit_variant_quantity : <span className="opacity-4">{t("shop.unlimited")}</span>}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                ) : (
                                                                    <span className="opacity-4">{t("lack")}</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("shop.isItemLimited")}:</td>
                                                            <td>
                                                                {shopItem.shit_limited === "Y" ? (
                                                                    <Fragment>
                                                                        {this.state.shopItemLabels.map((e, i) => (
                                                                            <Fragment key={i}>
                                                                                {i + 1 === this.state.shopItemLabels.length ? (
                                                                                    <Fragment>
                                                                                        <b>{e}</b>
                                                                                    </Fragment>
                                                                                ) : (
                                                                                    <Fragment>
                                                                                        <b>{e}</b>,{" "}
                                                                                    </Fragment>
                                                                                )}
                                                                            </Fragment>
                                                                        ))}
                                                                    </Fragment>
                                                                ) : (
                                                                    <span className="opacity-4">{t("shop.itemsList.all")}</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("shop.priorityDisplay")}</td>
                                                            <td>{shopItem.shit_priority}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("event.event")}</td>
                                                            <td>{shopItem.event_name}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col lg={4} xs={12}>
                                                <p className="h5 mb-3">{t("shop.itemImg")}</p>
                                                {shopItem.blob_data ? (
                                                    <img src={`data:${shopItem.blob_file_type};base64,${shopItem.blob_data}`} style={{ width: "100%" }} alt={shopItem.blob_name} />
                                                ) : (
                                                    <img src="images/placeholder_img_dark.jpg" alt="placeholder_img" style={{ width: "100%" }} />
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ShopItemDetails;
