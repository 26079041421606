import React, { Component } from "react";
import { Row, Col, Button, Form, Container, Alert } from "react-bootstrap";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../navigation/NavDetails";
import IdentType from "../../forms/dropdowns/IdentType";
import IdentObject from "../../forms/dropdowns/IdentObject";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import SelectPerson from "../../forms/dropdowns/SelectPerson";
import ShowQRCode from "../../other/ShowQRCode";
import { Fragment } from "react";
import NoPermission from "../../other/NoPermission";

class AddIdent extends Component {
    state = {
        identTypesSelected: "",
        identObjectsSelected: "",
        personFullNameSelected: "",
        qrCode: "",
        changedValues: false,
        rolesList: null,
        showScanner: false,
        showConfirmModal: false,
        rowRoleID: 1,
        eventsList: [],
        loading: false,
        assignedIdent: "",
        personAssignedToIdent: "",
        noPermission: false,
    };

    handleScanQRCode = (code) => {
        this.setState({ showScanner: false, changedValues: true });
        if (code) {
            this.setState({
                qrCode: code,
            });
        }
        this.props.onChangeValuesInFormes();
        this.checkAssignedIdent({ value: code });
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMssg = "";
        let preventValidation = false;
        const { assignedIdent, qrCode, identTypesSelected, identObjectsSelected, personFullNameSelected } = this.state;

        if (assignedIdent) {
            preventValidation = true;
            $("#alertAssignedIdent .alert").addClass("shake-horizontal");
            setTimeout(() => {
                $("#alertAssignedIdent .alert").removeClass("shake-horizontal");
            }, 1050);
        }

        if (qrCode === "") {
            errorMssg += t("error.noScannedCode");
        }
        console.log(identTypesSelected);
        if (identTypesSelected.length === 0 || !identTypesSelected) {
            errorMssg += t("error.noChosenIdentType");
            $("#identTypeDropdown").addClass("danger");
        }

        if (identObjectsSelected.length === 0 || !identObjectsSelected) {
            errorMssg += t("error.noChosenIdentObject");
            $("#identObjectDropdown").addClass("danger");
        }

        if (personFullNameSelected.length === 0 || !personFullNameSelected) {
            errorMssg += t("error.noChosenPerson");
            $("#fullnameDropdown").addClass("danger");
        }

        if (errorMssg || preventValidation) {
            onShowAlert({ variant: "danger", message: errorMssg });
        } else {
            const passData = {
                identValue: qrCode,
                identType: identTypesSelected.value,
                identObject: identObjectsSelected.value,
                userID: personFullNameSelected.person_id.value,
            };
            //console.log(passData);
            this.insertIdent(passData);
        }
    };

    insertIdent = async (passData) => {
        const { fetchURL, onShowAlert, onChangeNavAndPage, onCancelChangeValuesInFormes, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.addingIdent") });

        try {
            const response = await fetch(`${fetchURL}/insert-ident`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onCancelChangeValuesInFormes();
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        onChangeNavAndPage({ nav: "idents", subpage: "identsList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    checkAssignedIdent = async (passData) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert("");

        try {
            const response = await fetch(`${fetchURL}/check-assigned-ident`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), qrCode: "" });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "assigned") this.setState({ assignedIdent: t(`warning.${fetchedData.code}`), qrCode: "" });
                    else this.setState({ assignedIdent: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    //sprawdzanie, czy dana osoba ma przypisany już identyfikator
    checkAssignedPerson = async (passData) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert("");
        //console.log(passData);
        try {
            const response = await fetch(`${fetchURL}/check-assigned-object`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "assigned") this.setState({ personAssignedToIdent: t(`warning.${fetchedData.code}`) });
                    else this.setState({ personAssignedToIdent: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleSelectedPerson = (event, value) => {
        this.setState(
            {
                personFullNameSelected: {
                    person_id: value,
                    person_fullname: event.target.textContent, //uwaga: event.target.textContent po wpisaniu i kliknięciu enter może pokazać puste, więc nie należy brać tego jako wartość pod uwagę
                },
                changedValues: true,
            },
            () => {
                this.props.onChangeValuesInFormes();
                const data = {
                    objectID: value.value,
                    identObject: this.state.identObjectsSelected.value,
                };
                this.checkAssignedPerson(data);
            }
        );
        $("#fullnameDropdown").removeClass("danger");
    };

    handleIdentTypeSelected = (event, value) => {
        //event ma tutaj pozostać nawet jeśli nie jest używane
        console.log("lol", value);
        this.setState({
            identTypesSelected: {
                value: value,
                text: event.target.textContent, //uwaga: event.target.textContent po wpisaniu i kliknięciu enter może pokazać puste, więc nie należy brać tego jako wartość pod uwagę
            },
            changedValues: true,
        });
        this.props.onChangeValuesInFormes();
        $("#identTypeDropdown").removeClass("danger");
    };

    handleIdentObjectSelected = (event, value) => {
        const textContent = event.target.textContent;
        const valueContent = value;
        this.setState(
            {
                identObjectsSelected: "",
            },
            () => {
                this.setState({
                    identObjectsSelected: {
                        value: valueContent,
                        text: textContent, //uwaga: event.target.textContent po wpisaniu i kliknięciu enter może pokazać puste, więc nie należy brać tego jako wartość pod uwagę
                    },
                    changedValues: true,
                    personFullNameSelected: "", //trzeba zresetować ewentualnie wcześniej wybraną opcję osoby {t('from')} dropdowna
                    personAssignedToIdent: "",
                });
            }
        );
        this.props.onChangeValuesInFormes();
        $("#identObjectDropdown").removeClass("danger");
    };

    addIdentView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/add-ident-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow, activeEvent, onShowAlert } = this.props;
        onShowAlert("");
        if (activeEvent) {
            this.setState({ activeEvent: activeEvent.name });
        }
        onResizeWindow("basic");
        this.addIdentView();
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { qrCode, changedValues, showScanner, personAssignedToIdent, identObjectsSelected, loading, noPermission, assignedIdent, identTypesSelected } = this.state;
        const { scannerHeight, onCancelChangeValuesInFormes, onBackToPreviousPage, onShowAlert, activeEvent, t, onShowHomePage } = this.props;
        console.log("type: ", identTypesSelected);
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t("ident.addIdent")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <IdentType t={t} onShowHomePage={onShowHomePage} onIdentTypeSelected={this.handleIdentTypeSelected} defaultValue="default" />
                                            </Col>
                                            {identTypesSelected ? (
                                                <Fragment>
                                                    {identTypesSelected.value === "QR" ? (
                                                        <Col lg={4} xs={12}>
                                                            <Form.Group>
                                                                <Form.Label>
                                                                    {t("ident.qrCode")} <span className="required">*</span>
                                                                </Form.Label>
                                                                <Form.Control id="qrCode" type="hidden" name="qrCode" value={qrCode} className="req" />
                                                                <div className="mb-1">
                                                                    <Button className="w-100" variant="outline-primary" onClick={() => this.setState({ showScanner: true })}>
                                                                        {qrCode ? t("ident.scanNewCode") : t("ident.scanQRCode")}
                                                                    </Button>
                                                                    <p className="mt-2">{qrCode ? t("ident.codeScanned") : ""}</p>
                                                                </div>
                                                                {assignedIdent && (
                                                                    <Alert variant="danger" className="alert-small">
                                                                        {assignedIdent}
                                                                    </Alert>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                    ) : null}
                                                </Fragment>
                                            ) : null}

                                            <Col lg={4} xs={12}>
                                                <IdentObject t={t} onShowHomePage={onShowHomePage} onIdentObjectSelected={this.handleIdentObjectSelected} defaultValue="default" />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={4} xs={12}>
                                                <Form.Label>
                                                    {t("ident.assignment")} <span className="required">*</span>
                                                </Form.Label>
                                                {identObjectsSelected || identObjectsSelected.value === "default" ? (
                                                    identObjectsSelected.value === "USER" || identObjectsSelected.value === "PART" ? (
                                                        <Fragment>
                                                            <SelectPerson
                                                                identObject={identObjectsSelected.value}
                                                                fetchURL={this.props.fetchURL}
                                                                onSelectedPerson={this.handleSelectedPerson}
                                                                defaultValue="default"
                                                                alertMssg={personAssignedToIdent}
                                                                onShowAlert={onShowAlert}
                                                                activeEvent={activeEvent}
                                                                t={t}
                                                                onShowHomePage={onShowHomePage}
                                                            />
                                                            {personAssignedToIdent && (
                                                                <Alert variant="warning" className="alert-small">
                                                                    {personAssignedToIdent}
                                                                </Alert>
                                                            )}
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>{/*TO DO - wybór z listy itemów*/}</Fragment>
                                                    )
                                                ) : (
                                                    <Alert variant="info">
                                                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                                        <span>{t("ident.firstChooseIdentObject")}</span>
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton t={t} mssgTooltip={t("info.emptyForm")} btnTxt={t("assign")} tooltipID="tooltipDisabledNotFilled" size="lg" />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg" className="mb-3">
                                                        {t("assign")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                        {showScanner && (
                            <ShowQRCode
                                qrcode={qrCode}
                                onScanQRCode={this.handleScanQRCode}
                                qrReaderHeight={scannerHeight}
                                showScanner={showScanner}
                                onHideScanner={() => this.setState({ showScanner: false })}
                                t={t}
                                onShowHomePage={onShowHomePage}
                                onShowAlert={onShowAlert}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddIdent;
