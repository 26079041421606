import React, { Component, Fragment } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import $ from "jquery";
import NavDetails from "../../navigation/NavDetails";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

class RoomBookingDetails extends Component {
    state = {
        loading: true,
        noPermission: false,
        roomBooking: null,
        roomBookingParts: null,
    };

    roomBookingDetails = async () => {
        const { fetchURL, onShowAlert, currentPage, t } = this.props;

        const passData = {
            roomBookingID: currentPage.params.roomBookingID,
        };
        try {
            const response = await fetch(`${fetchURL}/room-booking-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ roomBooking: fetchedData.roomBooking, loading: false, roomBookingParts: fetchedData.roomBookingParts });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true }, () => this.roomBookingDetails());
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
        $("#uploadFile").on("change", function () {
            $("span").text($(this).val());
        });

        this.roomBookingDetails();
    }

    componentDidUpdate() {
        const { loading } = this.state;
        const { onResizeWindow } = this.props;

        if ($(".scrolled-content").outerHeight() && !loading) onResizeWindow("basic");
        if (!loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, changedValues, noPermission, roomBooking, roomBookingParts } = this.state;
        const { onCancelChangeValuesInFormes, onBackToPreviousPage, onChangeNavAndPage, currentPage, t } = this.props;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header="szczegóły rezerwacji"
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col className="text-right">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <div className="scrolled-content" style={{ display: "none" }}>
                                <Container className="py-3">
                                    <Row>
                                        <Col lg={8} xs={12}>
                                            <p className="h5 mb-3">Dane rezerwacji</p>
                                            <Table bordered hover className="table-details" responsive="lg" id="roomBookingTable">
                                                <tbody>
                                                    <tr>
                                                        <td>Pokój</td>
                                                        <td className="align-middle">
                                                            <Button
                                                                className="mb-2"
                                                                variant="link"
                                                                onClick={() =>
                                                                    onChangeNavAndPage(
                                                                        { nav: "rooms", subpage: "roomDetails", params: { roomID: roomBooking[0].room_id } },
                                                                        currentPageParams
                                                                    )
                                                                }
                                                            >
                                                                {roomBooking[0].room_code}
                                                            </Button>
                                                            <p className="small mb-1">Rozmiar: {roomBooking[0].room_size_name}</p>
                                                            <p className="small mb-1">
                                                                Pojemność:{" "}
                                                                {roomBooking[0].room_capacity ? roomBooking[0].room_capacity : <span className="opacity-4">Brak informacji</span>}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Nazwa pokoju</td>
                                                        <td>{roomBooking[0].room_name}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Rezerwacja ze zgłoszenia</td>
                                                        <td>{roomBooking[0].room_req_id ? t("yes") : t("no")}</td>
                                                    </tr>
                                                    {roomBooking[0].room_req_id && (
                                                        <Fragment>
                                                            <tr>
                                                                <td>Opiekun pokoju</td>
                                                                <td className="align-middle">
                                                                    <Button
                                                                        variant="link"
                                                                        onClick={() =>
                                                                            onChangeNavAndPage(
                                                                                {
                                                                                    nav: "participants",
                                                                                    subpage: "participantDetails",
                                                                                    params: { partID: roomBooking[0].part_id, userID: roomBooking[0].user_id },
                                                                                },
                                                                                currentPageParams
                                                                            )
                                                                        }
                                                                    >
                                                                        {roomBooking[0].room_keeper} ({roomBooking[0].part_ident_number})
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Grupa</td>
                                                                <td>{roomBooking[0].room_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Komentarz zgłoszenia</td>
                                                                <td>
                                                                    {roomBooking[0].room_req_comment ? (
                                                                        <i>{roomBooking[0].room_req_comment}</i>
                                                                    ) : (
                                                                        <span className="opacity-4">Brak komentarza</span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    )}
                                                    <tr>
                                                        <td>Lista przypisanych uczestników</td>
                                                        <td className="align-middle">
                                                            {roomBookingParts.map((e, i) => (
                                                                <Fragment key={i}>
                                                                    <Button
                                                                        className="mb-1 d-block"
                                                                        variant="link"
                                                                        onClick={() =>
                                                                            onChangeNavAndPage(
                                                                                {
                                                                                    nav: "participants",
                                                                                    subpage: "participantDetails",
                                                                                    params: { partID: e.part_id, userID: e.user_id },
                                                                                },
                                                                                currentPageParams
                                                                            )
                                                                        }
                                                                    >
                                                                        {e.user_fullname} ({e.part_ident_number})
                                                                    </Button>
                                                                </Fragment>
                                                            ))}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Rezerwację dokonał/-ła</td>
                                                        <td>{roomBooking[0].create_user}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Data rezerwacji</td>
                                                        <td>{roomBooking[0].create_date}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default RoomBookingDetails;
