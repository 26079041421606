import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import $ from "jquery";

class BarClientStatusesDropdown extends Component {
    state = { loading: true, noPermission: false, barClientStatuses: null };

    getBarClientStatuses = async () => {
        const { fetchURL, onShowAlert, t } = this.props;

        try {
            const response = await fetch(`${fetchURL}/bar-client-statuses`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Status": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const barClientStatuses = fetchedData.barClientStatuses;
                        barClientStatuses.unshift({ key: 0, value: "all", text: t("selectAll") });
                        this.setState({ barClientStatuses: barClientStatuses, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getBarClientStatuses();
    }

    render() {
        const { noPermission, loading, barClientStatuses } = this.state;
        const { t, selectedStatus, onSelectStatus } = this.props;
        return (
            <Fragment>
                <Form.Label className="w-100">{t("barClients.barClientStatus")}</Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : barClientStatuses && !loading ? (
                    <Dropdown
                        value={selectedStatus}
                        id="barClientStatusesDropdown"
                        className="req mb-3"
                        placeholder={t("select")}
                        fluid
                        selection
                        options={barClientStatuses}
                        onChange={(event, { value }) => {
                            $("#barClientStatusesDropdown").removeClass("danger");
                            onSelectStatus(value);
                        }}
                    />
                ) : (
                    <LoadingProgressBar t={t} />
                )}
            </Fragment>
        );
    }
}

export default BarClientStatusesDropdown;
