import React, { Component, Fragment } from "react";
import NavDetails from "../../navigation/NavDetails";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { Checkbox } from "semantic-ui-react";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import Switch from "@material-ui/core/Switch";
import $ from "jquery";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser } from "@fortawesome/free-solid-svg-icons";
import ParticipantsListForGroup from "./ParticipantsListForGroup";

class EditGroupMembers extends Component {
    state = {
        loading: true,
        groupMembers: null,
        availableGroupMembers: null,
        formHeight: null,
        searchedPhrase: "",
        searchedParticipants: null,
    };

    handleSearch = (forcedRefresh, e) => {
        const { availableGroupMembers, groupMembers } = this.state;
        console.log(groupMembers);
        const searchedPhrase = forcedRefresh ? this.state.searchedPhrase : e.target.value.toLowerCase();
        if (!forcedRefresh) this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredMembers = availableGroupMembers.concat(groupMembers);
            filteredMembers = filteredMembers.filter((el) => el.user_fullname.toString().toLowerCase().includes(searchedPhrase));
            console.log(filteredMembers);
            this.setState({ searchedParticipants: filteredMembers });
        } else {
            this.setState({ searchedParticipants: null });
        }
    };

    getGroupMembers = async (groupID) => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = {
            groupID: groupID,
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/group-members`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        const fetchedGroupMembers = fetchedData.groupMembers;
                        const groupMembers = [];
                        fetchedGroupMembers.forEach((e) => {
                            groupMembers.push({
                                part_id: e.part_id,
                                user_fullname: e.user_fullname,
                                part_affiliation_verified: e.part_affiliation_verified === "Y" ? true : false,
                                checked: true,
                                coordinator: e.user_id === fetchedData.coordinatorID ? true : false,
                            });
                        });

                        this.setState({ groupMembers: groupMembers }, () => this.getAvailableGroupMembers());
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    getAvailableGroupMembers = async () => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/participants-not-assigned-2-group`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        const availableParts = fetchedData.partFullnameCheckboxes;
                        /* availableParts[0].member_separator = true;
                        const { groupMembers } = this.state;
                        groupMembers.forEach((m, i) => {
                            availableParts.unshift({
                                part_id: m.part_id,
                                user_fullname: m.user_fullname,
                                part_affiliation_verified: m.part_affiliation_verified,
                                checked: true,
                            });
                        }); */
                        onShowAlert("");
                        this.setState({ availableGroupMembers: availableParts, originalAvailableGroupMembers: availableParts, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    updateGroupMembers = async (selectedGroupMembers) => {
        const { fetchURL, currentPage, onCancelChangeValuesInFormes, onShowAlert, t } = this.props;
        const passData = {
            groupID: currentPage.params.groupID,
            selectedGroupMembers: selectedGroupMembers,
        };
        try {
            const response = await fetch(`${fetchURL}/update-group-members`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ changedValues: false });
                        onCancelChangeValuesInFormes();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleChangeMemberAffiliation = (value, index) => {
        const { onChangeValuesInFormes } = this.props;
        const list = [...this.state.availableGroupMembers];
        list[index].part_affiliation_verified = value;
        this.setState({ availableGroupMembers: list, changedValues: true });
        onChangeValuesInFormes();
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { groupMembers, availableGroupMembers } = this.state;
        let selectedGroupMembers = groupMembers.concat(availableGroupMembers);
        selectedGroupMembers = selectedGroupMembers.filter((m) => m.checked);
        //console.log(selectedGroupMembers);
        this.updateGroupMembers(selectedGroupMembers);
    };

    handleSelectedMembers = (value, index) => {
        const { onChangeValuesInFormes } = this.props;
        const { availableGroupMembers } = this.state;
        const list = [...availableGroupMembers];
        list[index].checked = value;
        this.setState({ availableGroupMembers: list, changedValues: true });
        onChangeValuesInFormes();
    };

    componentDidMount() {
        const { currentPage } = this.props;
        if (currentPage.params) this.getGroupMembers(currentPage.params.groupID);
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, availableGroupMembers, changedValues, searchedPhrase, groupMembers, searchedParticipants } = this.state;
        const { onCancelChangeValuesInFormes, onBackToPreviousPage, t } = this.props;

        return (
            <Fragment>
                <Container fluid className="nav-details">
                    <Row>
                        <NavDetails
                            t={t}
                            header={t("group.manageMembers")}
                            changedValues={changedValues}
                            onChangeView={onBackToPreviousPage}
                            onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                        />
                        <Col></Col>
                    </Row>
                </Container>

                {loading ? (
                    <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                        <LoadingSpinnerInner />
                    </div>
                ) : (
                    <Form onSubmit={this.handleCheckValidation}>
                        <div className="scrolled-content" style={{ display: "none" }}>
                            <Container className="py-3">
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>{t("form.searching")}</Form.Label>
                                            <InputGroup className="mb-4">
                                                <Form.Control type="text" placeholder={t("form.search")} onChange={(e) => this.handleSearch(false, e)} value={searchedPhrase} />
                                                {searchedPhrase && (
                                                    <InputGroup.Append>
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                this.setState({
                                                                    searchedPhrase: "",
                                                                    searchedParticipants: null,
                                                                })
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faEraser} />
                                                        </Button>
                                                    </InputGroup.Append>
                                                )}
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {searchedParticipants !== null ? (
                                    <Fragment>
                                        <Row>
                                            <Col xs={8}>
                                                <Form.Label className="h5 mb-3">{t("group.searchedParticipants")}</Form.Label>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Label className="h5 mb-3">{t("group.verifiedMembership")}</Form.Label>
                                            </Col>
                                        </Row>
                                        <div className="members-list">
                                            {searchedParticipants.length === 0 ? (
                                                <p className="opacity-4">{t("group.noMembers")}</p>
                                            ) : (
                                                <ParticipantsListForGroup
                                                    t={t}
                                                    partList={searchedParticipants}
                                                    onChangeCheckbox={(data, i) => {
                                                        const { searchedParticipants, groupMembers } = this.state;
                                                        const partID = searchedParticipants[i].part_id;
                                                        const list = [...groupMembers];
                                                        list.forEach((e) => {
                                                            if (e.part_id === partID) e.checked = data.checked;
                                                        });
                                                        this.props.onChangeValuesInFormes();
                                                        this.setState({ groupMembers: list, changedValues: true }, () => this.handleSearch(true, null));
                                                    }}
                                                    onChangeSwitch={(checked, i) => {
                                                        const { searchedParticipants, groupMembers } = this.state;
                                                        const partID = searchedParticipants[i].part_id;
                                                        const list = [...groupMembers];
                                                        list.forEach((e) => {
                                                            if (e.part_id === partID) e.part_affiliation_verified = checked;
                                                        });
                                                        this.props.onChangeValuesInFormes();
                                                        this.setState({ groupMembers: list, changedValues: true }, () => this.handleSearch(true, null));
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <Row>
                                            <Col xs={8}>
                                                <Form.Label className="h5 mb-3">{t("group.currentMembers")}</Form.Label>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Label className="h5 mb-3">{t("group.verifiedMembership")}</Form.Label>
                                            </Col>
                                        </Row>
                                        <div className="members-list">
                                            {groupMembers.length === 0 ? (
                                                <p className="opacity-4">{t("group.noMembers")}</p>
                                            ) : (
                                                <ParticipantsListForGroup
                                                    t={t}
                                                    partList={groupMembers}
                                                    onChangeCheckbox={(data, i) => {
                                                        const { onChangeValuesInFormes } = this.props;
                                                        const { groupMembers } = this.state;
                                                        const list = [...groupMembers];
                                                        list[i].checked = data.checked;
                                                        this.setState({ groupMembers: list, changedValues: true });
                                                        onChangeValuesInFormes();
                                                    }}
                                                    onChangeSwitch={(checked, i) => {
                                                        const { onChangeValuesInFormes } = this.props;
                                                        const list = [...this.state.groupMembers];
                                                        list[i].part_affiliation_verified = checked;
                                                        this.setState({ groupMembers: list, changedValues: true });
                                                        onChangeValuesInFormes();
                                                    }}
                                                />
                                            )}
                                            <Row className="member-separator mt-2"></Row>
                                            <Row>
                                                <Col xs={8}>
                                                    <Form.Label className="h5 my-3">{t("group.addNewMembers")}</Form.Label>
                                                </Col>
                                                <Col xs={4}>
                                                    <Form.Label className="invisible">{t("group.verifiedMembership")}</Form.Label>
                                                </Col>
                                            </Row>
                                            {availableGroupMembers.length === 0 ? (
                                                <p className="opacity-4">{t("user.noAvailableUsers")}</p>
                                            ) : (
                                                availableGroupMembers.map((m, i) => (
                                                    <Row key={i}>
                                                        <Col md={8} xs={6}>
                                                            <Checkbox
                                                                className="pt-2"
                                                                label={m.user_fullname}
                                                                value={m.part_id}
                                                                checked={m.checked}
                                                                onChange={(event, data) => this.handleSelectedMembers(data.checked, i)}
                                                            />
                                                        </Col>
                                                        <Col md={2} xs={4}>
                                                            <Switch
                                                                id={`partAffiliationVerified${i}`}
                                                                checked={m.part_affiliation_verified}
                                                                onChange={(e) => this.handleChangeMemberAffiliation(e.target.checked, i)}
                                                                name="part_affiliation_verified"
                                                                disabled={!m.checked ? true : false}
                                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))
                                            )}
                                        </div>
                                    </Fragment>
                                )}
                            </Container>
                        </div>
                        <div className="content-bottom">
                            <Container>
                                <Row>
                                    <Col xs={12} className="py-3">
                                        {!changedValues ? (
                                            <TooltipWithDisabledButton mssgTooltip={t("info.uneditedForm")} btnTxt={t("edit")} tooltipID="tooltipDisabledNotFilled" size="lg" />
                                        ) : (
                                            <Button type="submit" variant="primary" size="lg">
                                                {t("edit")}
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Form>
                )}
            </Fragment>
        );
    }
}

export default EditGroupMembers;
