import React, { Component, Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import $ from "jquery";
import NavDetails from "../../navigation/NavDetails";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";

class RoomsMap extends Component {
    state = {
        loading: false,
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        onResizeWindow("basic");
        $(".scrolled-content").fadeIn();

        $(".map-room").hover(function () {
            $(this)
                .children()
                .children()
                .css({ top: `${-50}px`, left: 0 });
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;

        if ($(".scrolled-content").outerHeight()) onResizeWindow("basic");

        $(".map-room").hover(function () {
            $(this)
                .children()
                .children()
                .css({ top: `${-50}px`, left: 0 });
        });
    }

    render() {
        const { loading, noPermission } = this.state;
        const { onBackToPreviousPage, t } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails t={t} header={t("room.roomsMap")} onChangeView={onBackToPreviousPage} />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <div className="scrolled-content" style={{ display: "none" }}>
                                <Container fluid className="text-center m-auto pt-4">
                                    <div id="mapContainer" className="m-auto">
                                        <div className="map-room" style={{ left: "107px", top: "74px" }}>
                                            <Button onClick={() => console.log("ble")} variant="link" className="map-room-descr" style={{ width: "176px", height: "121px" }}>
                                                <span>
                                                    {t("room.room")} 1, {t("room.assignedParts")}: 5
                                                </span>
                                            </Button>
                                        </div>
                                        <div className="map-room" style={{ left: "285px", top: "74px" }}>
                                            <Button onClick={() => console.log("ble")} variant="link" className="map-room-descr" style={{ width: "68px", height: "121px" }}>
                                                <span>
                                                    {t("room.room")} 2, {t("room.assignedParts")}: 7
                                                </span>
                                            </Button>
                                        </div>
                                    </div>
                                </Container>
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default RoomsMap;
