import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class RoomsSizeDropdown extends Component {
    state = { roomsSizeList: null, noPermission: false };

    getRoomsSizeList = async () => {
        const { fetchURL, filter, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/rooms-size-list`, {
                credentials: "include",
                method: "POST",
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const roomSizes = fetchedData.roomsSizeList;
                        if (filter) {
                            roomSizes.unshift({
                                key: roomSizes.length + 1,
                                value: "all",
                                text: t("selectAll"),
                            });
                        }
                        this.setState({ roomsSizeList: roomSizes });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getRoomsSizeList();
    }

    render() {
        const { roomsSizeList, noPermission } = this.state;
        const { onChangeRoomsSizeValue, defaultValue, req, id, t } = this.props;

        return (
            <Fragment>
                <Form.Label>
                    {t("room.roomSize")} {req && <span className="required">*</span>}
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        {roomsSizeList ? (
                            <Dropdown
                                className="mb-3"
                                placeholder={t("form.choose")}
                                fluid
                                selection
                                defaultValue={defaultValue}
                                options={roomsSizeList}
                                onChange={onChangeRoomsSizeValue}
                                id={id}
                            />
                        ) : (
                            <LoadingProgressBar t={t} />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default RoomsSizeDropdown;
