import React, { Fragment, Component } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import NavDetails from "../../navigation/NavDetails";
import NoPermission from "../../other/NoPermission";
import ParticipantPaymentsListTable from "./ParticipantPaymentsListTable";
import $ from "jquery";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

class PaymentsList extends Component {
    state = { participantPaymentsList: null, noPermission: false, loading: true, ticketPrice: null, partData: null, paymentStatus: "", newPaymentAmount: "" };

    getBasicData = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/participant-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const partData = fetchedData.participantData;
                        //obliczanie statusu wpłat uczestnika
                        const ticketPrice = partData[0].part_verified_discount
                            ? partData[0].part_verified_amount - (partData[0].part_verified_amount * partData[0].part_verified_discount) / 100
                            : partData[0].part_verified_amount;
                        let paymentStatus = "";
                        if (partData[0].payment_amount_accr - ticketPrice === 0) paymentStatus = "PAID";
                        else if (partData[0].payment_amount_accr - ticketPrice > 0) paymentStatus = "OVERPAID";
                        else paymentStatus = "UNDERPAID";

                        this.setState({ partData: partData, paymentStatus: paymentStatus, ticketPrice: ticketPrice, loading: false }, () => this.getPartPaymentList(partID));
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getPartPaymentList = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/participant-payments-list`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ participantPaymentsList: fetchedData.participantPaymentsList, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) {
            onResizeWindow("basic");
        }
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    componentDidMount() {
        const { partData, paymentStatus, newPaymentAmount } = this.state;
        const { onResizeWindow, currentPage } = this.props;
        this.getBasicData(currentPage.params.partID);
        //console.log(partData, paymentStatus, newPaymentAmount);
        if (partData && paymentStatus && newPaymentAmount === "") {
            const ticketPrice = partData[0].part_verified_discount
                ? (partData[0].part_verified_amount - (partData[0].part_verified_amount * partData[0].part_verified_discount) / 100).toFixed(2)
                : partData[0].part_verified_amount.toFixed(2);

            //obliczanie różnicy nadpłaty lub niedopłaty - będzie to kwota domyślna w inpucie do wypłaty lub wpłaty
            let paymentAmount = 0;
            if (paymentStatus === "UNDERPAID") {
                paymentAmount = ticketPrice - partData[0].payment_amount_accr;
            } else if (paymentStatus === "OVERPAID") {
                paymentAmount = partData[0].payment_amount_accr - ticketPrice;
            }

            this.setState({ newPaymentAmount: paymentAmount });
        }

        onResizeWindow("basic");
    }

    render() {
        const { participantPaymentsList, noPermission, loading, partData } = this.state;
        const { currentPage, onBackToPreviousPage, onCheckUserPrivilage, onChangeNavAndPage, t } = this.props;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails t={t} header={t("payment.paymentsList")} onChangeView={onBackToPreviousPage} />
                                <Col md={3} xs={12}>
                                    {onCheckUserPrivilage("ACCR_PART_ACTIONS") && (
                                        <Button
                                            className="float-right"
                                            variant="outline-primary"
                                            onClick={() =>
                                                onChangeNavAndPage(
                                                    {
                                                        nav: "participants",
                                                        subpage: "postPayment",
                                                        params: {
                                                            partID: currentPage.params.partID,
                                                            partName: partData[0].user_fullname,
                                                            partIdentNumber: partData[0].part_ident_number,
                                                        },
                                                    },
                                                    currentPageParams
                                                )
                                            }
                                        >
                                            {t("payment.registerTransaction")}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <div className="scrolled-content" style={{ display: "none" }}>
                                <Container className="py-3">
                                    <Row>
                                        <Col className="text-center">
                                            <p className="title-details">
                                                {partData[0].user_fullname}
                                                {partData[0].part_ident_number ? ` (${partData[0].part_ident_number})` : ""}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <p className="h4 my-4 form-title">
                                                {t("payment.paymentsSums")}{" "}
                                                <span className="h6">
                                                    <OverlayTrigger
                                                        placement="bottom"
                                                        overlay={<Tooltip id={`tooltipPaymentsHelper`}>{t("payment.accrPaymentSumHelper")}</Tooltip>}
                                                    >
                                                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                                                    </OverlayTrigger>
                                                </span>
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={5} lg={3}>
                                            <p className="mb-2">{t("payment.accrPaymentSum")}:</p>
                                        </Col>
                                        <Col xs={7} lg={9}>
                                            <p className="mb-2">
                                                {partData[0].payment_amount_accr === null || partData[0].payment_amount_accr === "" ? (
                                                    <span className="opacity-4">{t("payment.noPayments")}</span>
                                                ) : (
                                                    <b>
                                                        {partData[0].payment_amount_accr.toFixed(2)} {t("currency")}
                                                    </b>
                                                )}
                                            </p>
                                        </Col>

                                        <Col xs={5} lg={3}>
                                            <p className="mb-2">{t("payment.barPaymentSum")}:</p>
                                        </Col>
                                        <Col xs={7} lg={9}>
                                            <p className="mb-2">
                                                {partData[0].payment_amount_bar || partData[0].payment_amount_bar === 0 ? (
                                                    <b>
                                                        {partData[0].payment_amount_bar.toFixed(2)} {t("currency")}
                                                    </b>
                                                ) : (
                                                    <span className="opacity-4">{t("payment.noPayments")}</span>
                                                )}
                                            </p>
                                        </Col>
                                        <Col xs={5} lg={3}>
                                            <p className="mb-2">{t("payment.shopPaymentSum")}:</p>
                                        </Col>
                                        <Col xs={7} lg={9}>
                                            <p className="mb-2">
                                                {partData[0].payment_amount_shop || partData[0].payment_amount_shop === 0 ? (
                                                    <b>
                                                        {partData[0].payment_amount_shop.toFixed(2)} {t("currency")}
                                                    </b>
                                                ) : (
                                                    <span className="opacity-4">{t("payment.noPayments")}</span>
                                                )}
                                            </p>
                                        </Col>
                                        <Col xs={5} lg={3}>
                                            <p className="mb-2">{t("payment.otherPaymentSum")}:</p>
                                        </Col>
                                        <Col xs={7} lg={9}>
                                            <p className="mb-2">
                                                {partData[0].payment_amount_other || partData[0].payment_amount_other === 0 ? (
                                                    <b>
                                                        {partData[0].payment_amount_other.toFixed(2)} {t("currency")}
                                                    </b>
                                                ) : (
                                                    <span className="opacity-4">{t("payment.noPayments")}</span>
                                                )}
                                            </p>
                                        </Col>
                                        <Col xs={5} lg={3}>
                                            <p className="mb-2">{t("payment.allPaymentSum")}:</p>
                                        </Col>
                                        <Col xs={7} lg={9}>
                                            <p className="mb-2">
                                                {partData[0].payment_amount_all ? (
                                                    <b>
                                                        {partData[0].payment_amount_all.toFixed(2)} {t("currency")}
                                                    </b>
                                                ) : (
                                                    <span className="opacity-4">{t("payment.noPayments")}</span>
                                                )}
                                            </p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={12}>
                                            <p className="h4 my-4 form-title">{t("payment.paymentsList")}</p>
                                            <ParticipantPaymentsListTable
                                                participantPaymentsList={participantPaymentsList}
                                                t={t}
                                                currentPageParams={currentPageParams}
                                                onChangeNavAndPage={onChangeNavAndPage}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default PaymentsList;
