import React, { Component, Fragment } from "react";
import { Alert } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class SelectPerson extends Component {
    state = { fullName: null, noPermission: false };

    getFullName = async () => {
        const { fetchURL, onShowAlert, activeEvent, identObject, t } = this.props;
        const passData = {
            activeEvent: activeEvent,
        };
        try {
            const response = await fetch(`${fetchURL}/${identObject === "USER" ? "users-fullname" : identObject === "PART" ? "participants-fullname" : ""}`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ fullName: fetchedData.fullName });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { identObject, activeEvent } = this.props;
        if ((identObject === "PART" && activeEvent) || identObject !== "PART") this.getFullName();
    }

    componentDidUpdate() {
        //this.getFullName(this.props.identObject);
    }
    render() {
        const { fullName, noPermission } = this.state;
        const { onSelectedPerson, defaultValue, identObject, activeEvent, t } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : identObject === "PART" && !activeEvent ? (
                    <Alert variant="danger" className="alert-small">
                        {t("ident.noActiveEvent")}
                    </Alert>
                ) : fullName ? (
                    <Fragment>
                        {fullName.length > 0 ? (
                            <Dropdown
                                defaultValue={defaultValue}
                                id="fullnameDropdown"
                                className="req mb-3"
                                placeholder={t("selectOrType")}
                                fluid
                                search
                                selection
                                options={fullName}
                                onChange={(event, { value }) => onSelectedPerson(event, { value })}
                                noResultsMessage={t("participant.personNotFound")}
                            />
                        ) : (
                            <p className="mb-3 opacity-4">{t("ident.emptyPersonList")}</p>
                        )}
                    </Fragment>
                ) : (
                    <LoadingProgressBar t={t} />
                )}
            </Fragment>
        );
    }
}

export default SelectPerson;
