import React, { Fragment, Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./components/structure/Main";
import ResetPass from "./components/pages/account/ResetPass";
import ConfirmAccount from "./components/pages/account/ConfirmAccount";
import NoMatch from "./components/other/NoMatch";
import { withTranslation } from "react-i18next";

class App extends Component {
    render() {
        const url = "https://api.accr.test.lyzkon.pl";
        const moduleName = "ACCR_LOGIN";
        const { t } = this.props;
        return (
            <Fragment>
                <Router>
                    <Switch>
                        <Route exact path="/" component={() => <Main fetchURL={url} moduleName={moduleName} t={t} />} />
                        <Route path="/reset-password">
                            <Route path="/:url" render={(routeProps) => <ResetPass fetchURL={url} {...routeProps} t={t} />} />
                        </Route>
                        <Route path="/activate-account">
                            <Route path="/:url" render={(routeProps) => <ConfirmAccount fetchURL={url} {...routeProps} t={t} />} />
                        </Route>
                        <Route path="/reactivate-account">
                            <Route path="/:url" render={(routeProps) => <ConfirmAccount fetchURL={url} {...routeProps} t={t} />} />
                        </Route>
                        <Route component={() => <NoMatch t={t} />} />
                    </Switch>
                </Router>
            </Fragment>
        );
    }
}

export default withTranslation()(App);
