import React, { Component, Fragment } from "react";
import { Form, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

class GroupCoordinator extends Component {
    state = { groupCoordinatorsList: null, noPermission: false, alertAssignedCoordinator: "" };

    getGroupCoordinator = async () => {
        const { fetchURL, onShowAlert, activeEvent, coordinatorID, t, groupID } = this.props;
        const passData = { activeEvent: activeEvent, coordinatorID: coordinatorID, groupID: groupID };
        try {
            const response = await fetch(`${fetchURL}/group-coordinators`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const groupCoordinatorsList = fetchedData.groupCoordinatorsList,
                            coordList = [];
                        groupCoordinatorsList.forEach((e, i) => {
                            coordList.push({
                                key: i + 1,
                                text: `${e.user_fullname}${e.part_group_id ? ` (${t("group.currentVerifiedMember")})` : ""}`,
                                value: e.user_id,
                            });
                        });
                        console.log(coordList);
                        this.setState({ groupCoordinatorsList: coordList });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getGroupCoordinator();
    }

    render() {
        const { groupCoordinatorsList, noPermission } = this.state;
        const { onFullnameSelected, defaultValue, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {t("group.groupCoordinator")} <span className="required">*</span>{" "}
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltipGroupCoordinatorHelper`}>{t("group.groupCoordinatorHelper")}</Tooltip>}>
                        <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                    </OverlayTrigger>
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : groupCoordinatorsList ? (
                    groupCoordinatorsList.length > 0 ? (
                        <Dropdown
                            value={defaultValue}
                            id="groupCoordinatorDropdown"
                            className="req mb-3"
                            placeholder={t("selectOrType")}
                            fluid
                            search
                            selection
                            options={groupCoordinatorsList}
                            onChange={(event, { value }) => {
                                $("#groupCoordinatorDropdown").removeClass("danger");
                                onFullnameSelected(value);
                            }}
                            noResultsMessage={t("group.personNotFound")}
                        />
                    ) : (
                        <Alert variant="warning" className="alert-small">
                            {t("user.noAvailableUsers")}
                        </Alert>
                    )
                ) : (
                    <LoadingProgressBar t={t} />
                )}
            </Fragment>
        );
    }
}

export default GroupCoordinator;
