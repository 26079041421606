import React, { Fragment, Component } from "react";
import { Container, Row, Col, Button, Modal, Dropdown } from "react-bootstrap";
import $ from "jquery";
import NavDetails from "../../navigation/NavDetails";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";
import SendMailToChosenUsers from "../../modals/SendMailToChosenUsers";

class MailsList extends Component {
    state = {
        //loading: this.props.dataList ? false : true,
        loading: true,
        mailsTmplList: this.props.dataList,
        showSendMailToChosenUsersModal: false,
        showSendMailToAllUsersModal: false,
        showEmailDetailsModal: false,
        chosenEmailIndex: null,
        selectedEmails: null,
        usersEmails: null,
        noPermission: false,
    };

    getMailTemplatesList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/mail-templates-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission")
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else
                        this.setState({ mailsTmplList: fetchedData.mailsTmplList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(fetchedData.mailsTmplList);
                        });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
            this.setState({ loading: false });
        }
    };

    getListOfEmails = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/list-of-users-emails`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission")
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.setState({ usersEmails: fetchedData.mailsList });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
            this.setState({ loading: false });
        }
    };

    handleSelectEmailsList = (event, value) => {
        const selectedUsers = value;
        const usersEmails = this.state.usersEmails;
        const selectedUsersIDAndEmails = [];
        selectedUsers.forEach((u) => {
            usersEmails.forEach((e) => {
                if (u === e.value) {
                    selectedUsersIDAndEmails.push({ userID: u, userEmail: e.email });
                }
            });
        });
        this.setState({ selectedEmails: selectedUsersIDAndEmails });
    };

    sendEmailToUsers = async (emailsList) => {
        const { fetchURL, onShowAlert, t } = this.props;
        this.props.onBlockSendingEmails();
        const { chosenEmailIndex, mailsTmplList } = this.state;
        const passData = {
            selectedEmails: emailsList,
            email: mailsTmplList[chosenEmailIndex],
        };
        onShowAlert({ variant: "info", message: t("info.sendingEmails"), fading: 2000 });
        this.setState({ showSendMailToChosenUsersModal: false, showSendMailToAllUsersModal: false });
        try {
            const response = await fetch(`${fetchURL}/send-email-to-chosen-users`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response
                    .json()
                    .then((fetchedData) => {
                        if (fetchedData.status === false || fetchedData.status === "noPermission")
                            onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                        else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                        else {
                            onShowAlert({
                                variant: "success",
                                message:
                                    emailsList.length === 1
                                        ? t("mails.mailsSentToOnePerson")
                                        : `${t("mails.mailsSentToManyPeople1")}${emailsList.length}${t("mails.mailsSentToManyPeople2")}`,
                                fading: 3000,
                            });
                            this.props.onUnblockSendingEmails(mailsTmplList[chosenEmailIndex], emailsList.length);
                        }
                    })
                    .then(() => {
                        this.setState({ chosenEmailIndex: null, selectedEmails: null });
                    });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
            this.setState({ loading: false });
        }
    };

    sendEmailToAllUsers = () => {
        const { usersEmails } = this.state;
        let allUsers = [];
        usersEmails.forEach((e) => {
            allUsers.push({
                userID: e.value,
                userEmail: e.email,
            });
        });
        //console.log(this.state.mailsTmplList[this.state.chosenEmailIndex]);
        this.sendEmailToUsers(allUsers);
    };

    mailsListView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/mails-list-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        this.getMailTemplatesList();
                        this.getListOfEmails();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        this.mailsListView();
        onResizeWindow("basic");
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, mailsTmplList, showSendMailToChosenUsersModal, chosenEmailIndex, usersEmails, showSendMailToAllUsersModal, showEmailDetailsModal, noPermission } =
            this.state;
        const { onChangeNavAndPage, blockSendingEmails, currentPage, t, onShowHomePage } = this.props;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: "" };
        const contentLength = 300;
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails t={t} header="maile" hideBackButton={true} />
                                <Col>
                                    <Button
                                        variant="outline-primary"
                                        className="btn-sync float-right mt-1"
                                        onClick={() => onChangeNavAndPage({ nav: "mails", subpage: "addMail", params: "" }, currentPageParams)}
                                    >
                                        {t("mails.createNewTmpl")}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <div className="scrolled-content" style={{ display: "none" }}>
                                <Container className="py-3">
                                    <Row className="mt-3">
                                        {mailsTmplList.length > 0 ? (
                                            <Fragment>
                                                {mailsTmplList.map((e, i) => (
                                                    <Fragment key={i}>
                                                        <Col xs={8}>
                                                            <p className="h5 mb-3">{e.mail_tmpl_name}</p>
                                                            <p className="">
                                                                {e.mail_tmpl_content.replaceAll(/<[^>]*>/g, " ").length > contentLength
                                                                    ? e.mail_tmpl_content.replaceAll(/<[^>]*>/g, " ").substr(0, contentLength - 1) + "..."
                                                                    : e.mail_tmpl_content.replaceAll(/<[^>]*>/g, " ")}
                                                            </p>
                                                        </Col>
                                                        <Col xs={4} className="">
                                                            <Row>
                                                                <Col lg={7} md={6} xs={12}>
                                                                    {blockSendingEmails ? (
                                                                        <TooltipWithDisabledButton
                                                                            t={t}
                                                                            mssgTooltip={t("mails.sendingManyEmails")}
                                                                            btnTxt={t("send")}
                                                                            tooltipID="tooltipBlockSendingMails"
                                                                            className="d-inline-block w-100"
                                                                        />
                                                                    ) : (
                                                                        <Button
                                                                            variant="primary"
                                                                            className="d-inline-block w-100"
                                                                            onClick={() => this.setState({ showSendMailToAllUsersModal: true, chosenEmailIndex: i })}
                                                                        >
                                                                            Wyślij
                                                                        </Button>
                                                                    )}
                                                                </Col>
                                                                <Col lg={5} md={6} xs={12}>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="outline-primary" id="dropdownMailActions" className=" w-100">
                                                                            {t("dropdowns.actions")}
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item
                                                                                as="button"
                                                                                onClick={() => {
                                                                                    this.setState({ showEmailDetailsModal: true, chosenEmailIndex: i });
                                                                                }}
                                                                            >
                                                                                {t("mails.tmplPreview")}
                                                                            </Dropdown.Item>

                                                                            <Dropdown.Item
                                                                                as="button"
                                                                                onClick={() => this.setState({ showSendMailToChosenUsersModal: true, chosenEmailIndex: i })}
                                                                                disabled={blockSendingEmails ? true : false}
                                                                            >
                                                                                {t("mails.sendToSelected")}
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {i !== mailsTmplList.length && (
                                                            <Col xs={12}>
                                                                <hr />
                                                            </Col>
                                                        )}
                                                    </Fragment>
                                                ))}
                                            </Fragment>
                                        ) : (
                                            <p className="opacity-4">{t("mails.noTmpls")}</p>
                                        )}
                                    </Row>
                                </Container>
                            </div>
                        )}
                        {showSendMailToChosenUsersModal && (
                            <SendMailToChosenUsers
                                showModal={showSendMailToChosenUsersModal}
                                onCloseModal={() => this.setState({ showSendMailToChosenUsersModal: false, chosenEmailIndex: null })}
                                tmplName={mailsTmplList[chosenEmailIndex].mail_tmpl_name}
                                usersEmails={usersEmails}
                                onSelectEmails={(event, { value }) => {
                                    this.handleSelectEmailsList(event, value);
                                }}
                                onSend={() => this.sendEmailToUsers(this.state.selectedEmails)}
                                t={t}
                                onShowHomePage={onShowHomePage}
                            />
                        )}

                        {showSendMailToAllUsersModal && (
                            <Modal
                                show={showSendMailToAllUsersModal}
                                onHide={() => this.setState({ showSendMailToAllUsersModal: false, chosenEmailIndex: null })}
                                backdrop="static"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className="h5">{t("mails.sendMailToAllUsersModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {t("mails.sendMailToAllUsersModal.text1")}
                                    {mailsTmplList[chosenEmailIndex].mail_tmpl_name}
                                    {t("mails.sendMailToAllUsersModal.text2")}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-secondary" type="button" onClick={() => this.setState({ showSendMailToAllUsersModal: false, chosenEmailIndex: null })}>
                                        {t("mails.sendMailToAllUsersModal.no")}
                                    </Button>
                                    <Button variant="primary" type="button" onClick={this.sendEmailToAllUsers}>
                                        {t("mails.sendMailToAllUsersModal.yes")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}

                        {showEmailDetailsModal && (
                            <Modal
                                show={showEmailDetailsModal}
                                onHide={() => this.setState({ showEmailDetailsModal: false, chosenEmailIndex: null, htmlMailContent: null })}
                                size="lg"
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title className="h5">
                                        {t("mails.mailDetails")} {mailsTmplList[chosenEmailIndex].mail_tmpl_name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div dangerouslySetInnerHTML={{ __html: mailsTmplList[chosenEmailIndex].mail_tmpl_content }} />
                                </Modal.Body>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default MailsList;
