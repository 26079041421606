import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container } from "react-bootstrap";
import RoomsRequestsListTable from "./RoomsRequestsListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import EventFilter from "../../forms/dropdowns/EventFilter";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import RoomRequestStatuses from "../../forms/dropdowns/RoomRequestStatuses";
import RoomsSizeDropdown from "../../forms/dropdowns/RoomsSizeDropdown";
import { faEraser, faLongArrowAltLeft, faLongArrowAltRight, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../other/NoPermission";

class RoomsRequestsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        roomsRequestsList: this.props.dataList,
        originalRooms: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        selectedRoomStat: "all",
        selectedReqStat: "NEW",
        selectedRoomSize: "all",
        noPermission: false,
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalRooms } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredRooms = originalRooms;
            //console.log(filteredRooms)
            filteredRooms = filteredRooms.filter(
                (el) =>
                    (el.room_req_name && el.room_req_name.toString().toLowerCase().includes(searchedPhrase)) ||
                    (el.part_group_name && el.part_group_name.toString().toLowerCase().includes(searchedPhrase))
                //to do - wyszukiwanie po przypisanej grupie
            );
            this.setState({ roomsRequestsList: filteredRooms });
        } else {
            this.setState({ roomsRequestsList: originalRooms });
        }
    };

    handleDownloadData = () => {
        this.props.onResizeWindow("tableNotLoaded");
        this.setState({ loading: true });
        this.getRoomsRequestsList();
    };

    getRoomsRequestsList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/rooms-requests-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const roomsRequestsList = fetchedData.roomsRequestsList;

                        this.setState({ roomsRequestsList: roomsRequestsList, originalRooms: roomsRequestsList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(roomsRequestsList);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        this.getRoomsRequestsList();
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { roomsRequestsList, searchedPhrase, loading, displayNOR, showNOR, selectedReqStat, selectedRoomSize, noPermission } = this.state;
        const { fetchURL, onChangeNavAndPage, currentPage, onShowAlert, activeEvent, t, onShowHomePage, eventFilterValue } = this.props;

        let filteredRoomsRequestsList = null;
        if (roomsRequestsList) {
            filteredRoomsRequestsList = roomsRequestsList.filter(
                (e) =>
                    (eventFilterValue === "all" || eventFilterValue === e.event_code) &&
                    (selectedReqStat === "all" || selectedReqStat === e.room_req_stat_code) &&
                    (selectedRoomSize === "all" || selectedRoomSize === e.room_size_code)
            );
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            roomsRequestsList: this.state.originalRooms,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col xl={1} lg={2} md={2} xs={4}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EventFilter
                                        t={t}
                                        eventFilterValue={this.props.eventFilterValue}
                                        eventsList={this.props.eventsList}
                                        onChangeEventFilter={this.props.onChangeEventFilter}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <RoomRequestStatuses
                                        fetchURL={fetchURL}
                                        onShowAlert={onShowAlert}
                                        onChangeRoomRequestStatusesValue={(value) => {
                                            this.props.onResizeWindow("tableNotLoaded");
                                            this.setState({ selectedReqStat: value });
                                        }}
                                        filter
                                        defaultValue={selectedReqStat}
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <RoomsSizeDropdown
                                        fetchURL={fetchURL}
                                        defaultValue="all"
                                        filter
                                        onChangeRoomsSizeValue={(event, { value }) => {
                                            this.props.onResizeWindow("tableNotLoaded");
                                            this.setState({ selectedRoomSize: value });
                                        }}
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                    />
                                </Col>

                                <Col lg={2} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                {filteredRoomsRequestsList.length > 0 ? (
                                                    <Fragment>
                                                        <RoomsRequestsListTable
                                                            roomsRequestsList={filteredRoomsRequestsList}
                                                            displayNOR={displayNOR}
                                                            fetchURL={fetchURL}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            currentPageParams={currentPageParams}
                                                            onShowAlert={onShowAlert}
                                                            activeEvent={activeEvent}
                                                            onDownloadData={this.handleDownloadData}
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                        />
                                                        <div className="light-border"></div>
                                                    </Fragment>
                                                ) : (
                                                    <div className="text-center">{t("notFound")}</div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            {t("loaded")} {displayNOR > filteredRoomsRequestsList.length ? filteredRoomsRequestsList.length : displayNOR} {t("from")}{" "}
                                            {filteredRoomsRequestsList.length} {t("records")}
                                        </Col>
                                        <Col className="text-center">
                                            {displayNOR >= filteredRoomsRequestsList.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">{t("thatsAll")}</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    {t("loadMore")}
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default RoomsRequestsList;
