import React, { Component, Fragment } from "react";
import { Row, Col, Dropdown, Container, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import NavDetails from "../../../navigation/NavDetails";
import PartDetailsNavbar from "../../../navigation/PartDetailsNavbar";
import $ from "jquery";
import Account from "./Account";
import BasicData from "./BasicData";
//import Accomodation from "./Accomodation";
import FilledForms from "./FilledForms";
import ShopOrders from "./ShopOrders";
import CharactersList from "./CharactersList";
import LoadingSpinnerInner from "../../../spinners/LoadingSpinnerInner";
import LoadingSpinnerSm from "../../../spinners/LoadingSpinnerSm";
import NoPermission from "../../../other/NoPermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faUser } from "@fortawesome/free-solid-svg-icons";
import BarClient from "./BarClient";

class ParticipantDetails extends Component {
    state = {
        partData: null,
        loading: true,
        noPermission: false,
        avatar: null,
    };

    abortParticipant = async (partID) => {
        const { fetchURL, onChangeNavAndPage, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.deletingPart") });
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/abort-participant`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: `${t("participant.partDeletedSuccessfully")}${this.state.partData[0].user_fullname}.`, fading: 2000 });
                        onChangeNavAndPage({ nav: "participants", subpage: "participantsList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleAccrPaymentReminder = async () => {
        const { fetchURL, onShowAlert, t, activeEvent, currentPage } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingMessage") });
        const passData = {
            userID: currentPage.params.userID,
            activeEvent: activeEvent,
        };

        try {
            const response = await fetch(`${fetchURL}/send-accr-payment-reminder`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "warning") onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`), fading: false });
                    else onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getBasicData = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };
        try {
            const response = await fetch(`${fetchURL}/participant-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ partData: fetchedData.participantData, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    readAvatar = async () => {
        const { fetchURL, onShowAlert, t, currentPage } = this.props;
        const passData = { userID: currentPage.params.userID };
        try {
            const response = await fetch(`${fetchURL}/read-avatar`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else if (fetchedData.status && fetchedData.fileData === "noFile") {
                        this.setState({ avatar: fetchedData.fileData, userLogged: true }, () => this.getBasicData(currentPage.params.partID));
                    } else {
                        const blob = fetchedData.fileData.blob_data;
                        var buffer = Buffer.from(blob);
                        var bufferBase64 = buffer.toString("base64");
                        this.setState({ avatar: { data: bufferBase64, type: fetchedData.fileData.blob_file_type, name: fetchedData.fileData.blob_name }, userLogged: true }, () => {
                            this.getBasicData(currentPage.params.partID);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})`, fading: false });
        }
    };

    componentDidMount() {
        this.props.onResizeWindow("basic");
        this.readAvatar();
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    handlePaymentReminder = async (paymentType) => {
        const { fetchURL, onShowAlert, t, currentPage } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingMessage") });
        const passData = {
            userID: currentPage.params.userID,
            partID: currentPage.params.partID,
            paymentType: paymentType,
        };
        try {
            const response = await fetch(`${fetchURL}/send-payment-reminder`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "warning") onShowAlert({ variant: "warning", message: t(`warning.${fetchedData.code}`), fading: false });
                    else onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    render() {
        const { partData, loading, noPermission, avatar } = this.state;
        const { onCheckUserPrivilage, currentPage, fetchURL, onChangeNavAndPage, onBackToPreviousPage, onShowAlert, t, onShowHomePage, activeEvent } = this.props;

        const partID = currentPage.params.partID;
        const userID = currentPage.params.userID;

        const currentPageParams = { nav: "participants", subpage: "participantDetails", params: { partID: partID, userID: userID } };
        console.log(partID, userID, partData, !loading);
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details part-details" id="navDetails">
                            <Row>
                                <NavDetails t={t} header={t("participant.partDetails")} onChangeView={onBackToPreviousPage} />
                                <Col>
                                    {!onCheckUserPrivilage || !partData ? (
                                        <div className="float-right">
                                            <LoadingSpinnerSm />
                                        </div>
                                    ) : onCheckUserPrivilage("ACCR_PART_ACTIONS") ? (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-primary" id="dropdownParticipantActions" className="float-right mt-1 d-inline-block">
                                                {t("dropdowns.actions")}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    as="button"
                                                    onClick={() =>
                                                        onChangeNavAndPage(
                                                            {
                                                                nav: "participants",
                                                                subpage: "postPayment",
                                                                params: { partID: partID, partName: partData[0].user_fullname, partIdentNumber: partData[0].part_ident_number },
                                                            },
                                                            currentPageParams
                                                        )
                                                    }
                                                >
                                                    {t("payment.registerTransaction")}
                                                </Dropdown.Item>
                                                {partData[0].part_stat_code === "ABSENT" && activeEvent && activeEvent !== "noActiveEvent" ? (
                                                    <Dropdown.Item
                                                        id="accreditParticipantBtn"
                                                        as="button"
                                                        onClick={() => onChangeNavAndPage({ nav: "participants", subpage: "accreditParticipant", params: { partID: partID } }, currentPageParams)}
                                                    >
                                                        {t("participant.accreditPart")}
                                                    </Dropdown.Item>
                                                ) : (
                                                    <OverlayTrigger overlay={<Tooltip id="accreditParticipantBtnDisabled">{t("payment.accreditParticipantDisabled")}</Tooltip>}>
                                                        <span className="d-block">
                                                            <Dropdown.Item as="button" disabled>
                                                                {t("participant.accreditPart")}
                                                            </Dropdown.Item>
                                                        </span>
                                                    </OverlayTrigger>
                                                )}
                                                {partData[0].payment_amount_all !== null ? (
                                                    <Dropdown.Item
                                                        as="button"
                                                        onClick={() =>
                                                            onChangeNavAndPage({ nav: "participants", subpage: "paymentsList", params: { partID: partID, userID: userID } }, currentPageParams)
                                                        }
                                                        id="displayTransactionsBtn"
                                                    >
                                                        {t("payment.displayTransactions")}
                                                    </Dropdown.Item>
                                                ) : (
                                                    <OverlayTrigger overlay={<Tooltip id="displayTransactionsBtnDisabled">{t("payment.displayTransactionsDisabled")}</Tooltip>}>
                                                        <span className="d-block">
                                                            <Dropdown.Item as="button" disabled>
                                                                {t("payment.displayTransactions")}
                                                            </Dropdown.Item>
                                                        </span>
                                                    </OverlayTrigger>
                                                )}

                                                {partData[0].payment_amount_accr !== null && partData[0].part_verified_amount !== 0 && (
                                                    <Dropdown.Item as="button" disabled={partData[0].payment_amount_accr !== null ? true : false} onClick={this.handleAccrPaymentReminder}>
                                                        {t("payment.sendReminder")}
                                                    </Dropdown.Item>
                                                )}
                                                <hr />
                                                <Dropdown.Item
                                                    as="button"
                                                    onClick={() => {
                                                        onShowAlert("");
                                                        this.setState({ loading: true });
                                                        this.getBasicData(partID);
                                                    }}
                                                >
                                                    {t("dropdowns.refreshData")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => {
                                                onShowAlert("");
                                                this.setState({ loading: true });
                                                this.getBasicData(partID);
                                            }}
                                            className="btn-sync float-right mt-1"
                                        >
                                            <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <PartDetailsNavbar
                                        t={t}
                                        scrollToDetail={(id) => {
                                            document.getElementById(id).scrollIntoView();
                                        }}
                                        onCheckUserPrivilage={onCheckUserPrivilage}
                                    />
                                </Col>
                            </Row>
                        </Container>

                        {partID && userID && partData && !loading ? (
                            <div className="scrolled-content" style={{ display: "none" }} id="scrollContainer">
                                <Container className="py-3">
                                    <Row>
                                        <Col xs={12} className="text-center">
                                            {avatar && avatar !== "noFile" ? (
                                                <div className="mx-auto d-block">
                                                    <img src={`data:${avatar.type};base64,${avatar.data}`} width="200" alt={avatar.name} className="rounded-circle" />
                                                </div>
                                            ) : (
                                                <div className="avatar mx-auto d-block border rounded-circle" style={{ width: "200px", height: "200px" }}>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </div>
                                            )}
                                        </Col>
                                        <Col xs={12}>
                                            <p className="title-details">
                                                {partData[0].user_fullname}
                                                {partData[0].part_ident_number ? ` (${partData[0].part_ident_number})` : ""}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row id="participationPartDetail">
                                        <BasicData
                                            partID={partID}
                                            userID={userID}
                                            partData={partData}
                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                            fetchURL={fetchURL}
                                            onChangeNavAndPage={onChangeNavAndPage}
                                            onAccrPaymentReminder={this.handleAccrPaymentReminder}
                                            onPaymentReminder={this.handlePaymentReminder}
                                            onShowAlert={onShowAlert}
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                            currentPageParams={currentPageParams}
                                            activeEvent={activeEvent}
                                        />
                                    </Row>
                                    <Row id="userPartDetail">
                                        <Account
                                            userID={userID}
                                            partID={partID}
                                            fetchURL={fetchURL}
                                            onChangeNavAndPage={onChangeNavAndPage}
                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                            onShowAlert={onShowAlert}
                                            currentPageParams={currentPageParams}
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                        />
                                    </Row>
                                    {/* Wyłączone z użycia */}
                                    {/*  <Row id="roomsPartDetail">
                                        <Accomodation
                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                            onShowAlert={onShowAlert}
                                            partID={partID}
                                            fetchURL={fetchURL}
                                            onChangeNavAndPage={onChangeNavAndPage}
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                            partData={partData}
                                            currentPageParams={currentPageParams}
                                        />
                                    </Row> */}
                                    <Row id="shopPartDetail">
                                        <ShopOrders
                                            partID={partID}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                            onChangeNavAndPage={onChangeNavAndPage}
                                            currentPageParams={currentPageParams}
                                        />
                                    </Row>
                                    {onCheckUserPrivilage("ACCR_APPLICATIONS_VIEW") && (
                                        <Row id="formsPartDetail">
                                            <FilledForms
                                                onCheckUserPrivilage={onCheckUserPrivilage}
                                                onShowAlert={onShowAlert}
                                                t={t}
                                                onShowHomePage={onShowHomePage}
                                                fetchURL={fetchURL}
                                                partID={partID}
                                            />
                                        </Row>
                                    )}

                                    <Row id="characterPartDetail">
                                        <CharactersList
                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                            onShowAlert={onShowAlert}
                                            t={t}
                                            onShowHomePage={onShowHomePage}
                                            fetchURL={fetchURL}
                                            partID={partID}
                                        />
                                    </Row>
                                    <Row id="barClient">
                                        <BarClient
                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                            onShowAlert={onShowAlert}
                                            t={t}
                                            userID={userID}
                                            onShowHomePage={onShowHomePage}
                                            fetchURL={fetchURL}
                                            partID={partID}
                                            onChangeNavAndPage={onChangeNavAndPage}
                                            currentPageParams={currentPageParams}
                                        />
                                    </Row>
                                </Container>
                            </div>
                        ) : (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 75 }}>
                                <LoadingSpinnerInner />
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ParticipantDetails;
