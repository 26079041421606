import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class BuildingsDropdown extends Component {
    state = { buildingsList: null, noPermission: false };

    getBuildingsList = async () => {
        const { fetchURL, filter, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/buildings-list`, {
                credentials: "include",
                method: "POST",
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: 2400 });
                    else {
                        const buildings = fetchedData.buildingsList;
                        if (filter) {
                            buildings.unshift({
                                key: buildings.length + 1,
                                value: "all",
                                text: t("selectAll"),
                            });
                        }
                        this.setState({ buildingsList: buildings });
                    }
                });
            } else {
                //console.log(response.status);
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getBuildingsList();
    }

    render() {
        const { buildingsList, noPermission } = this.state;
        const { onChangeBuildingValue, defaultValue, req, id, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {t("building.building")}: {req && <span className="required">*</span>}
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        {buildingsList ? (
                            <Dropdown
                                className="req mb-3"
                                placeholder={t("form.choose")}
                                fluid
                                selection
                                defaultValue={defaultValue}
                                options={buildingsList}
                                onChange={onChangeBuildingValue}
                                id={id}
                            />
                        ) : (
                            <LoadingProgressBar t={t} />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default BuildingsDropdown;
