import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import ChangeCharacterStatusModal from "../../modals/ChangeCharacterStatusModal";
import CharacterDetailsModal from "../../modals/CharacterDetailsModal";

class TableCharactersApplicationsList extends Component {
    state = {
        showModal: null,
    };

    render() {
        const { showModal } = this.state;
        const { charsList, numOfRecords, t, onShowAlert, fetchURL, onCheckUserPrivilage, refreshData, onShowHomePage } = this.props;

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>{t("on")}</th>
                            <th>{t("participant.partIdent")}</th>
                            <th>{t("characters.characterName")}</th>
                            <th>{t("characters.characterFraction")}</th>
                            <th>{t("characters.participant")}</th>
                            <th>{t("characters.isContinued")}</th>
                            <th>{t("characters.status")}</th>
                            <th>{t("characters.eventName")}</th>
                            <th>{t("characters.createDateApp")}</th>
                            <th>{t("dropdowns.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {charsList
                            .map((p, i) => (
                                <tr key={`characters_data_${i}`}>
                                    <td>{i + 1}</td>
                                    <td>{p.part_ident_number}</td>
                                    <td>{p.char_name}</td>
                                    <td>{p.char_fraction ? p.char_fraction : <span className="opacity-4">{t("lack")}</span>}</td>

                                    <td>{p.participant}</td>
                                    <td>{p.char_is_continued === "Y" ? t("yes") : t("no")}</td>
                                    <td>
                                        <span
                                            className={
                                                p.stat_code === "ACCEPTED"
                                                    ? "text-success"
                                                    : p.stat_code === "REJECTED"
                                                    ? "text-danger"
                                                    : p.stat_code === "CANCELED"
                                                    ? "opacity-4"
                                                    : "text-primary"
                                            }
                                        >
                                            {p.stat_name}
                                        </span>
                                    </td>
                                    <td>{p.event_name}</td>
                                    <td>{p.create_date}</td>
                                    <td className="text-center">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`charDetailsTooltip${i}`}>{t("characters.charDetails")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => this.setState({ showModal: { modalName: "charDetails", charID: p.char_id, eventCode: p.event_code } })}
                                                    className="mx-1"
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                        {onCheckUserPrivilage("ACCR_LARP_APPL_ACTIONS") && p.stat_code !== "REJECTED" && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`changeCharStatusTooltip${i}`}>{t("characters.changeCharStatus")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            this.setState({
                                                                showModal: { modalName: "changeCharStatus", charID: p.char_id, charStatus: p.stat_code, partID: p.part_id },
                                                            })
                                                        }
                                                        className="mx-1"
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, numOfRecords)}
                    </tbody>
                </Table>

                {showModal && (
                    <Fragment>
                        {showModal.modalName === "charDetails" && (
                            <CharacterDetailsModal
                                t={t}
                                onShowAlert={onShowAlert}
                                fetchURL={fetchURL}
                                charID={showModal.charID}
                                eventCode={showModal.eventCode}
                                show={true}
                                onClose={() => this.setState({ showModal: null })}
                            />
                        )}
                        {showModal.modalName === "changeCharStatus" && (
                            <ChangeCharacterStatusModal
                                t={t}
                                onShowAlert={onShowAlert}
                                fetchURL={fetchURL}
                                charID={showModal.charID}
                                show={true}
                                onClose={() => this.setState({ showModal: null })}
                                charStatus={showModal.charStatus}
                                onCloseAndRefresh={() => this.setState({ showModal: null }, () => refreshData())}
                                onShowHomePage={onShowHomePage}
                                partID={showModal.partID}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default TableCharactersApplicationsList;
