import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import $ from "jquery";

class PaymentTypeMethodsDropdown extends Component {
    state = { loading: true, noPermission: false, paymentMethods: null };

    getPaymentTypes = async () => {
        const { fetchURL, onShowAlert, activeEvent, defaultValue, t } = this.props;
        const passData = { activeEvent: activeEvent, selectedParts: defaultValue ? defaultValue : null };
        try {
            const response = await fetch(`${fetchURL}/payment-type-methods`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const paymentTypes = fetchedData.list;
                        let paymentMethods = [];
                        paymentTypes.forEach((e, i) => {
                            paymentMethods.push({
                                key: i + 1,
                                value: e.payment_type_method,
                                text: t(`payment.methods.${e.payment_type_method}`),
                            });
                        });
                        paymentMethods.unshift({ key: 0, value: "all", text: t("selectAll") });

                        this.setState({ paymentMethods: paymentMethods, loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getPaymentTypes();
    }

    render() {
        const { noPermission, loading, paymentMethods } = this.state;
        const { t, selectedPaymentMethod, onSelectPaymentMethod } = this.props;
        return (
            <Fragment>
                <Form.Label className="w-100">{t("payment.list.method")}</Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : paymentMethods && !loading ? (
                    <Dropdown
                        value={selectedPaymentMethod}
                        id="paymentTypeMethods"
                        className="req mb-3"
                        placeholder={t("select")}
                        fluid
                        selection
                        options={paymentMethods}
                        onChange={(event, { value }) => {
                            $("#paymentTypeMethods").removeClass("danger");
                            onSelectPaymentMethod(value);
                        }}
                    />
                ) : (
                    <LoadingProgressBar t={t} />
                )}
            </Fragment>
        );
    }
}

export default PaymentTypeMethodsDropdown;
