import React, { Component, Fragment } from "react";
import { Modal, Form, Button, Alert, Row, Col } from "react-bootstrap";
import TextForm from "../forms/text-inputs/TextForm";
import TextareaForm from "../forms/TextareaForm";

class ContactWithAdminModal extends Component {
    state = {
        mail_txt: "",
    };

    sendMail = async () => {
        const { fetchURL, contactAdminData, onShowAlert, t, onClose } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingEmail") });
        const passData = {
            mailSender: contactAdminData.mailAddress,
            mailTitle: contactAdminData.mailTitle,
            mailSenderFullname: contactAdminData.mailSender,
            mailTxt: this.state.mail_txt,
        };
        try {
            const response = await fetch(`${fetchURL}/send-email`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        onClose();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.props.onShowAlert("");
    }

    render() {
        const { show, onClose, contactAdminData, t } = this.props;
        const { mail_txt, alert } = this.state;

        return (
            <Fragment>
                <Modal show={show} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("contactWithAdminModal.title")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
                        {((alert && (alert.variant === "info" || alert.variant === "danger")) || !alert) && (
                            <Form onSubmit={this.handleSendMail} autoComplete="off">
                                <Row>
                                    <Col xs={12}>
                                        <TextForm value={contactAdminData.mailTitle} readOnly labelTxt={t("contactWithAdminModal.titleLabel")} name="mail_title" id="mailTitle" />
                                    </Col>
                                    <Col xs={12}>
                                        <TextareaForm
                                            value={mail_txt}
                                            onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                            labelTxt={t("contactWithAdminModal.mssgLabel")}
                                            name="mail_txt"
                                            req
                                            placeholderTxt={t("form.typeMessage")}
                                            id="mail_txt"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {(alert && (alert.variant === "info" || alert.variant === "danger")) || !alert ? (
                            <Fragment>
                                <Button variant="outline-secondary" onClick={onClose} type="button">
                                    {t("cancel")}
                                </Button>
                                <Button variant="primary" type="button" onClick={() => this.sendMail()}>
                                    {t("mails.sendMail")}
                                </Button>
                            </Fragment>
                        ) : (
                            <Button variant="outline-primary" onClick={onClose} type="button">
                                {t("close")}
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default ContactWithAdminModal;
