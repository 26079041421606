import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

class BarBalanceListTable extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
        showModal: null,
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    sendReminder = async (userID, eventCode) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t(`info.sendingEmail`) });
        const passData = {
            userID: userID,
            eventCode: eventCode,
        };
        try {
            const response = await fetch(`${fetchURL}/send-reminder-for-bar-balance`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        onShowAlert({ variant: "success", message: t(`info.messageSentSuccessfully`), fading: 3000 });
                        this.setState({ showModal: null });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    render() {
        const { currentSortType, currentSortingColumn, showModal } = this.state;
        const { barBalanceList, displayNOR, t, onChangeNavAndPage } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.bar_client_id - b.bar_client_id, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )
                fnClientBalance: (a, b) => {
                    if (a.client_balance > b.client_balance) return 1;
                    else return -1;
                },
                fnPart: (a, b) => {
                    if (a.user_fullname.toUpperCase() > b.user_fullname.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.bar_client_id - a.bar_client_id,
                fnClientBalance: (a, b) => {
                    if (a.client_balance > b.client_balance) return -1;
                    else return 1;
                },
                fnPart: (a, b) => {
                    if (a.user_fullname.toUpperCase() > b.user_fullname.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnClientBalance: (a, b) => a,
                fnPart: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (currentSortingColumn === "sortID") sortedData = barBalanceList.sort(sortTypes[currentSortType].fnID);
        else if (currentSortingColumn === "sortClientBalance") sortedData = barBalanceList.sort(sortTypes[currentSortType].fnClientBalance);
        else if (currentSortingColumn === "sortPart") sortedData = barBalanceList.sort(sortTypes[currentSortType].fnPart);
        else sortedData = barBalanceList.sort(sortTypes[currentSortType].fnID);

        const currentPageParams = { nav: "transactions", subpage: "barBalanceList", params: "" };
        console.log(sortedData);
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "45px" }}>{t("on")}</th>
                            <th>
                                {t("barClients.participant")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortPart" onClick={() => this.handleSorting("sortPart")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortPart" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>{t("barClients.barClientType")}</th>
                            <th>{t("barClients.barClientStatus")}</th>
                            <th>{t("barClients.barClientLimit")}</th>
                            <th>{t("barClients.barClientFree")}</th>
                            <th>{t("barClients.ordersSum")}</th>
                            <th>{t("barClients.transactionResult")}</th>
                            <th>
                                {t("barClients.clientBalance")}
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortClientBalance" onClick={() => this.handleSorting("sortClientBalance")}>
                                    <FontAwesomeIcon
                                        icon={currentSortingColumn !== "sortClientBalance" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort}
                                    />
                                </Button>
                            </th>
                            <th>{t("dropdowns.actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <Button
                                            onClick={() =>
                                                onChangeNavAndPage({ nav: "participants", subpage: "participantDetails", params: { partID: p.part_id, userID: p.user_id } }, currentPageParams)
                                            }
                                            variant="link"
                                            className="text-left"
                                        >
                                            {p.user_fullname}
                                        </Button>
                                    </td>
                                    <td>{t(`barClients.types.${p.client_type}`)}</td>
                                    <td>
                                        <span className={p.client_status === "BANNED" ? "text-danger" : p.client_status === "INACTIVE" ? "text-info" : "text-primary"}>
                                            {t(`barClients.statuses.${p.client_status}`)}
                                        </span>
                                    </td>
                                    <td>{p.amount_limit ? `${p.amount_limit} ${t("currency")}` : <span className="opacity-4">{t("barClients.noLimit")}</span>}</td>
                                    <td>{p.amount_free || p.amount_free === 0 ? `${p.amount_free} ${t("currency")}` : <span className="opacity-4">{t("lack")}</span>}</td>
                                    <td>
                                        {p.sum_orders_value} {t("currency")}
                                    </td>
                                    <td>
                                        {p.sum_bar_payments ? (
                                            <span>
                                                {p.sum_bar_payments} {t("currency")}
                                            </span>
                                        ) : (
                                            <span className="opacity-4">{t("payment.noPayments")}</span>
                                        )}
                                    </td>
                                    <td>
                                        <span className={p.client_balance < 0 ? "text-danger" : p.client_balance === 0 ? "" : "text-primary"}>
                                            {p.client_balance} {t("currency")}
                                        </span>
                                    </td>
                                    <td>
                                        {p.client_balance < 0 && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`barClientBalance${i}`}>{t("barClients.sendReminder")}</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        className="mx-1"
                                                        onClick={() =>
                                                            this.setState({ showModal: { option: "sendReminder", eventCode: p.event_code, userID: p.user_id, userFullname: p.user_fullname, amount: -1 * p.client_balance } })
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faPaperPlane} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>

                {showModal && (
                    <Fragment>
                        {showModal.option === "sendReminder" && (
                            <Modal show={true} onHide={() => this.setState({ showModal: null })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("barClients.sendReminderModal.title")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-3">{t("barClients.sendReminderModal.text")} <b>{showModal.userFullname}</b>?</p>
                                    <p>{t("barClients.sendReminderModal.text2")}: {showModal.amount} {t('currency')}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-secondary" onClick={() => this.setState({ showModal: null })}>
                                        {t("cancel")}
                                    </Button>
                                    <Button variant="primary" type="button" onClick={() => this.sendReminder(showModal.userID, showModal.eventCode)}>
                                        {t("yes")}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default BarBalanceListTable;
