import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class UserStatuses extends Component {
    state = {
        statusesList: null,
        noPermission: false,
    };

    getStatusesList = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/user-statuses-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const userStatuses = fetchedData.userStatuses;
                        if (this.props.defaultValue) userStatuses.unshift({ key: 0, value: "all", text: t("selectAll") });

                        this.setState({ statusesList: userStatuses });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getStatusesList();
    }

    render() {
        const { statusesList, noPermission } = this.state;
        const { userDetails, req, onChangeStatus, value, t } = this.props;

        return (
            <Fragment>
                <Form.Label>
                    {t("user.userStatus")} {req && <span className="required">*</span>}
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        {!statusesList ? (
                            <LoadingProgressBar t={t} />
                        ) : (
                            <Fragment>
                                <Dropdown
                                    id="userStatus"
                                    className={req ? "mb-3 req" : "mb-3"}
                                    placeholder={t("form.choose")}
                                    fluid
                                    options={statusesList}
                                    defaultValue={userDetails ? userDetails.map((u) => u.user_stat_code).toString() : value}
                                    selection
                                    onChange={onChangeStatus}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default UserStatuses;
