import React, { Fragment, Component } from "react";
import NotLoggedPages from "./NotLoggedPages";
import LoggedPages from "./LoggedPages";
import AlertBasic from "../alerts/AlertBasic";
import $ from "jquery";
import ContactWithAdminModal from "../modals/ContactWithAdminModal";
import LoadingSpinner from "../spinners/LoadingSpinner";
import AlertUsingCookies from "../alerts/AlertUsingCookies";
import SessionAlert from "../alerts/SessionAlert";

var interval;

class Main extends Component {
    state = {
        alert: "",
        loggedUserEmail: null,
        loggedUserPrivilages: [],
        resetPassError: "",
        loading: true,
        loggedIn: false,
        cookieExpDate: null,
        scannerHeight: $(window).height(),
        sendOnThisEmail: "",
        showContactModal: false,
        contactFormData: null,
        sessionAlert: null,
        showCookieAlert: false,
    };

    handleShowAlert = (alert) => {
        this.setState({ alert: alert });
    };

    //odliczanie czasu do wygaśnięcia sesji i wyświetlanie alertu
    startSession = () => {
        console.log("start session");
        const { cookieExpDate } = this.state;
        const { t } = this.props;
        const loggedTime = Date.now();
        const endSessTime = cookieExpDate; //czas w ms kiedy ma zakończyć się sesja
        console.log(endSessTime);
        //jeśli została strona odświeżona w trakcie trwania sesji a także gdy do końca trwania sesji pozostało mniej niż 10 sekund -> wyświetl komunikat

        if (endSessTime - loggedTime <= 10000)
            if (!this.state.sessionAlert) {
                const secLeft = Math.round((endSessTime - loggedTime) / 1000);
                this.setState({
                    sessionAlert: {
                        message: `${t("session.in")} ${secLeft} ${secLeft <= 4 && secLeft >= 2 ? t("session.seconds2") : secLeft === 1 ? t("session.seconds1") : t("session.secondsMore")} ${t(
                            "session.sessionWillExpire"
                        )}`,
                        dismissible: false,
                    },
                });
            }

        interval = setInterval(() => {
            const now = Date.now();
            const leftTime = endSessTime - now;
            let secLeft = Math.round(leftTime / 1000);
            if (leftTime <= 0) {
                //sesja wygasła
                console.log("session out");
                this.setState({ loggedIn: false, alert: "", sessionAlert: { message: t("session.sessionExpired"), dismissible: true } });
                $("#showNotLoggedPages").fadeIn(700);
                clearInterval(interval);
            } else if (leftTime <= 10000)
                this.setState({
                    sessionAlert: {
                        message:
                            leftTime > 1000
                                ? `${t("session.in")} ${secLeft} ${secLeft <= 4 && secLeft >= 2 ? t("session.seconds2") : secLeft === 1 ? t("session.seconds1") : t("session.secondsMore")} ${t(
                                      "session.sessionWillExpire"
                                  )}`
                                : t("session.loggingOut"),
                        dismissible: false,
                    },
                });
            else if (leftTime <= 120000 && leftTime > 10000) {
                //sesja jeszcze trwa, ale zostało więcej niż 10 sekund
                this.setState({
                    sessionAlert: {
                        message: leftTime < 60000 ? t("session.lessThanMinute") : t("session.moreThanMinute"),
                        dismissible: false,
                    },
                });
            }
        }, 1000);
    };

    handleLogout = async () => {
        const { fetchURL, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/logout`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false) this.handleShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: 2000 });
                    else {
                        this.setState({ loggedIn: false, sessionAlert: null });
                        this.handleShowAlert({ variant: "success", message: t(`success.logout`), fading: 2000 });
                        $("#showNotLoggedPages").fadeIn(700);
                        clearInterval(interval);
                    }
                });
            }
        } catch (err) {
            this.setState({ loading: false });
            this.handleShowAlert({ variant: "danger", message: t("error.loggingOutFailed") + err });
        }
    };

    handleShowHomePage = () => {
        const { t } = this.props;
        this.setState({ loggedIn: false });
        this.handleShowAlert({ variant: "info", message: t(`session.sessionExpired`) });
        $("#showNotLoggedPages").fadeIn(700);
        clearInterval(interval);
    };

    resetCookieAlertText = async () => {
        const { fetchURL, t } = this.props;
        try {
            await fetch(`${fetchURL}/reset-cookie-alert-text`, {
                credentials: "include",
            });
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    fetchMainData = async () => {
        const { fetchURL, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/`, {
                credentials: "include", //include - bez tego nie będzie można odczytać wartości ciasteczek w controllerze (ciasteczka będą undefined)
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") {
                        this.setState({ disallowPermission: true, loading: false, loggedIn: false });
                    } else if (fetchedData.status && fetchedData.loginSuccess) {
                        this.setState(
                            {
                                loggedIn: true,
                                loggedUserPrivilages: fetchedData.loggedUserPrivilages,
                                cookieExpDate: fetchedData.cookieExpDate,
                            },
                            () => {
                                if (this.state.loggedIn && this.state.cookieExpDate) {
                                    //jeżeli nastąpiło zalogowanie oraz na serwerze jest ustawiony czas maksymalny trwania sesji
                                    this.startSession();
                                }
                                this.setState({ loading: false });
                            }
                        );
                    } else if (fetchedData.status && !fetchedData.loginSuccess) {
                        this.setState({ loggedIn: false, loading: false });
                    } else {
                        this.setState({ alert: { variant: "danger", message: `${t("error.cannotFetchData")}` } });
                    }
                });
            }
        } catch (err) {
            this.setState({ loading: false });
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handlePassLoggedUserData = (userPrivilages, expDate) => {
        console.log("handlePassLoggedUserData");
        this.setState({ loggedIn: true, loggedUserPrivilages: userPrivilages, cookieExpDate: expDate, sessionAlert: null }, () => {
            this.handleShowAlert("");
            if (this.state.loggedIn && this.state.cookieExpDate) {
                //jeżeli nastąpiło zalogowanie oraz na serwerze jest ustawiony czas maksymalny trwania sesji
                this.startSession();
            }
        });
    };

    handleCheckUserPrivilage = (priv) => {
        const { loggedUserPrivilages } = this.state;
        for (let i = 0; i < loggedUserPrivilages.length; i++) if (loggedUserPrivilages[i] === priv) return true;
        return false;
    };

    componentDidMount() {
        //pobranie danych z serwera
        this.fetchMainData();

        if (!this.state.loggedIn) $("#showNotLoggedPages").fadeIn(700);
        else $("#showNotLoggedPages").fadeOut();

        if (!localStorage.getItem("confirmCookies")) this.setState({ showAlertCookie: true });
    }

    render() {
        const { resetPassError, loading, loggedIn, loggedUserPrivilages, scannerHeight, sendOnThisEmail, alert, showContactModal, contactFormData, showCookieAlert, sessionAlert } = this.state;
        const { fetchURL, moduleName, t } = this.props;
        return (
            <Fragment>
                {alert && (
                    <AlertBasic
                        className="alert-basic"
                        variant={alert.variant}
                        onCloseAlert={() => this.setState({ alert: "" })}
                        dismissible
                        mssg={alert.message}
                        contactBtn={alert.contactBtn}
                        fading={alert.fading}
                        t={t}
                        onShowContactModal={() => this.setState({ showContactModal: true, contactFormData: alert.contactBtn })}
                    />
                )}
                {loading && <LoadingSpinner />}
                <Fragment>
                    {loggedIn ? (
                        <Fragment>
                            <LoggedPages
                                fetchURL={fetchURL}
                                loggedUserPrivilages={loggedUserPrivilages}
                                onLogout={this.handleLogout}
                                scannerHeight={scannerHeight}
                                onShowAlert={this.handleShowAlert}
                                moduleName={moduleName}
                                onCheckUserPrivilage={this.handleCheckUserPrivilage}
                                t={t}
                                onShowHomePage={this.handleShowHomePage}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div id="showNotLoggedPages" style={{ display: "none" }}>
                                <NotLoggedPages
                                    fetchURL={fetchURL}
                                    sendOnThisEmail={sendOnThisEmail}
                                    scannerHeight={scannerHeight}
                                    resetPassError={resetPassError}
                                    onShowAlert={this.handleShowAlert}
                                    onPassLoggedUserData={this.handlePassLoggedUserData}
                                    moduleName={moduleName}
                                    t={t}
                                />
                            </div>
                        </Fragment>
                    )}
                </Fragment>

                {showContactModal && (
                    <ContactWithAdminModal
                        t={t}
                        contactAdminData={contactFormData}
                        show={showContactModal}
                        onClose={() => this.setState({ showContactModal: false, contactFormData: null })}
                        fetchURL={fetchURL}
                        onShowAlert={this.handleShowAlert}
                    />
                )}
                {sessionAlert && <SessionAlert sessionAlert={sessionAlert} onCloseAlert={() => this.setState({ sessionAlert: null })} />}
                {showCookieAlert && (
                    <AlertUsingCookies
                        onAcceptCookies={() => {
                            this.setState({ showCookieAlert: false });
                            localStorage.setItem("confirmCookies", true);
                        }}
                        t={t}
                    />
                )}
            </Fragment>
        );
    }
}

export default Main;
