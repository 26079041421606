import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container, Form, InputGroup } from "react-bootstrap";
import RoomBookingsListTable from "./RoomBookingsListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import EventFilter from "../../forms/dropdowns/EventFilter";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import { faLongArrowAltLeft, faLongArrowAltRight, faSync, faEraser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../other/NoPermission";

class RoomsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        roomBookingsList: this.props.dataList,
        originalRoomBookings: this.props.dataList,
        loading: this.props.dataList ? false : true,
        selectedEvent: this.props.activeEvent && this.props.activeEvent !== "noActiveEvent" ? this.props.activeEvent.code : "all",
        noPermission: false,
        searchedPhrase: "",
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalRoomBookings } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredRooms = originalRoomBookings;
            //console.log(filteredRooms)
            filteredRooms = filteredRooms.filter(
                (el) =>
                    (el.room_code && el.room_code.toString().toLowerCase().includes(searchedPhrase)) ||
                    (el.room_keeper && el.room_keeper.toString().toLowerCase().includes(searchedPhrase)) ||
                    (el.create_user && el.create_user.toString().toLowerCase().includes(searchedPhrase)) ||
                    (el.part_group_name && el.part_group_name.toString().toLowerCase().includes(searchedPhrase)) ||
                    (el.room_name && el.room_name.toString().toLowerCase().includes(searchedPhrase))
            );
            this.setState({ roomBookingsList: filteredRooms });
        } else {
            this.setState({ roomBookingsList: originalRoomBookings });
        }
    };

    handleDownloadData = () => {
        this.props.onResizeWindow("tableNotLoaded");
        this.setState({ loading: true });
        this.getRoomsList();
    };

    getRoomsList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/room-bookings-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const roomBookingsList = fetchedData.roomBookingsList;

                        this.setState({ roomBookingsList: roomBookingsList, originalRoomBookings: roomBookingsList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(roomBookingsList);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        this.getRoomsList();
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { roomBookingsList, loading, displayNOR, showNOR, selectedEvent, noPermission, searchedPhrase } = this.state;
        const { fetchURL, onCheckUserPrivilage, onChangeNavAndPage, currentPage, onShowAlert, activeEvent, t } = this.props;
        let filteredRoomsList = null;
        if (roomBookingsList) {
            filteredRoomsList = roomBookingsList.filter((e) => selectedEvent === "all" || e.event_code === selectedEvent);
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            roomBookingsList: this.state.originalRoomBookings,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col xl={1} lg={2} md={2} xs={4}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EventFilter
                                        t={t}
                                        eventFilterValue={this.props.eventFilterValue}
                                        eventsList={this.props.eventsList}
                                        onChangeEventFilter={this.props.onChangeEventFilter}
                                    />
                                </Col>

                                <Col lg={{ span: 1, offset: 5 }} xl={{ span: 1, offset: 5 }} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                {filteredRoomsList.length > 0 ? (
                                                    <Fragment>
                                                        <RoomBookingsListTable
                                                            roomBookingsList={filteredRoomsList}
                                                            displayNOR={displayNOR}
                                                            fetchURL={fetchURL}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            currentPageParams={currentPageParams}
                                                            onShowAlert={onShowAlert}
                                                            activeEvent={activeEvent}
                                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                                            t={t}
                                                        />
                                                        <div className="light-border"></div>
                                                    </Fragment>
                                                ) : (
                                                    <div className="text-center">{t("notFound")}</div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            {t("loaded")} {displayNOR > filteredRoomsList.length ? filteredRoomsList.length : displayNOR} {t("from")} {filteredRoomsList.length}{" "}
                                            {t("records")}
                                        </Col>
                                        <Col className="text-center">
                                            {displayNOR >= filteredRoomsList.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">{t("thatsAll")}</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    {t("loadMore")}
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default RoomsList;
