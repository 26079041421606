import React, { Fragment, Component } from "react";
import queryString from "query-string";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoAndTitle from "../../other/LogoAndTitle";
import ContactToAdmin from "../../other/ContactToAdmin";
import ContactWithAdminModal from "../../modals/ContactWithAdminModal";
import Password from "../../forms/Password";

class ConfirmAccount extends Component {
    state = {
        userID: null,
        password: "",
        repeatPassword: "",
        alert: "",
        userName: "",
        showContactToAdmin: false,
        showContactModal: false,
        contactFormData: null,
        showForm: false,
    };

    handleShowAlert = (alert) => {
        this.setState({ alert: alert });
    };

    setPassword = async (passData) => {
        const { fetchURL, t } = this.props;
        this.handleShowAlert({ variant: "info", message: t("info.settingNewPass") });
        try {
            const response = await fetch(`${fetchURL}/set-password`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") this.setState({ alert: { variant: "danger", message: t(`error.${fetchedData.code}`) } });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "expired_link") this.setState({ alert: { variant: "warning", message: t(`error.${fetchedData.code}`) } });
                    else {
                        this.setState({ alert: { variant: "success", message: t(`success.setNewPassword`) } });
                        window.location.assign("/");
                    }
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleSetPassword = (e) => {
        e.preventDefault();
        const { t, location } = this.props;
        this.handleShowAlert("");
        let errorMsg = false;
        const { password, repeatPassword } = this.state;
        if (!password || !repeatPassword) {
            this.setState({ password: "", repeatPassword: "" });
            errorMsg = t("error.reqFields");
        } else if (password !== repeatPassword) {
            this.setState({ password: "", repeatPassword: "" });
            errorMsg = t("error.passNotIdentical");
        }

        if (errorMsg) {
            this.handleShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const dataPass = {
                password: password,
                repeatPassword: repeatPassword,
                postDataURL: {
                    url: `${location.pathname}${location.search}`,
                    pathname: `${location.pathname}`,
                },
            };
            this.setPassword(dataPass);
        }
    };

    confirmAccountView = async (passData) => {
        const { fetchURL, t } = this.props;
        this.handleShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/confirm-account`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") this.setState({ alert: { variant: "danger", message: t(`error.${fetchedData.code}`) } });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === "expired_link") this.setState({ alert: { variant: "warning", message: t(`error.${fetchedData.code}`), contactBtn: true } });
                    else
                        this.setState({
                            userID: fetchedData.userID,
                            userName: fetchedData.userName,
                            showForm: true,
                        });
                });
            }
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        const { location, t } = this.props;
        const parsedLocationSearch = queryString.parse(location.search);
        if (location.search === "" || parsedLocationSearch.url === null) {
            this.handleShowAlert({
                variant: "warning",
                message: t("error.passLinkIncorrect"),
                contactBtn: {
                    mailAddress: "",
                    mailTitle: t("mails.incorrectPassLinkTitle"),
                    mailSender: "",
                },
            });
        } else {
            const postDataURL = {
                url: `${location.pathname}${location.search}`,
                pathname: `${location.pathname}`,
                generatedString: parsedLocationSearch.url,
            };
            this.confirmAccountView(postDataURL);
        }
    }
    render() {
        const { alert, password, repeatPassword, userName, showContactToAdmin, showContactModal, contactFormData, showForm } = this.state;
        const { fetchURL, t, onShowHomePage } = this.props;
        return (
            <Fragment>
                {alert && (
                    <Alert className="alert-basic" variant={alert.variant} onClose={() => this.setState({ alert: "" })} dismissible>
                        {alert.message}
                        {alert.contactBtn && (
                            <Button
                                variant={`outline-${alert.variant}`}
                                onClick={() => this.setState({ showContactModal: true, contactFormData: alert.contactBtn }, () => this.setState({ alert: "" }))}
                                className="d-block mt-2"
                            >
                                {t("confirmAccount.contactWithAdminModal")}
                            </Button>
                        )}
                    </Alert>
                )}
                {showForm && (
                    <Container>
                        {showContactToAdmin ? (
                            <ContactToAdmin t={t} onShowHomePage={onShowHomePage} />
                        ) : (
                            <Fragment>
                                <LogoAndTitle title={t("confirmAccount.title")} />

                                <Row>
                                    <Col lg={{ span: 6, offset: 3 }}>
                                        <p className="mb-3">
                                            {t("confirmAccount.text1")}
                                            {userName}
                                            {t("confirmAccount.text2")}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={{ span: 6, offset: 3 }}>
                                        <Form onSubmit={this.handleSetPassword} autoComplete="off">
                                            <Password
                                                t={t}
                                                onShowHomePage={onShowHomePage}
                                                value1={password}
                                                value2={repeatPassword}
                                                onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                option="double"
                                            />
                                            <Form.Group className="text-center">
                                                <Button variant="primary" type="submit">
                                                    {t("confirmAccount.setPassword")}
                                                </Button>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                    <Col className="text-center">
                                        <Link className="btn btn-outline-secondary" to="/">
                                            {t("confirmAccount.homePage")}
                                        </Link>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </Fragment>
                        )}
                    </Container>
                )}

                {showContactModal && (
                    <ContactWithAdminModal
                        t={t}
                        onShowHomePage={onShowHomePage}
                        contactAdminData={contactFormData}
                        show={showContactModal}
                        onClose={() => this.setState({ showContactModal: false, contactFormData: null })}
                        fetchURL={fetchURL}
                    />
                )}
            </Fragment>
        );
    }
}

export default ConfirmAccount;
