import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import EventFilter from "../../forms/dropdowns/EventFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEraser, faLongArrowAltLeft, faLongArrowAltRight, faSync } from "@fortawesome/free-solid-svg-icons";
import NoPermission from "../../other/NoPermission";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import TableCharactersApplicationsList from "./TableCharactersApplicationsList";
import CharacterStatuses from "../../forms/dropdowns/CharacterStatuses";

class CharactersApplicationsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        charsList: this.props.dataList,
        originalChars: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        selectedEvent: "all",
        eventsList: [],
        noPermission: false,
        selectedVerifiedGroup: "all",
        selectedCharStat: "all",
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalChars } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredCharactersApplications = originalChars;
            //console.log(filteredCharactersApplications)
            filteredCharactersApplications = filteredCharactersApplications.filter(
                (el) =>
                    el.char_name.toString().toLowerCase().includes(searchedPhrase) ||
                    el.participant.toString().toLowerCase().includes(searchedPhrase) ||
                    (el.char_fraction && el.char_fraction.toString().toLowerCase().includes(searchedPhrase))
            );
            this.setState({ charsList: filteredCharactersApplications });
        } else {
            this.setState({ charsList: originalChars });
        }
    };

    handleDownloadData = () => {
        this.props.onResizeWindow("tableNotLoaded");
        this.setState({ loading: true });
        this.getCharactersApplicationsList();
    };

    getCharactersApplicationsList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/get-characters-applications-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const charsList = fetchedData.charsList;
                        this.setState(
                            {
                                charsList: charsList,
                                originalChars: charsList,
                            },
                            () => {
                                onGetDataList(charsList);
                                this.setState({ loading: false });
                            }
                        );
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getCharactersApplicationsList();

        const { onResizeWindow } = this.props;
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { searchedPhrase, loading, noPermission, charsList, displayNOR, showNOR, selectedCharStat } = this.state;
        const { t, eventFilterValue, eventsList, onChangeEventFilter, activeEvent, onCheckUserPrivilage, fetchURL, onShowAlert, onShowHomePage } = this.props;

        let filteredCharsList = null;
        if (charsList)
            filteredCharsList = charsList.filter(
                (e) => (eventFilterValue === "all" || e.event_code === eventFilterValue) && (selectedCharStat === "all" || selectedCharStat === e.stat_code)
            );
        //const currentPageParams = { nav: "characters", subpage: "characterApplicationsList", params: "" };
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            charsList: this.state.originalChars,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col lg={1} md={3} xs={5}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EventFilter t={t} eventFilterValue={eventFilterValue} eventsList={eventsList} onChangeEventFilter={onChangeEventFilter} />
                                </Col>
                                <Col lg={3} md={3} xs={5}>
                                    <CharacterStatuses
                                        t={t}
                                        onChangeValue={(value) => this.setState({ selectedCharStat: value })}
                                        value={selectedCharStat}
                                        onShowAlert={onShowAlert}
                                        fetchURL={fetchURL}
                                        onShowHomePage={onShowHomePage}
                                    />
                                </Col>

                                <Col xl={{ offset: 0, span: 3 }} lg={{ span: 2, offset: 4 }} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                {filteredCharsList.length > 0 ? (
                                                    <Fragment>
                                                        <TableCharactersApplicationsList
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                            charsList={filteredCharsList}
                                                            displayNOR={displayNOR}
                                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                                            activeEvent={activeEvent}
                                                            fetchURL={fetchURL}
                                                            onShowAlert={onShowAlert}
                                                            refreshData={() => {
                                                                this.setState({ loading: true }, () => this.getCharactersApplicationsList());
                                                            }}
                                                        />
                                                        <div className="light-border"></div>
                                                    </Fragment>
                                                ) : (
                                                    <div className="text-center">{t("notFound")}</div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            {t("loaded")} {displayNOR > filteredCharsList.length ? filteredCharsList.length : displayNOR} {t("from")} {filteredCharsList.length}{" "}
                                            {t("records")}
                                        </Col>
                                        <Col className="text-center">
                                            {displayNOR >= filteredCharsList.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">{t("thatsAll")}</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    {t("loadMore")}
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default CharactersApplicationsList;
