import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container } from "react-bootstrap";
import TableUsersList from "./TableUsersList";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import { Dropdown } from "semantic-ui-react";
import $ from "jquery";
import { faEraser, faLongArrowAltLeft, faLongArrowAltRight, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../other/NoPermission";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import UserStatuses from "../../forms/dropdowns/UserStatuses";

class UsersList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        usersList: this.props.dataList,
        originalUsers: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        selectedUserRoles: "all",
        selectedUserStatus: "all",
        noPermission: false,
        roleGroups: [
            {
                key: 0,
                value: "all",
                text: this.props.t("selectAll"),
            },
            {
                key: 1,
                value: "CORE",
                text: this.props.t("user.roleGroups.core"),
            },
            {
                key: 2,
                value: "WEB",
                text: this.props.t("user.roleGroups.web"),
            },
            {
                key: 3,
                value: "ACCR",
                text: this.props.t("user.roleGroups.accr"),
            },
            {
                key: 4,
                value: "BAR",
                text: this.props.t("user.roleGroups.bar"),
            },
            {
                key: 5,
                value: "LARP",
                text: this.props.t("user.roleGroups.larp"),
            },
        ],
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalUsers } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredUsers = originalUsers;
            //console.log(filteredUsers)
            filteredUsers = filteredUsers.filter(
                (el) =>
                    el.user_surname.toString().toLowerCase().includes(searchedPhrase) ||
                    el.user_name.toString().toLowerCase().includes(searchedPhrase) ||
                    el.user_email.toString().toLowerCase().includes(searchedPhrase) ||
                    (el.user_nickname && el.user_nickname.toString().toLowerCase().includes(searchedPhrase)) //el.user_nickname może być nullem
            );
            this.setState({ usersList: filteredUsers });
        } else {
            this.setState({ usersList: originalUsers });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getUserList();
    };

    getUserList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/users-list`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const usersList = fetchedData.usersList;
                        this.setState({ usersList: usersList, originalUsers: usersList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(usersList);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    searchForRoles(userRolesList, selectedUserRoles) {
        if (userRolesList.includes(selectedUserRoles)) return true;
        else return false;
    }

    filterUsersList = (e) => {
        const { selectedUserRoles, selectedUserStatus } = this.state;

        if ((selectedUserRoles === "all" || this.searchForRoles(e.user_role_groupes, selectedUserRoles) === true) && (selectedUserStatus === "all" || e.user_stat_code === selectedUserStatus))
            return true;
        else return false;
    };

    componentDidMount() {
        this.getUserList();
        const { onResizeWindow } = this.props;
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const { usersList, searchedPhrase, loading, displayNOR, showNOR, noPermission, selectedUserStatus } = this.state;
        const { onCheckUserPrivilage, onChangeNavAndPage, onCheckAdmin, fetchURL, onShowAlert, t, onShowHomePage } = this.props;

        const currentPageParams = { nav: "users", subpage: "usersList", params: "" };

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={3} md={4} xs={12}>
                                    <Form.Label>{t("form.searching")}</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                        {searchedPhrase && (
                                            <InputGroup.Append>
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() =>
                                                        this.setState({
                                                            searchedPhrase: "",
                                                            usersList: this.state.originalUsers,
                                                        })
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                            </InputGroup.Append>
                                        )}
                                    </InputGroup>
                                </Col>
                                <Col xl={1} lg={2} md={2} xs={3}>
                                    <RowsDropdown
                                        t={t}
                                        defaultValue={this.props.numberOfRecords}
                                        onChangeValue={(event, { value }) => {
                                            this.setState({
                                                showNOR: value,
                                                displayNOR: value,
                                            });
                                            this.props.onChangeNOR(value);
                                        }}
                                    />
                                </Col>

                                <Col lg={3} md={4} xs={6}>
                                    <Form.Label>{t("user.roles")}:</Form.Label>
                                    <Dropdown
                                        className="mb-3"
                                        placeholder={t("event.clickToChooseGroupRoles")}
                                        fluid
                                        selection
                                        options={this.state.roleGroups}
                                        onChange={(event, { value }) => {
                                            this.setState({
                                                selectedUserRoles: value,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col lg={2} md={3} xs={6}>
                                    <UserStatuses
                                        defaultValue={selectedUserStatus}
                                        value={selectedUserStatus}
                                        userDetails={false}
                                        onChangeStatus={(event, { value }) => this.setState({ selectedUserStatus: value })}
                                        fetchURL={fetchURL}
                                        onShowAlert={onShowAlert}
                                        t={t}
                                        onShowHomePage={onShowHomePage}
                                    />
                                </Col>
                                <Col lg={3} md={4} xs={12} className="text-center text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                    {onCheckUserPrivilage("COMMON_USER_ACTIONS") && (
                                        <Button variant="outline-primary" type="button" onClick={() => onChangeNavAndPage({ nav: "users", subpage: "addUser", params: "" }, currentPageParams)}>
                                            {t("user.addUser")}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <div id="loadTable" style={{ display: "none" }}>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                {usersList.filter(this.filterUsersList).length > 0 ? (
                                                    <Fragment>
                                                        <TableUsersList
                                                            users={usersList.filter(this.filterUsersList)}
                                                            numOfRecords={displayNOR}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            onCheckAdmin={onCheckAdmin}
                                                            t={t}
                                                            onShowHomePage={onShowHomePage}
                                                            onCheckUserPrivilage={onCheckUserPrivilage}
                                                        />
                                                        <div className="light-border"></div>
                                                    </Fragment>
                                                ) : (
                                                    <div className="text-center">{t("notFound")}</div>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            {t("loaded")} {displayNOR > usersList.filter(this.filterUsersList).length ? usersList.filter(this.filterUsersList).length : displayNOR} {t("from")}{" "}
                                            {usersList.filter(this.filterUsersList).length} {t("records")}
                                        </Col>
                                        <Col className="text-center ">
                                            {displayNOR >= usersList.filter(this.filterUsersList).length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">{t("thatsAll")}</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    {t("loadMore")}
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </div>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default UsersList;
