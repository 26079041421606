import React, { Component, Fragment } from "react";
import { Alert, Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class UsersListAvailableToAssign extends Component {
    state = { usersFullname: null, noPermission: false };

    getUsersFullname = async () => {
        const { fetchURL, onShowAlert, activeEvent, t } = this.props;
        const passData = {
            eventCode: activeEvent.code,
        };
        try {
            const response = await fetch(`${fetchURL}/users-list-available-to-assign`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ usersFullname: fetchedData.usersFullname });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        this.getUsersFullname();
    }

    render() {
        const { usersFullname, noPermission } = this.state;
        const { labelTxt, onFullnameSelected, defaultValue, t } = this.props;
        return (
            <Fragment>
                <Form.Label>
                    {labelTxt} <span className="required">*</span>
                </Form.Label>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : usersFullname ? (
                    usersFullname.length > 0 ? (
                        <Dropdown
                            defaultValue={defaultValue}
                            id="fullnameDropdown"
                            className="req mb-3"
                            placeholder={t("selectOrType")}
                            fluid
                            search
                            selection
                            options={usersFullname}
                            onChange={(event, { value }) => onFullnameSelected(event, { value })}
                            noResultsMessage={t("participant.personNotFound")}
                        />
                    ) : (
                        <Alert variant="warning">{t("user.noUsers")}</Alert>
                    )
                ) : (
                    <LoadingProgressBar t={t} />
                )}
            </Fragment>
        );
    }
}

export default UsersListAvailableToAssign;
