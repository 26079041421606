import React, { Fragment, Component } from "react";
import { Button, Row, Col, Form, InputGroup, Container } from "react-bootstrap";
import TransactionsListTable from "./TransactionsListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import "react-widgets/dist/css/react-widgets.css";
import $ from "jquery";
import EventFilter from "../../forms/dropdowns/EventFilter";
import RowsDropdown from "../../forms/dropdowns/RowsDropdown";
import { faEraser, faLongArrowAltLeft, faLongArrowAltRight, faRedoAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoPermission from "../../other/NoPermission";
import PaymentTypeGroupsDropdown from "../../forms/dropdowns/PaymentTypeGroupsDropdown";
import PaymentTypeMethodsDropdown from "../../forms/dropdowns/PaymentTypeMethodsDropdown";
import PaymentTypeSidesDropdown from "../../forms/dropdowns/PaymentTypeSidesDropdown";
import PaymentAmountsDropdown from "../../forms/dropdowns/PaymentAmountsDropdown";

class TransactionsList extends Component {
    state = {
        showNOR: this.props.numberOfRecords,
        displayNOR: this.props.numberOfRecords,
        transactionsList: this.props.dataList,
        originalTransactions: this.props.dataList,
        searchedPhrase: "",
        loading: this.props.dataList ? false : true,
        selectedPaymentGroup: "all",
        selectedPaymentMethod: "all",
        selectedPaymentSide: "all",
        selectedPaymentAmountStart: "",
        selectedPaymentAmountEnd: "",
    };

    handleResetFilters = () => {
        this.setState({ selectedPaymentGroup: "all", selectedPaymentMethod: "all", selectedPaymentSide: "all", selectedPaymentAmountStart: "", selectedPaymentAmountEnd: "" });
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalTransactions } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredParts = originalTransactions;
            //console.log(filteredParts)
            filteredParts = filteredParts.filter(
                (el) =>
                    el.participant_surname.toString().toLowerCase().includes(searchedPhrase) ||
                    el.participant_name.toString().toLowerCase().includes(searchedPhrase) ||
                    (el.participant_nickname && el.participant_nickname.toString().toLowerCase().includes(searchedPhrase)) //el.user_nickname może być nullem
            );
            this.setState({ transactionsList: filteredParts });
        } else {
            this.setState({ transactionsList: originalTransactions });
        }
    };

    handleDownloadData = () => {
        this.props.onResizeWindow("tableNotLoaded");
        this.setState({ loading: true });
        this.getTransactionsList();
    };

    getTransactionsList = async () => {
        const { fetchURL, onShowAlert, onGetDataList, t } = this.props;
        console.log("getTransactionsList");
        try {
            const response = await fetch(`${fetchURL}/transactions-list`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const transactionsList = fetchedData.transactionsList;
                        this.setState({ transactionsList: transactionsList, originalTransactions: transactionsList }, () => {
                            this.setState({ loading: false });
                            onGetDataList(transactionsList);
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow } = this.props;
        console.log("TransactionsList");
        this.getTransactionsList();
        onResizeWindow("tableNotLoaded");
        $(window).on("resize", function () {
            onResizeWindow("tableLoaded");
        });
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if (!this.state.loading) {
            $("#loadTable").fadeIn(200);
            if ($(".light-border").outerHeight() && $(".rest-info").outerHeight()) {
                onResizeWindow("tableLoaded");
            }
        }
    }

    render() {
        const {
            transactionsList,
            searchedPhrase,
            loading,
            displayNOR,
            showNOR,
            selectedPaymentGroup,
            selectedPaymentMethod,
            noPermission,
            selectedPaymentSide,
            selectedPaymentAmountStart,
            selectedPaymentAmountEnd,
        } = this.state;
        const { onCheckUserPrivilage, onChangeNavAndPage, eventFilterValue, activeEvent, t, onShowHomePage, currentPage, onShowAlert, fetchURL } = this.props;

        function filterList(e) {
            console.log();
            if (
                (eventFilterValue === "all" || e.event_code === eventFilterValue) &&
                (selectedPaymentSide === "all" || e.payment_type_side === selectedPaymentSide) &&
                (selectedPaymentMethod === "all" || e.payment_type_method === selectedPaymentMethod) &&
                (selectedPaymentGroup === "all" || e.payment_type_group === selectedPaymentGroup) &&
                ((!selectedPaymentAmountStart && !selectedPaymentAmountEnd) ||
                    (selectedPaymentAmountStart && parseFloat(e.payment_amount) >= parseFloat(selectedPaymentAmountStart) && !selectedPaymentAmountEnd) ||
                    (selectedPaymentAmountEnd && parseFloat(e.payment_amount) <= parseFloat(selectedPaymentAmountEnd) && !selectedPaymentAmountStart) ||
                    (selectedPaymentAmountStart &&
                        parseFloat(e.payment_amount) >= parseFloat(selectedPaymentAmountStart) &&
                        selectedPaymentAmountEnd &&
                        parseFloat(e.payment_amount) <= parseFloat(selectedPaymentAmountEnd)))
            )
                return true;
            else return false;
        }

        let filteredPartList = [];
        if (transactionsList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredPartList = transactionsList.filter(filterList);
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="oneNavbar" />
                ) : (
                    <Fragment>
                        <Container fluid className="pt-2 second-bar">
                            <Row>
                                <Col lg={11} xs={12}>
                                    <Row>
                                        <Col lg={3} xs={12}>
                                            <Form.Label>{t("form.searching")}</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control type="text" placeholder={t("form.search")} onChange={this.handleSearch} value={searchedPhrase} />
                                                {searchedPhrase && (
                                                    <InputGroup.Append>
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                this.setState({
                                                                    searchedPhrase: "",
                                                                    transactionsList: this.state.originalTransactions,
                                                                })
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon={faEraser} />
                                                        </Button>
                                                    </InputGroup.Append>
                                                )}
                                            </InputGroup>
                                        </Col>
                                        <Col xl={1} lg={2} md={2} xs={4}>
                                            <RowsDropdown
                                                t={t}
                                                defaultValue={this.props.numberOfRecords}
                                                onChangeValue={(event, { value }) => {
                                                    this.setState({
                                                        showNOR: value,
                                                        displayNOR: value,
                                                    });
                                                    this.props.onChangeNOR(value);
                                                }}
                                            />
                                        </Col>
                                        <Col lg={2} md={3} xs={5}>
                                            <EventFilter t={t} eventFilterValue={this.props.eventFilterValue} eventsList={this.props.eventsList} onChangeEventFilter={this.props.onChangeEventFilter} />
                                        </Col>

                                        <Col lg={2} md={3} xs={5}>
                                            <PaymentTypeGroupsDropdown
                                                t={t}
                                                selectedPaymentGroup={selectedPaymentGroup}
                                                onSelectPaymentGroup={(v) => this.setState({ selectedPaymentGroup: v })}
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                            />
                                        </Col>
                                        <Col lg={2} md={3} xs={5}>
                                            <PaymentTypeSidesDropdown
                                                t={t}
                                                selectedPaymentSide={selectedPaymentSide}
                                                onSelectPaymentSide={(v) => this.setState({ selectedPaymentSide: v })}
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                            />
                                        </Col>
                                        <Col lg={2} md={3} xs={5}>
                                            <PaymentTypeMethodsDropdown
                                                t={t}
                                                selectedPaymentMethod={selectedPaymentMethod}
                                                onSelectPaymentMethod={(v) => this.setState({ selectedPaymentMethod: v })}
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                            />
                                        </Col>
                                        <Col lg={3} md={3} xs={5}>
                                            <PaymentAmountsDropdown
                                                t={t}
                                                selectedPaymentAmountStart={selectedPaymentAmountStart}
                                                selectedPaymentAmountEnd={selectedPaymentAmountEnd}
                                                onSelectPaymentAmountStart={(v) => this.setState({ selectedPaymentAmountStart: v })}
                                                onSelectPaymentAmountEnd={(v) => this.setState({ selectedPaymentAmountEnd: v })}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={1} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <Button variant="outline-primary" onClick={this.handleResetFilters} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faRedoAlt} />
                                    </Button>
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync mr-3">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : (
                                <Fragment>
                                    <div id="loadTable" style={{ display: "none" }}>
                                        <Fragment>
                                            <Row>
                                                <Col>
                                                    <div className="table-responsive table-fixed-head">
                                                        {filteredPartList.length > 0 ? (
                                                            <Fragment>
                                                                <TransactionsListTable
                                                                    transactionsList={filteredPartList}
                                                                    displayNOR={displayNOR}
                                                                    onChangeNavAndPage={onChangeNavAndPage}
                                                                    onCheckUserPrivilage={onCheckUserPrivilage}
                                                                    activeEvent={activeEvent}
                                                                    t={t}
                                                                    onShowHomePage={onShowHomePage}
                                                                    currentPageParams={currentPageParams}
                                                                />
                                                                <div className="light-border"></div>
                                                            </Fragment>
                                                        ) : (
                                                            <div className="text-center">{t("notFound")}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="rest-info py-3">
                                                <Col>
                                                    {t("loaded")} {displayNOR > filteredPartList.length ? filteredPartList.length : displayNOR} {t("from")} {filteredPartList.length} {t("records")}
                                                </Col>
                                                <Col className="text-center">
                                                    {displayNOR >= filteredPartList.length ? (
                                                        <p className="opacity-4">
                                                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                            <span className="px-3">{t("thatsAll")}</span>
                                                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                        </p>
                                                    ) : (
                                                        <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                            {t("loadMore")}
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                        </Fragment>
                                    </div>
                                </Fragment>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default TransactionsList;
