import React, { Component, Fragment } from "react";
import { Row, Col, Button, Form, Alert, Container } from "react-bootstrap";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../navigation/NavDetails";
import $ from "jquery";
import TextForm from "../../forms/text-inputs/TextForm";
import TextareaForm from "../../forms/TextareaForm";
import GroupCoordinator from "../../forms/dropdowns/GroupCoordinator";
import { Switch } from "@material-ui/core";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";

class AddEditGroup extends Component {
    state = {
        groupData: [
            {
                coordinator_user_id: null,
                part_group_name: "",
                part_group_descr: "",
                part_group_verified: false,
            },
        ],
        loading: true,
        changedValues: false,
    };

    //akutalizacja danych {t('from')} formularza w state'cie groupData
    handleChangeGroupData(name, data) {
        const { groupData } = this.state;
        const { onChangeValuesInFormes } = this.props;
        const list = [...groupData];
        list[0][name] = data;
        this.setState({ changedValues: true, groupData: list });
        onChangeValuesInFormes();
    }

    //pobierz dane o grupie
    getGroupData = async (groupID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert("");
        const passData = {
            groupID: groupID,
        };
        try {
            const response = await fetch(`${fetchURL}/group-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response
                    .json()
                    .then((fetchedData) => {
                        console.log(fetchedData);
                        if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                        else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                        else {
                            const groupData = fetchedData.groupData;
                            groupData[0].part_group_verified = groupData[0].part_group_verified === "Y" ? true : false;
                            this.setState({ groupData: fetchedData.groupData });
                        }
                    })
                    .then(() => {
                        this.setState({ loading: false });
                    });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { groupData, alertAssignedCoordinator } = this.state;
        const { onShowAlert, currentPage, activeEvent, t } = this.props;
        onShowAlert("");
        let errorMsg = "";

        if (!groupData[0].part_group_name) {
            errorMsg += t("error.groupNameEmpty");
            $(`#partGroupName`).addClass("danger");
        }

        if (!groupData[0].part_group_descr) {
            errorMsg += t("error.groupDescrEmpty");
            $(`#partGroupDescr`).addClass("danger");
        }

        if (!groupData[0].coordinator_user_id) {
            errorMsg += t("error.noCoordinator");
            $(`#groupCoordinatorDropdown`).addClass("danger");
        } else if (alertAssignedCoordinator) {
            errorMsg += t("error.alreadyCoordinator");
            $(`#fullnameDropdown`).addClass("danger");
        }

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const passData = {
                groupID: currentPage.params.groupID ? currentPage.params.groupID : null,
                coordinatorID: groupData[0].coordinator_user_id,
                partGroupName: groupData[0].part_group_name,
                partGroupDescr: groupData[0].part_group_descr,
                partGroupVerified: !groupData[0].part_group_verified ? "N" : "Y",
                activeEvent: activeEvent,
            };

            //console.log(passData);
            this.insertOrUpdateGroup(passData);
        }
    };

    insertOrUpdateGroup = async (passData) => {
        const { fetchURL, onChangeNavAndPage, onShowAlert, currentPage, t } = this.props;
        onShowAlert({ variant: "info", message: currentPage.subpage === "addGroup" ? t("info.addingGroup") : currentPage.subpage === "editGroup" ? t("info.editingGroup") : "" });
        try {
            const response = await fetch(`${fetchURL}/${currentPage.params.groupID ? "update-group" : "insert-group"}`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({
                            variant: "success",
                            message: `${t("group.groupSuccessfullyAdded1")}${this.state.groupData[0].part_group_name}${t("group.groupSuccessfullyAdded2")}${
                                currentPage.subpage === "addGroup" ? t("group.added") : t("group.edited")
                            }.`,
                            fading: 3000,
                        });
                        onChangeNavAndPage({ nav: "groups", subpage: "groupsList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    addGroupView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/add-group-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: false });
                    else if (fetchedData.status === "false") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    componentDidMount() {
        const { onResizeWindow, activeEvent, currentPage } = this.props;
        if (activeEvent) {
            this.setState({ activeEvent: activeEvent.name });
        }
        onResizeWindow("basic");
        if (currentPage.subpage === "editGroup") this.getGroupData(currentPage.params.groupID);
        else this.addGroupView();
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, groupData, alertAssignedCoordinator, changedValues, noPermission } = this.state;
        const { onCancelChangeValuesInFormes, fetchURL, onBackToPreviousPage, currentPage, onShowAlert, activeEvent, t, onShowHomePage } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={currentPage.subpage === "editGroup" ? t("group.editGroup") : t("group.addGroup")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : !activeEvent ? (
                            <Container>
                                <Row>
                                    <Col>
                                        <Alert variant="danger">{t("group.noActiveEvent")}</Alert>
                                    </Col>
                                </Row>
                            </Container>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col md={6} xs={12}>
                                                <TextForm
                                                    value={groupData[0].part_group_name}
                                                    req
                                                    placeholderTxt={t("group.groupName.placeholder")}
                                                    labelTxt={t("group.groupName.label")}
                                                    onChangeValue={(e) => this.handleChangeGroupData(e.target.name, e.target.value)}
                                                    name="part_group_name"
                                                    id="partGroupName"
                                                />
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <GroupCoordinator
                                                    fetchURL={fetchURL}
                                                    onFullnameSelected={(value) => this.handleChangeGroupData("coordinator_user_id", value)}
                                                    defaultValue={groupData[0].coordinator_user_id}
                                                    activeEvent={activeEvent}
                                                    onShowAlert={onShowAlert}
                                                    coordinatorID={currentPage.subpage === "editGroup" ? groupData[0].coordinator_user_id : null}
                                                    t={t}
                                                    onShowHomePage={onShowHomePage}
                                                    groupID={currentPage.params.groupID}
                                                />
                                                {currentPage.subpage === "editGroup" && (
                                                    <p>
                                                        {t("group.currentGroupCoordinator")}: {groupData[0].coordinator_fullname}
                                                    </p>
                                                )}
                                                {alertAssignedCoordinator && (
                                                    <Alert variant={alertAssignedCoordinator.variant} className="alert-small">
                                                        {alertAssignedCoordinator.message}
                                                    </Alert>
                                                )}
                                            </Col>

                                            <Col xs={12}>
                                                <Form.Group>
                                                    <Form.Label>{t("group.verifiedGroup.label")}</Form.Label>
                                                    <Switch
                                                        id="partGroupVerified"
                                                        checked={groupData[0].part_group_verified}
                                                        onChange={(e) => this.handleChangeGroupData(e.target.name, e.target.checked)}
                                                        name="part_group_verified"
                                                        inputProps={{ "aria-label": "primary checkbox" }}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12}>
                                                <TextareaForm
                                                    value={groupData[0].part_group_descr}
                                                    req
                                                    labelTxt={t("group.groupDescrLabel")}
                                                    placeholderTxt={t("group.groupDescrPlaceholder")}
                                                    onChangeValue={(e) => this.handleChangeGroupData(e.target.name, e.target.value)}
                                                    name="part_group_descr"
                                                    id="partGroupDescr"
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton
                                                        t={t}
                                                        mssgTooltip={t("info.emptyForm")}
                                                        btnTxt={currentPage.subpage === "editGroup" ? t("edit") : t("add")}
                                                        tooltipID="tooltipDisabledNotFilled"
                                                        size="lg"
                                                    />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg">
                                                        {currentPage.subpage === "editGroup" ? t("edit") : t("add")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddEditGroup;
