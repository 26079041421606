import React, { Fragment, Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Email from "../../forms/Email";

class RememberPass extends Component {
    state = { email: "" };

    validateEmail = (email) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    resetPassword = async (dataPass) => {
        const { fetchURL, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.sendingEmail") });

        try {
            const response = await fetch(`${fetchURL}/reset-password`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(dataPass),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission")
                        onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { email } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        if (!email || !this.validateEmail(email)) {
            onShowAlert({ variant: "danger", message: t("error.invalidEmail"), fading: false });
        } else {
            const passedData = {
                email: email,
                lang: "pl",
            };

            this.resetPassword(passedData);
        }
    };

    render() {
        const { email } = this.state;
        const { t, onShowHomePage } = this.props;
        return (
            <Fragment>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }}>
                        <Form onSubmit={this.handleCheckValidation} id="rememberPassForm" autoComplete="off">
                            <Email
                                t={t}
                                onShowHomePage={onShowHomePage}
                                value={email}
                                onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                id="userEmail"
                                name="email"
                            />

                            <Form.Group className="text-center">
                                <Button variant="primary" type="submit">
                                    {t("user.resetPassword")}
                                </Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default RememberPass;
