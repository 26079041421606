import React, { Component, Fragment } from "react";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import TooltipWithDisabledButton from "../../tooltips/TooltipWithDisabledButton";
import NavDetails from "../../navigation/NavDetails";
import $ from "jquery";
import TextForm from "../../forms/text-inputs/TextForm";
import TextareaForm from "../../forms/TextareaForm";
import LoadingSpinnerInner from "../../spinners/LoadingSpinnerInner";
import NoPermission from "../../other/NoPermission";

class AddEditMail extends Component {
    state = {
        mailData: [
            {
                mail_tmpl_name: "",
                mail_tmpl_title: "",
                mail_tmpl_content: "",
            },
        ],
        loading: true,
        noPermission: false,
    };

    //zmiana danych w formularzu
    handleMailData = (e) => {
        const { name, value, id } = e.target;
        const { mailData } = this.state;
        const list = [...mailData];
        list[0][name] = value;
        $(`#${id}`).removeClass("danger");
        this.setState({ mailData: list, changedValues: true });
        this.props.onChangeValuesInFormes();
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        const { mailData } = this.state;
        const { onShowAlert, t } = this.props;
        onShowAlert("");
        let errorMsg = "";

        $(".form-control.req").each(function () {
            if ($(this).val() === "" || $(this).val() === "default") {
                $(this).addClass("danger");
                errorMsg = t("error.erqFields");
            } else $(this).removeClass("danger");
        });

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            let mailContent = mailData[0].mail_tmpl_content;
            mailContent = mailContent.replaceAll(/\s+/g, " "); // zamień wszystkie whitespace'y na spację
            mailContent = mailContent.replaceAll(/>\s</g, ""); //usuń wszystkie spacje znajdujące się między ostrymi nawiasami
            mailContent = mailContent.replaceAll(/<script>[^<]*<\/script>/g, ""); //usuń wszystkie skrypty

            //console.log(mailContent);
            const list = [...mailData];
            list[0].mail_tmpl_content = mailContent;
            this.setState({ mailData: list }, () => this.insertMail());
        }
    };

    insertMail = async () => {
        const { mailData } = this.state;
        const { fetchURL, onChangeNavAndPage, onShowAlert, t } = this.props;
        onShowAlert({ variant: "info", message: t("info.addingNewMailTmpl") });
        mailData[0].mail_tmpl_content.replaceAll(/[^\S ]+/g, "");

        const passData = {
            mailData: mailData,
        };

        try {
            const response = await fetch(`${fetchURL}/insert-tmpl-mail`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === false || fetchedData.status === "noPermission") onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: `${t("mails.addMailTxt1")}${mailData[0].mail_tmpl_name}${t("mails.addMailTxt2")}`, fading: 3000 });
                        onChangeNavAndPage({ nav: "mails", subpage: "mailsList", params: "" }, null);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };
    addMailView = async () => {
        const { fetchURL, onShowAlert, t } = this.props;
        try {
            const response = await fetch(`${fetchURL}/add-mail-view`, {
                credentials: "include",
                method: "POST",
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else this.setState({ loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        const { onResizeWindow, activeEvent } = this.props;
        if (activeEvent) {
            this.setState({ activeEvent: activeEvent.name });
        }
        onResizeWindow("basic");
        this.addMailView();
    }

    componentDidUpdate() {
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !this.state.loading) onResizeWindow("basic");
        if (!this.state.loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, mailData, changedValues, noPermission } = this.state;
        const { onCancelChangeValuesInFormes, onBackToPreviousPage, t } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t("mails.createNewTmpl")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col md={6} xs={12}>
                                                <TextForm
                                                    value={mailData[0].mail_tmpl_name}
                                                    onChangeValue={this.handleMailData}
                                                    req
                                                    labelTxt={t("mails.mailTmplName.label")}
                                                    placeholderTxt={t("mails.mailTmplName.placeholder")}
                                                    name="mail_tmpl_name"
                                                    id="mail_tmpl_name"
                                                />
                                            </Col>
                                            <Col md={6} xs={12}>
                                                <TextForm
                                                    value={mailData[0].mail_tmpl_title}
                                                    onChangeValue={this.handleMailData}
                                                    req
                                                    labelTxt={t("mails.mailTmplTitle.label")}
                                                    placeholderTxt={t("mails.mailTmplTitle.placeholder")}
                                                    name="mail_tmpl_title"
                                                    id="mail_tmpl_title"
                                                />
                                            </Col>
                                            <Col xs={12}>
                                                <TextareaForm
                                                    value={mailData[0].mail_tmpl_content}
                                                    onChangeValue={this.handleMailData}
                                                    req
                                                    labelTxt={t("mails.mailTmplTxt.label")}
                                                    placeholderTxt={t("mails.mailTmplTxt.placeholder")}
                                                    name="mail_tmpl_content"
                                                    id="mail_tmpl_content"
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="content-bottom">
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="py-3">
                                                {!changedValues ? (
                                                    <TooltipWithDisabledButton
                                                        t={t}
                                                        mssgTooltip={t("info.emptyForm")}
                                                        btnTxt={t("add")}
                                                        tooltipID="tooltipDisabledNotFilled"
                                                        size="lg"
                                                    />
                                                ) : (
                                                    <Button type="submit" variant="primary" size="lg" className="mb-3">
                                                        {t("add")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddEditMail;
