import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Table, Row, Col, Button, Container, Dropdown, Form, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";
import NavDetails from "../../../navigation/NavDetails";
import NoPermission from "../../../other/NoPermission";
import LoadingSpinnerInner from "../../../spinners/LoadingSpinnerInner";
import { faInfoCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import ShopItemStatuses from "../../../forms/dropdowns/ShopItemStatuses";
import ShopItemSendEmailModal from "../../../modals/ShopItemSendEmailModal";

class ShopOrderDetails extends Component {
    state = {
        loading: true,
        shopOrderDetails: null,
        shopOrderItemsList: null,
        noPermission: false,
        itemDetails: null,
        changedValues: false,
        oldStatuses: null,
        showOrderWriteEmailModal: null,
    };

    getOrderDetails = async (orderCode) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            orderCode: orderCode,
        };
        onShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/shop-order-details`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    if (fetchedData.status === true)
                        this.setState({ shopOrderDetails: fetchedData.shopOrderDetails }, () => this.getShopOrderItems(fetchedData.shopOrderDetails[0].shord_id));
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    getShopOrderItems = async (shordID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            shordID: shordID,
        };
        onShowAlert("");
        try {
            const response = await fetch(`${fetchURL}/shop-order-items`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    if (fetchedData.status === true) {
                        const items = fetchedData.shopOrderItemsList;
                        let oldStatuses = [];
                        items.forEach((e) => {
                            oldStatuses.push(e.shordit_stat_code);
                        });
                        this.setState({ shopOrderItemsList: items, oldStatuses: oldStatuses }, () => this.setState({ loading: false }));
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    updateShopOrderItemStatus = async () => {
        const { shopOrderDetails, oldStatuses, shopOrderItemsList } = this.state;
        const { fetchURL, onShowAlert, t, onCancelChangeValuesInFormes } = this.props;

        const items = shopOrderItemsList.filter((e, i) => e.shordit_stat_code !== oldStatuses[i] && oldStatuses[i] !== "CANCELED");
        //console.log(items);
        const passData = {
            shordItemStatuses: items,
            userEmail: shopOrderDetails[0].user_email,
            userFullname: shopOrderDetails[0].user_fullname,
            partID: shopOrderDetails[0].part_id,
            orderCode: shopOrderDetails[0].shord_code,
        };
        onShowAlert({ variant: "info", message: t("info.updatingStatuses") });
        try {
            const response = await fetch(`${fetchURL}/update-shop-order-item-status`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        this.setState({ changedValues: false });
                        onCancelChangeValuesInFormes();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
            this.setState({ loading: false });
        }
    };

    handleSelectStatus = (value, text, i) => {
        const { shopOrderItemsList } = this.state;
        const list = [...shopOrderItemsList];
        list[i].shordit_stat_code = value;
        list[i].shordit_stat_name = text;
        this.setState({ changedValues: true, shopOrderItemsList: list });
        this.props.onChangeValuesInFormes();
    };

    componentDidMount() {
        const { onResizeWindow, currentPage } = this.props;
        onResizeWindow("basic");
        this.getOrderDetails(currentPage.params.orderCode);
        $("#uploadFile").on("change", function () {
            $("span").text($(this).val());
        });
    }

    componentDidUpdate() {
        const { loading } = this.state;
        const { onResizeWindow } = this.props;
        if ($(".scrolled-content").outerHeight() && !loading) onResizeWindow("basic");
        if (!loading) $(".scrolled-content").fadeIn();
    }

    render() {
        const { loading, noPermission, shopOrderDetails, shopOrderItemsList, changedValues, oldStatuses, showOrderWriteEmailModal } = this.state;
        const { onChangeNavAndPage, t, onCheckUserPrivilage, onBackToPreviousPage, currentPage, onCancelChangeValuesInFormes, onShowAlert, fetchURL, onShowHomePage } = this.props;
        const currentPageParams = { nav: "shop", subpage: "shopOrderDetails", params: { orderCode: currentPage.params.orderCode } };

        function itemsValue(items) {
            let value = 0;
            items.forEach((e) => {
                value += e.shordit_price * e.shordit_quantity;
            });

            const sum = parseFloat(value.toFixed(2));
            console.log(sum, typeof sum);
            return sum;
        }

        function isOrderPaied() {
            console.log(
                shopOrderDetails[0].payment_amount === itemsValue(shopOrderItemsList) ? (
                    <span className="text-success">{t("shop.ordersList.paied")}</span>
                ) : shopOrderDetails[0].payment_amount > itemsValue(shopOrderItemsList) ? (
                    <span className="text-info">{t("shop.ordersList.overpaied")}</span>
                ) : shopOrderDetails[0].payment_amount < (itemsValue(shopOrderItemsList) && shopOrderDetails[0].payment_amount > 0) ? (
                    <span className="text-warning">{t("shop.ordersList.underpaied")}</span>
                ) : (
                    <span className="text-danger">{t("shop.ordersList.notpaied")}</span>
                )
            );
            return shopOrderDetails[0].payment_amount === itemsValue(shopOrderItemsList) ? (
                <span className="text-success">{t("shop.ordersList.paied")}</span>
            ) : shopOrderDetails[0].payment_amount > itemsValue(shopOrderItemsList) ? (
                <span className="text-info">{t("shop.ordersList.overpaied")}</span>
            ) : shopOrderDetails[0].payment_amount < (itemsValue(shopOrderItemsList) && shopOrderDetails[0].payment_amount > 0) ? (
                <span className="text-warning">{t("shop.ordersList.underpaied")}</span>
            ) : (
                <span className="text-danger">{t("shop.ordersList.notpaied")}</span>
            );
        }

        return (
            <Fragment>
                {noPermission ? (
                    <NoPermission t={t} option="twoNavbars" />
                ) : (
                    <Fragment>
                        <Container fluid className="nav-details">
                            <Row>
                                <NavDetails
                                    t={t}
                                    header={t("shop.orderDetails")}
                                    changedValues={changedValues}
                                    onChangeView={onBackToPreviousPage}
                                    onCancelChangeValuesInFormes={onCancelChangeValuesInFormes}
                                />
                                <Col>
                                    {onCheckUserPrivilage("ACCR_SHOP_ORDER_ACTIONS") ? (
                                        <Dropdown>
                                            <Dropdown.Toggle variant="outline-primary" id="dropdownParticipantActions" className="float-right mt-1 d-inline-block">
                                                {t("dropdowns.actions")}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item
                                                    as="button"
                                                    onClick={() =>
                                                        onChangeNavAndPage(
                                                            {
                                                                nav: "participants",
                                                                subpage: "postPayment",
                                                                params: {
                                                                    partID: shopOrderDetails[0].part_id,
                                                                    partName: shopOrderDetails[0].user_fullname,
                                                                    partIdentNumber: shopOrderDetails[0].part_ident_number,
                                                                },
                                                            },
                                                            currentPageParams
                                                        )
                                                    }
                                                >
                                                    {t("payment.registerTransaction")}
                                                </Dropdown.Item>
                                                <Dropdown.Item as="button" onClick={() => this.setState({ showOrderWriteEmailModal: true })}>
                                                    {t("shop.writeEmail")}
                                                </Dropdown.Item>

                                                <Dropdown.Item
                                                    as="button"
                                                    onClick={() => {
                                                        this.setState({ loading: true, changedValues: false }, () => this.getOrderDetails(currentPage.params.orderCode));
                                                    }}
                                                >
                                                    {t("dropdowns.refreshData")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (
                                        <Button
                                            variant="outline-primary"
                                            onClick={() => {
                                                this.setState({ loading: true, changedValues: false }, () => this.getOrderDetails(currentPage.params.orderCode));
                                            }}
                                            className="btn-sync float-right mt-1"
                                        >
                                            <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                        {loading ? (
                            <div style={{ height: $(window).height() - $("#mainNavbar").outerHeight() - $(`#borderNavbar`).outerHeight() - $("footer.small").outerHeight() - 51 }}>
                                <LoadingSpinnerInner />
                            </div>
                        ) : (
                            <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                <div className="scrolled-content" style={{ display: "none" }}>
                                    <Container className="py-3">
                                        <Row>
                                            <Col xs={12} lg={8}>
                                                <p className="h4 my-4 form-title">{t("data")}</p>
                                                <Table bordered className="table-details" responsive="lg" id="shopOrderDetailsTable">
                                                    <tbody>
                                                        <tr>
                                                            <td width="40%">{t("shop.ordersList.code")}</td>
                                                            <td>{shopOrderDetails[0].shord_code}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("shop.ordersList.orderedBy")}</td>
                                                            <td>{shopOrderDetails[0].user_fullname}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("shop.ordersList.value")}</td>
                                                            <td>
                                                                {itemsValue(shopOrderItemsList)} {t("currency")}
                                                                {itemsValue(shopOrderItemsList) > 0 && <span> - {isOrderPaied()}</span>}
                                                            </td>
                                                        </tr>
                                                        {itemsValue(shopOrderItemsList) > 0 && (
                                                            <tr>
                                                                <td>{t("shop.ordersList.paymentAmount")}</td>
                                                                <td>
                                                                    {shopOrderDetails[0].payment_amount ? (
                                                                        `${shopOrderDetails[0].payment_amount.toFixed(2)} ${t("currency")}`
                                                                    ) : (
                                                                        <span className="opacity-4">{t("lack")}</span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )}
                                                        <tr>
                                                            <td>{t("shop.orderComment")}</td>
                                                            <td>
                                                                {shopOrderDetails[0].shord_comment ? (
                                                                    shopOrderDetails[0].shord_comment
                                                                ) : (
                                                                    <span className="opacity-4">{t("lack")}</span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t("shop.ordersList.date")}</td>
                                                            <td>{shopOrderDetails[0].create_date}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col xs={12} className="mt-4">
                                                <p className="h4 my-4 form-title">{t("shop.products")}</p>
                                                <Table id="shopOrderItemTable" className="table-list" bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>{t("shop.itemsList.name")}</th>
                                                            <th>{t("shop.itemsList.changeStatus")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {shopOrderItemsList.map((e, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    {e.shit_name}
                                                                    {e.shit_variant_name && `, ${e.shit_variant_name}`} x{e.shordit_quantity} -{" "}
                                                                    {(e.shordit_quantity * e.shordit_price).toFixed(2)} {t("currency")}
                                                                </td>
                                                                <td width="40%">
                                                                    {oldStatuses[i] === "CANCELED" ? (
                                                                        <OverlayTrigger
                                                                            overlay={
                                                                                <Tooltip id="tooltipCannotChangeCanceledStatus">{t("shop.cannotChangeCanceledStatus")}</Tooltip>
                                                                            }
                                                                        >
                                                                            <span className="d-inline-block w-100">
                                                                                <Form.Control type="text" value={e.shordit_stat_name} readOnly />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    ) : onCheckUserPrivilage("ACCR_SHOP_ORDER_ACTIONS") ? (
                                                                        <ShopItemStatuses
                                                                            onSelectStatus={(value, text) => this.handleSelectStatus(value, text, i)}
                                                                            defaultValue={e.shordit_stat_code}
                                                                            t={t}
                                                                            onShowHomePage={onShowHomePage}
                                                                            onShowAlert={onShowAlert}
                                                                            fetchURL={fetchURL}
                                                                        />
                                                                    ) : (
                                                                        <span className="d-inline-block w-100">
                                                                            <Form.Control type="text" value={e.shordit_stat_name} readOnly />
                                                                        </span>
                                                                    )}
                                                                    {oldStatuses[i] !== "PAID" && e.shordit_stat_code === "PAID" && (
                                                                        <Alert variant="info" className="alert-small my-2">
                                                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                                                                            {t("shop.transactionReminder")}
                                                                        </Alert>
                                                                    )}
                                                                    {oldStatuses[i] !== "PENDING" && e.shordit_stat_code === "PENDING" && (
                                                                        <Alert variant="info" className="alert-small my-2">
                                                                            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                                                                            {t("shop.pendingOrderItemInfo")}
                                                                        </Alert>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                {changedValues && <Alert variant="info">{t("info.orderItemsChangedStatus")}</Alert>}
                                                {onCheckUserPrivilage("ACCR_SHOP_ORDER_ACTIONS") && (
                                                    <Button variant="primary" disabled={!changedValues} onClick={this.updateShopOrderItemStatus}>
                                                        {t("save")}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Form>
                        )}
                    </Fragment>
                )}

                {showOrderWriteEmailModal && (
                    <ShopItemSendEmailModal
                        fetchURL={fetchURL}
                        show={showOrderWriteEmailModal}
                        addressee={{ email: shopOrderDetails[0].user_email, orderCode: shopOrderDetails[0].shord_code }}
                        onCloseModal={() => this.setState({ showOrderWriteEmailModal: false, adressee: null })}
                        shopOrderSentEmail={() => {
                            onShowAlert({ variant: "success", message: t("info.messageSentSuccessfully"), fading: 2000 });
                            this.setState({ showOrderWriteEmailModal: false, adressee: null });
                        }}
                        t={t}
                        onShowHomePage={onShowHomePage}
                        onShowAlert={onShowAlert}
                    />
                )}
            </Fragment>
        );
    }
}

export default ShopOrderDetails;
