import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Button, Col, Table } from "react-bootstrap";
import AlertNoPermission from "../../../alerts/AlertNoPermission";
import LoadingProgressBar from "../../../spinners/LoadingProgressBar";
import CharacterDetailsModal from "../../../modals/CharacterDetailsModal";

class CharactersList extends Component {
    state = {
        noPermission: false,
        loading: true,
        partChars: null,
        showDetailsModal: false,
    };

    getPartCharacters = async (partID) => {
        const { fetchURL, onShowAlert, t } = this.props;
        const passData = {
            partID: partID,
        };

        try {
            const response = await fetch(`${fetchURL}/get-part-characters`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else this.setState({ partChars: fetchedData.charsList, loading: false });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getPartCharacters(this.props.partID);
    }

    render() {
        const { noPermission, loading, partChars, showDetailsModal } = this.state;
        const { t, fetchURL, onShowAlert } = this.props;
        return (
            <Fragment>
                <Col xs={12}>
                    <p className="h3 my-3 d-inline-block mr-3 align-middle">{t("participant.charactersList")}</p>
                </Col>
                {noPermission ? (
                    <AlertNoPermission t={t} />
                ) : (
                    <Fragment>
                        <Col xs={12} className="mb-3">
                            {loading ? (
                                <LoadingProgressBar t={t} />
                            ) : partChars.length > 0 ? (
                                <Fragment>
                                    <Table bordered hover size="sm" className="table-list">
                                        <thead>
                                            <tr>
                                                <th>{t("characters.characterName")}</th>
                                                <th>{t("characters.createDateApp")}</th>
                                                <th>{t("characters.status")}</th>
                                                <th>{t("characters.acceptDateApp")}</th>
                                                <th>{t("dropdowns.actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {partChars.map((e, i) => (
                                                <tr key={i}>
                                                    <td>{e.char_name}</td>
                                                    <td>{e.create_date}</td>
                                                    <td>
                                                        <span
                                                            className={
                                                                e.char_stat_code === "ACCEPTED"
                                                                    ? "text-success"
                                                                    : e.char_stat_code === "REJECTED"
                                                                    ? "text-danger"
                                                                    : e.char_stat_code === "CANCELED"
                                                                    ? "opacity-4"
                                                                    : "text-primary"
                                                            }
                                                        >
                                                            {e.char_stat_name}
                                                        </span>
                                                    </td>
                                                    <td>{e.accept_date ? e.accept_date : <span className="opacity-4">{t("lack")}</span>}</td>
                                                    <td className="text-center">
                                                        <Button
                                                            variant="outline-primary"
                                                            size="sm"
                                                            className="mx-1"
                                                            onClick={() => this.setState({ showDetailsModal: { show: true, charID: e.char_id, charDetails: e, eventCode: e.event_code } })}
                                                        >
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Fragment>
                            ) : (
                                <p className="opacity-4 mb-3">{t("characters.noCharacters")}</p>
                            )}
                        </Col>
                    </Fragment>
                )}

                {showDetailsModal && (
                    <CharacterDetailsModal
                        t={t}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        show={showDetailsModal.show}
                        charID={showDetailsModal.charID}
                        eventCode={showDetailsModal.eventCode}
                        charDetails={showDetailsModal.charDetails}
                        onClose={() => this.setState({ showDetailsModal: null })}
                    />
                )}
            </Fragment>
        );
    }
}

export default CharactersList;
