import React, { Component, Fragment } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import AlertNoPermission from "../alerts/AlertNoPermission";
import ApplicationStatuses from "../forms/dropdowns/ApplicationStatuses";

class ChangeAppStatusModal extends Component {
    state = {
        selectedAppStat: this.props.partAppData.appStatus,
        oldSelectedAppStat: this.props.partAppData.appStatus,
        noPermission: false,
    };

    changeApplicationStatus = async () => {
        const { selectedAppStat, oldSelectedAppStat } = this.state;
        const { fetchURL, onShowAlert, t, partAppData, onClose, onDownloadData } = this.props;
        const passData = { partAppID: partAppData.appID, partAppStat: selectedAppStat, oldPartAppStat: oldSelectedAppStat };
        onShowAlert({ variant: "info", message: t("info.changingAppStatus") });
        try {
            const response = await fetch(`${fetchURL}/change-application-status`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`), fading: false });
                    else {
                        onShowAlert({ variant: "success", message: t(`success.${fetchedData.code}`), fading: 2000 });
                        onDownloadData();
                        onClose();
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.changeApplicationStatus();
    };

    render() {
        const { selectedAppStat, oldSelectedAppStat, noPermission } = this.state;
        const { show, t, onClose, fetchURL, onShowAlert, onShowHomePage } = this.props;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose}>
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t("applications.changeAppStatModal.title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {noPermission ? (
                                <AlertNoPermission t={t} />
                            ) : (
                                <ApplicationStatuses
                                    t={t}
                                    onShowHomePage={onShowHomePage}
                                    onPartAppStatSelected={(value) => {
                                        this.setState({ selectedAppStat: value });
                                    }}
                                    defaultValue={selectedAppStat}
                                    fetchURL={fetchURL}
                                    onShowAlert={onShowAlert}
                                />
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={onClose}>
                                {t("cancel")}
                            </Button>
                            {!noPermission && (
                                <Button variant="primary" type="submit" disabled={oldSelectedAppStat === selectedAppStat ? true : false}>
                                    {t("change")}
                                </Button>
                            )}
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

export default ChangeAppStatusModal;
