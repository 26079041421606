import React, { Fragment, Component } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import AlertNoPermission from "../../alerts/AlertNoPermission";
import { FormControlLabel } from "@material-ui/core";

class RolesAndEvents extends Component {
    state = {
        rolesList: [],
        eventsList: [],
        rolesListDropdown: [],
        selectedRole: [],
        noPermission: false,
    };

    getRolesAndEvents = async () => {
        const { fetchURL, onShowAlert, activeEvent, t, option } = this.props;
        onShowAlert("");
        const passData = {
            activeEvent: activeEvent,
            option: option,
        };
        try {
            const response = await fetch(`${fetchURL}/roles-and-events-list`, {
                credentials: "include",
                method: "POST",
                body: JSON.stringify(passData),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "notLogged") this.props.onShowHomePage();
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: t(`error.${fetchedData.code}`) });
                    else {
                        const eventsList = fetchedData.eventsList;
                        //obowiązkowość ról w zależności czy istnieje aktywny konwent
                        eventsList.unshift({
                            key: 0,
                            value: "permanent",
                            text: t("event.permanent"),
                        });
                        this.setState({ eventsList: eventsList, rolesList: fetchedData.rolesList });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `${t("error.cannotFetchData")} (${err})` });
        }
    };

    componentDidMount() {
        this.getRolesAndEvents();
    }

    render() {
        const { rolesList, eventsList, noPermission } = this.state;
        const { userRoles, onRemoveRoleSelects, onAddRoleSelects, option, onDropdownValue, t } = this.props;

        return (
            <Fragment>
                <Row>
                    <Col>
                        <Form.Label>
                            {t("event.role")}
                            <span className="required"> *</span>
                        </Form.Label>
                    </Col>
                    <Col>
                        <Form.Label>
                            {t("event.validFor")}
                            <span className="required"> *</span>
                        </Form.Label>
                    </Col>
                    <Col></Col>
                </Row>
                {userRoles.map((r, i) => (
                    <Fragment key={`rowRoleID${i}`}>
                        <Row>
                            <Col>
                                {noPermission ? (
                                    <AlertNoPermission t={t} />
                                ) : (
                                    <Dropdown
                                        id={`roleCode${i}`}
                                        className="mb-3 req"
                                        placeholder={t("form.choose")}
                                        fluid
                                        options={rolesList}
                                        defaultValue={r.roleCode}
                                        selection
                                        onChange={(event, { value }) => onDropdownValue("roleCode", value, `roleCode${i}`, i)}
                                        disabled={i === 0 && r.roleCode === "USER" ? true : false}
                                    />
                                )}
                            </Col>
                            <Col>
                                {noPermission ? (
                                    <AlertNoPermission t={t} />
                                ) : (
                                    <Dropdown
                                        id={`roleEvent${i}`}
                                        disabled={i === 0 && r.roleEvent === "permanent" ? true : /* r.roleCode !== "default" ? false : true */ false}
                                        className="mb-3 req"
                                        placeholder={t("form.choose")}
                                        fluid
                                        options={eventsList}
                                        defaultValue={r.roleEvent}
                                        selection
                                        onChange={(event, { value }) => onDropdownValue("roleEvent", value, `roleEvent${i}`, i)}
                                    />
                                )}
                            </Col>

                            {option === "add" && (
                                <Col>
                                    {i > 0 && (
                                        <Button variant="outline-secondary" className="d-block" onClick={onRemoveRoleSelects.bind(this, i)}>
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    )}
                                </Col>
                            )}
                            {option === "edit" && (
                                <Col>
                                    {r.roleID ? (
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    id={`roleActivity${i}`}
                                                    checked={r.roleActivity === "Y" ? true : false}
                                                    onChange={(e) => this.props.onChangeRoleActivity(e, i)}
                                                    name="roleActivity"
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                />
                                            }
                                            label={option === "edit" ? t("event.activeRole") : ""}
                                        />
                                    ) : (
                                        <Button variant="outline-secondary" className="d-block" onClick={onRemoveRoleSelects.bind(this, i)}>
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </Button>
                                    )}
                                </Col>
                            )}
                        </Row>
                    </Fragment>
                ))}
                <Row>
                    <Col xs={12}>
                        <Button variant="outline-secondary" size="sm" onClick={onAddRoleSelects} className="mb-3">
                            {t("event.addNewRole")}
                        </Button>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default RolesAndEvents;
